import React from "react";
import PageTitle from "../../Common/PageTitle";
import {
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  Box,
} from "@chakra-ui/react";

import { CheckIcon, MinusIcon, AddIcon } from "@chakra-ui/icons";
import feature from "./Feature/clinicfeature.json";
import module from "./Feature/clinicmodule.json";
import RaiseaQuery from "../../component/RaiseaQuery";
import Timeline from "../../component/Timeline";
import timelinedata from "./Feature/clinictimeline.json";
import Featuredata from "../../component/Featuredata";
import Testimonials from "../../component/CardContain/Testimonials";
import testimonialdata from "../../component/Testimonials/ClinicMStstimonials.json";

const ClinicMS = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="Clinic Management System"
          page="Clinic Management System"
          desc="Optimize, track, and manage administrative and financial processes using India's best Clinic Management Software. You can program the workflow, manage storage and retrieve information from a secure central location.      "
          descarr={[
            "Clinic Information Software",
            "Clinic Practice Management Software",
            "Clinic Case Management Software Services ",
            "Integrated Clinic Information Systems",
          ]}
          bimg="/img/banner/hms.jpg"
        />
        <div className="container ">
          <div className=" col-md-12   mt-5 mb-5 ">
            <div className="top_title">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Benefits Of Our Clinic Management System</span>
              </h2>
            </div>
            <p className="lead mt-4 fw-300">
              Technology is a continuous change in the healthcare industry. It
              allows clinics to arrange their respective data electronically
              using the best Clinic Management Software.
            </p>
            <p className="lead mt-4 fw-300">
              Clinic Management Software in India is a computerized system
              responsible for managing all the essential information, from
              medical procedures to billing information.
              <br />
            </p>
          </div>
        </div>
        <div className="container ">
          <div className="row mt-n1-9 ">
            <div className="col-lg-6 wow fadeInUp mt-1-9  ">
              <div className="pe-2  mt-5">
                <p className="lead mt-4 fw-300">
                  <strong>
                    This will help you manage all clinic activities in the
                    following ways.
                  </strong>
                </p>
                <List spacing={3}>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="#056894" />
                    Clinic Management Software helps as a decision support
                    system for the clinic authority by which you can make health
                    care policies accordingly.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="#056894" />
                    The Clinic Management Software helps access patients' data
                    to generate various records related to age, gender, etc.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="#056894" />
                    Clinic Management Software gives a realistic picture of
                    future clinic growth as It efficiently engenders the running
                    of finance, patients' diet, and the distribution of medical
                    aid.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="#056894" />
                    Clinic Management Software is straightforward to use and
                    puts an end to errors caused by handwriting.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="#056894" />
                    Clinic Management Software also improves the communication
                    and interactions of doctors with their patients.
                  </ListItem>
                  <ListItem>
                    <ListIcon as={CheckIcon} color="#056894" />
                    Clinic Management Software reduces the expenses of an
                    organization because of less paperwork, improved safety, and
                    reduced duplication of testing.
                  </ListItem>
                </List>
              </div>
            </div>
            <div className="col-lg-6 mt-1-9 wow fadeIn  mt-5">
              <img src="/img/hospital.png" className=" mb-4" alt="..." />
            </div>
          </div>
          <hr />
          <div className=" col-md-12   mt-5 mb-5">
            <div className="top_title">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>Benefits To Doctor With Clinic Management Software</span>
              </h2>
            </div>

            <List spacing={3}>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                Minimal upfront costs for the facility.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                With the mobile and web app, we get quick confirmation of
                appointment schedules, rounding lists, and patient details for
                discharge inflation
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                Communication tool for doctors that enables easy standard and
                patient data transmission on a secure platform.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                Allows remote viewing of patient information.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                User-friendly design with minimum clicks for data entry.
                interface.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                For doctors, telemedicine capability with an easy-to-navigate
              </ListItem>
            </List>
          </div>
          <hr />
          <div className=" col-md-12   mt-5 mb-5">
            <div className="top_title">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>
                  Benefits To Patients With Clinic Management Software
                </span>
              </h2>
            </div>

            <List spacing={3}>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                An efficient management system keeps patients satisfied with a
                smooth, stress-free waiting period.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                Automated patient reminders for follow-up visits.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                Telemedicine allows patients to communicate with care
                providers/facilities from remote locations.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                Online patient access to complete medical history.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />
                With the help of the Mobile pharmacy application, the patient
                will get the medication at their doorsteps.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="#056894" />A fully automated
                platform creates less dependence on staff assistance.
              </ListItem>
            </List>
          </div>
          <hr />
        </div>

        <div className="softwareDevpl_bgImg">
          <div
            className="mb-5 rounded-lg mt-5 mb-5  onsoftwareDevpl_BGlayer"
            style={{
              padding: "20px",
            }}
          >
            <section className="mb-10">
              <div className="container ">
                <div className="col-md-12 mt-5 mb-5 ">
                  <div className="col-md-12   mt-5 mb-5 ">
                    {/* <Card bg='transparent' border='none'> */}
                    {/* <CardHeader> */}
                    <div className="green_title text-center">
                      <h2 data-aos="fade-up" data-aos-duration="500">
                        <span> Purpose To Start</span>
                      </h2>
                    </div>
                    <h5
                      data-aos="fade-up"
                      data-aos-duration="600"
                      className="After_title mb-4 text-white"
                    >
                      Your Business With Clinic Management Software
                    </h5>

                    <p className="lead mt-4 fw-300 text-white">
                      If you are ready to go with the best clinic management ERP
                      software, we have the things which make it useful in your
                      clinic & labs & these are as follows.
                    </p>

                    <SimpleGrid
                      className="stepProcess_section"
                      columns={{ base: 1, md: 1, lg: 2 }}
                    >
                      <Card
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className="m-4"
                      >
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/processing-speed.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            Processing Speed And Results
                          </h5>
                          <p>
                            Clinic Management Software has some standard
                            procedures, and there are no chances for variation
                            in any effective clinic management software system.
                            With the performance of Clinic Management Software
                            in your labs or clinics, you will be able to manage
                            your patient in a better way.
                          </p>
                        </CardBody>
                      </Card>
                      <Card
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className="m-4"
                      >
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/cost-effective.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            Cost - Effective
                          </h5>
                          <p>
                            Clinic Management Software System helps to control
                            and manage finance and reduce manual work, so there
                            will be no requirement for a higher human workforce
                            as most of the work will be automated, so Clinic
                            Management Software reduces human resources costs.
                          </p>
                        </CardBody>
                      </Card>
                      <Card
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className="m-4"
                      >
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/data-security.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            Data Security
                          </h5>
                          <p>
                            A Best Clinic Management Software in india one of
                            the best software where everything gets interlinked.
                            Therefore there are no chances for contrast as they
                            have high data security. Suppose you have Clinic
                            Management Software in your clinic. In that case, it
                            becomes easy for you to access clinical,
                            operational, and financial data.
                          </p>
                        </CardBody>
                      </Card>
                      <Card
                        data-aos="fade-up"
                        data-aos-duration="1000"
                        className="m-4"
                      >
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/quality-1.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            Quality and Compliance
                          </h5>
                          <p>
                            Every clinic has to submit different types of
                            reports related to birth and death, their related
                            solutions and the reasons for death, etc., to the
                            NABH accreditation monthly, which is problematic.
                            Here is the best Clinic Management Software in india
                            that will help you to forward the reports faster and
                            at the right time.
                          </p>
                        </CardBody>
                      </Card>
                    </SimpleGrid>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="container mt-5 mb-5">
          <div className="row">
            {/* <div className=" col-md-12 mt-5 mb-5 text-center">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span> Video Gallery</span>
                </h2>
              </div>
              <h5
                className="After_title mb-2"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                Clinic Management Software
              </h5>

              <p className="lead mt-2 fw-300">
                Checkout our clinic management software demo and let's get
                started with the best clinic software in India. With this clinic
                management software demo video you will get an overview about
                the best clinic management software in India.
              </p>
            </div>
            <div className="col-md-12 mb-5 ">
              <SimpleGrid
                spacing={4}
                //  templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
                columns={{ base: 1, md: 1, lg: 3 }}
              >
                <Card>
                  <CardHeader className="d-flex justify-content-center align-items-center">
                    <video controls>
                      <source src="/assets/clinic_admin.mp4" type="video/mp4" />
                    </video>
                  </CardHeader>
                  <CardBody>
                    <h5 size="md" className=" text-blue text-center">
                      Admin Demo
                    </h5>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader className="d-flex justify-content-center align-items-center">
                    <video controls>
                      <source
                        src="/assets/clinic_doctor.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </CardHeader>
                  <CardBody>
                    <h5 size="md" className=" text-blue text-center">
                      Doctor Demo
                    </h5>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader className="d-flex justify-content-center align-items-center">
                    <video controls>
                      <source
                        src="/assets/clinic_pharmacy.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </CardHeader>
                  <CardBody>
                    <h5 size="md" className=" text-blue text-center">
                      Pharmacy Demo
                    </h5>
                  </CardBody>
                </Card>
              </SimpleGrid>
            </div> */}

            <div className=" col-md-12  mt-5 mb-5 text-center">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span> Features For</span>
                </h2>
              </div>
              <h5
                className="After_title mb-2"
                data-aos="fade-up"
                data-aos-duration="500"
              >
                Clinic Management Software
              </h5>

              <p
                className="lead mt-4 fw-300"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                We have some key features and clinic management software demos
                to help the decision support system for the clinic authority by
                which you can make health care policies accordingly. Some of the
                features of clinic pharmacy management software are as follows:
              </p>
            </div>
            <div className="col-md-12   mt-5  ">
              {window.innerWidth > 1200 && (
                <>
                  <SimpleGrid
                    className="mb-3"
                    spacing={4}
                    columns={{ base: 1, sm: 1, md: 1, lg: 4 }}
                  >
                    <Featuredata feature={feature.slice(0, 4)} />
                  </SimpleGrid>
                  <SimpleGrid
                    spacing={4}
                    columns={{ base: 1, sm: 1, md: 1, lg: 3 }}
                  >
                    <Featuredata feature={feature.slice(4, 7)} />
                  </SimpleGrid>
                </>
              )}
              {window.innerWidth < 1200 && (
                <>
                  <SimpleGrid
                    className="mb-3"
                    spacing={4}
                    columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
                  >
                    <Featuredata feature={feature.slice(0, 6)} />
                  </SimpleGrid>
                  <SimpleGrid
                    spacing={4}
                    columns={{ base: 1, sm: 1, md: 1, lg: 1 }}
                  >
                    <Featuredata feature={feature.slice(6, 7)} />
                  </SimpleGrid>
                </>
              )}
              {/* {window.innerWidth < 992 && window.innerWidth > 768 && (
                <>
                  <SimpleGrid
                    className="mb-3"
                    spacing={4}
                    columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
                  >
                    <Featuredata feature={feature.slice(0, 6)} />
                  </SimpleGrid>
                  <SimpleGrid
                    spacing={4}
                    columns={{ base: 1, sm: 1, md: 1, lg: 1 }}
                  >
                    <Featuredata feature={feature.slice(6, 7)} />
                  </SimpleGrid>
                </>
              )} 
               {window.innerWidth < 768 && (
                <>
                  <SimpleGrid
                    className="mb-3"
                    spacing={4}
                    columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
                  >
                    <Featuredata feature={feature} />
                  </SimpleGrid>
                </>
              )} */}
            </div>
          </div>
        </div>
        <br />
        <div className="container-fluid p-0   mt-5 mb-5">
          <div className=" col-md-12 py-4  mt-5 mb-5 text-center bg-blue gradientbg_blue ">
            <h2
              className=" text-white pt-4  mb-2 font-itelic "
              data-aos="fade-up"
              data-aos-duration="500"
            >
              How Clinic Management
            </h2>
            <h2
              className="text-white  mb-2 font-itelic "
              data-aos="fade-up"
              data-aos-duration="500"
            >
              System Works
            </h2>
            <p
              className="lead mt-4 text-white fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              We provide the best Clinic Management Software in india We will
              work on some terms which help manage certain activities of the
              clinic properly, and these are as follows.
            </p>
            <br />

            <Timeline timelinedata={timelinedata} />
          </div>
        </div>

        <div className="container mt-5 mb-5">
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Modules We Offer For</span>
              </h2>
            </div>
            <h5
              className="After_title "
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Clinic Management Software
            </h5>

            <p
              className="lead mt-2 fw-300"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              We offer the best clinic management software demo and modules;
              some are as follows.
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={4}
              templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
            >
              {module &&
                module.map((data, index) => (
                  <Card
                    data-aos="fade-up"
                    data-aos-duration="500"
                    className=" d-flex justify-content-center align-items-center"
                    style={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.9)" }}
                  >
                    <CardBody>
                      <h5 size="md" className=" text-blue text-center">
                        {data.title}
                      </h5>
                      <p>{data.desc}</p>
                    </CardBody>
                  </Card>
                ))}
            </SimpleGrid>
          </div>
        </div>

        <Testimonials
          testimonialdata={testimonialdata}
          tHead={
            <span>
              What Our
              <br />
              Customer Says About The Software
            </span>
          }
          tText={
            "There are some of our customer's feedback who are happy with our Clinic Management Software application for their clinic management; some of them are"
          }
        />
        <div className="container ">
          <div className=" col-md-12   mt-5 mb-5 ">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> FAQ's About</span>
              </h2>
            </div>
            <h5
              className="After_title mb-2"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Clinic Management Software
            </h5>

            <p className="lead text-center fw-300">
              Plunge into the FAQs and clear out your doubts, else chat with our
              customer support team?
              <br />
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <Accordion allowToggle>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Where can I find your product?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      We are currently only available in California, but to a
                      state near you as soon as possible. You can locate our
                      amazing retailers carrying Company on our Where To Find
                      page. applications
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is Veterinary Clinic Management Software ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Veterinary Clinic Management Software is used in an animal
                      healthcare center to support the daily operations of
                      veterinary clinic management. The Veterinary Clinic
                      Management Software is created for managing all the
                      information related to the patient, managing treatment
                      activities, and scheduling. Veterinary Clinic Management
                      Software allows storing all the necessary data in it.
                      <br />
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What are the modules used in the best Clinic
                          Management Software in India?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Some of the modules we use for the best Clinic Management
                      Software are as follows
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Patient Management System
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Doctor Management System
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Drug Management System
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Administrative Rights Management
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          System Online Appointment
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Management System Lab Test System
                        </ListItem>
                      </List>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Is it worth starting our business with Clinic
                          Management Software?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Yes, it is totally worth starting your business with
                      Clinic Management Software. If you are ready to go with
                      the best clinic management ERP software, we have the
                      things which make it useful in your clinic.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What cost does Hospital Management Software Charge ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Well, the cost of Hospital Management Software totally
                      depends on the size of the project and the needs of your
                      hospital management. It is not possible for us to inform
                      you about the accurate Amount.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What cost does Clinic Management Software Charge ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      The main attributes we offer if you guys started using
                      Hospital Management Software in Vadodara are as follows
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Improve customer services
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Improve quality control
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" /> Improve
                          Visibility & Transparency
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Unlimited User Support Single Database Management
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" /> System
                          Ease to Access
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" /> System
                          Facilities Improved Time Management Streamline
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Accurate Reporting
                        </ListItem>
                      </List>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Which are the top Ophthalmology Clinic Management
                          Software in India ? .
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      These are the list of top Ophthalmology Clinic Management
                      Software in India which provided the best Functionalities
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" /> Netra
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" /> Smart Eye
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          EyeMD EMR
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Softcore
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          first Insight
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" /> And many
                          more
                        </ListItem>
                      </List>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <RaiseaQuery />
      </div>
    </>
  );
};
export default ClinicMS;
