import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

const PageTitle = (props) => {
  return (
    <>
      <section
        class="page-title-section bg-img "
        style={{
          backgroundImage: `url(${props.bimg})`,
          backgroundSize: "cover",
          position: "relative",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="onBg_layer">
          <div class="container">
            <div className="row">
              <div className="col-lg-12 ">
                <div class="title-info text-white">
                  <h1 class=" ml-5">{props.title}</h1>
                  <br />
                  <p className="text-white ml-5 mr-10 Titletop_text">
                    {props.desc}
                  </p>
                  <ol className="text-white ml-4 mr-10">
                    {props.descarr &&
                      props.descarr.map((item, index) => (
                        <li key={index}>{item} &nbsp;</li>
                      ))}
                  </ol>
                  <Breadcrumb
                    spacing="4px"
                    separator={<ChevronRightIcon color="gray.500" />}
                    className="bg-transparent text-warning ml-1"
                  >
                    <BreadcrumbItem>
                      <BreadcrumbLink href="/">Home</BreadcrumbLink>
                    </BreadcrumbItem>
                    {props.prevtitle && (
                      <BreadcrumbItem>
                        <BreadcrumbLink
                          href={`/${props.prevtitle}`}
                        >
                          {props.prevtitle}
                        </BreadcrumbLink>
                      </BreadcrumbItem>
                    )}
                    <BreadcrumbItem isCurrentPage>
                      <BreadcrumbLink href="">{props.page}</BreadcrumbLink>
                    </BreadcrumbItem>
                  </Breadcrumb>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PageTitle;
