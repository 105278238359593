import { Card, CardHeader, CardBody, Image } from "@chakra-ui/react";

const Featuredata = ({ feature }) => {
  return (
    <>
      {feature &&
        feature.map((data, index) => (
          <Card
            data-aos="fade-up"
            data-aos-duration="200"
            className=" featurebg_blue d-flex justify-content-center align-items-center  "
          >
            <CardHeader>
              <h5 size="md" className=" text-darkblue text-center">
                {data.title}
              </h5>
              <div className="d-flex justify-content-center align-items-center">
                <Image src={data.image} alt="....." height="80px" />
              </div>
            </CardHeader>
            <CardBody
            // className="gradientbg_blue"
            // style={{
            //   borderTopLeftRadius: "20px",
            //   borderTopRightRadius: "20px",
            // }}
            >
              <p className="text-white">{data.desc}</p>
            </CardBody>
          </Card>
        ))}
    </>
  );
};
export default Featuredata;
