import { CheckCircleIcon } from "@chakra-ui/icons";
import Lottie from "lottie-react";

const CardOne = (props) => {
  return (
    <>
      <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
        <div
          className="card"
          data-aos="zoom-in-right"
          data-aos-duration={props.delay}
          style={{ "--clr": "#03b5be" }}
        >
          <div className="box">
            <div className="icon">
              <div className="iconbox">
                {" "}
                <Lottie animationData={props.anim} />{" "}
              </div>
            </div>
            <div className="heading_of_card">
              <h3>{props.title}</h3>
            </div>
            <div className="content">
              {/* <h3>{props.title}</h3> */}
              <p>{props.txt} </p>
              <ul className="list-unstyled text-light mt-4 mb-5">
                <li>
                  <CheckCircleIcon color="#03b5be" /> &nbsp; {props.listone}
                </li>
                <li>
                  <CheckCircleIcon color="#03b5be" /> &nbsp; {props.listtwo}{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardOne;
