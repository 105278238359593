import React from "react";
import PageTitle from "../../Common/PageTitle";
import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, CardFooter, SimpleGrid, Image} from "@chakra-ui/react";
import Lottie from "lottie-react";
import req from "../../component/jsonfile/require.json";
import dev from "../../component/jsonfile/develop.json";
import test from "../../component/jsonfile/testing.json";
import subm from "../../component/jsonfile/submit.json";
import softex from "./software example.json";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import RaiseaQuery from "../../component/RaiseaQuery";
import CommonCard from "../../Common/CommonCard";
import Faqs from "../../component/faq/Faqs";
import faqdata from "../../component/faq/SoftwareD.json";

const SoftwareDevelopment = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          title="Software Solution Company"
          desc="Get started with the most optimized custom developed softwares and automate your working strategies. Get your quotation now!"
          bimg="/img/banner/software.png"
          page="Software Development"
        />
        <div className="container  dot-pattern">
          <div className="row">
            <div className=" col-md-12   mt-5 mb-5 text-center">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span> Our Custom Software Solutions</span>
                </h2>
              </div>
              <p
                className="lead mt-2 "
                data-aos="fade-up"
                data-aos-duration="600"
              >
                We strive to offer the best product as per the users' needs. Our
                custom build softwares are Fast, <br /> Secure and Reliable with
                design-driven hybrid technology.
              </p>
            </div>
            <div className=" col-md-12   mt-3 mb-5 ">
              <SimpleGrid
                spacing={4}
                templateColumns="repeat(auto-fill, minmax(255px, 1fr))"
              >
                {softex &&
                  softex.map((data, index) => (
                    <Card
                      key={index}
                      data-aos="flip-right"
                      data-aos-duration="600"
                      className="Softwr_Deve_css"
                    >
                      <CardHeader className=" d-flex justify-content-center align-items-center">
                        <Image
                          src={data.image}
                          alt="....."
                          maxWidth="300px"
                          maxHeight="500px"
                        />
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="CartTitle_color">
                          {data.title}
                        </h5>
                        <p>{data.desc}</p>
                      </CardBody>
                    </Card>
                  ))}
              </SimpleGrid>
            </div>
          </div>
        </div>

        <div className="container-fluid " style={{ paddingLeft: "0px" }}>
          <div className="row pl-0 mt-5 mb-5">
            <div className="col-lg-5  col-md-12 mt-1-9 pl-0">
              <img
                src="../assets/home/about-us.png"
                alt="..."
                className="img-fluid"
                style={{ width: "100%", marginTop: "-83px" }}
              />
            </div>
            <div className="col-lg-6  col-md-12 mt-1-9 dot-pattern mt-5 It_solution_section pl-4">
              <div className="top_title mb-3">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span>About Us</span>
                </h2>
              </div>
              <p className="lead" data-aos="fade-up" data-aos-duration="600">
                Connecting People
              </p>
              <hr />
              <div className="about_useshort_content">
                <p data-aos="fade-up" data-aos-duration="200">
                  We are just a group of technology radicals who are constantly
                  at work to surpass their previous best work at OneBigBit.
                </p>
                <p data-aos="fade-up" data-aos-duration="300">
                  we consult our clients to understand their requirements and
                  brainstorm profusely to create a revolutionary solution.
                </p>
                <p data-aos="fade-up" data-aos-duration="400">
                  Our office in Lucknow is the nerve centre of our execution
                  process. It is from there, that our team researches, rack
                  brains, and delivers the result that your company desires.
                </p>
                <br />
                <Link
                  to="/about"
                  data-aos="fade-up"
                  data-aos-duration="200"
                  className=" all_btn mt-5"
                >
                  Read More...
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-12   mt-1 mb-5 ">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span>What Makes Us</span>
                </h2>
              </div>
              <h5
                className="After_title mb-4"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                The Best Custom Software Company?
              </h5>
              <SimpleGrid
                spacing={4}
                columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
              >
                <CommonCard
                  delay="100"
                  img="productivity.png"
                  title="  We Aim at Productivity"
                  txt="We make tailored digital products for your business and help
                        you to increase your business productivity with intelligent
                        strategies."
                />
                <CommonCard
                  delay="200"
                  img="fresh idea.png"
                  title="   We have Fresh Idea"
                  txt="  Whatever your idea is, we will provide you with the solution
                      for it. We explore, then make the structure as per your
                      functional needs."
                />
                <CommonCard
                  delay="300"
                  img="service on demand.png"
                  title="Service On-Demand"
                  txt=" You will get round the clock customer service. We will get
                      the problem resolved within the next 24 hours. You can also
                      avail AMC service from us."
                />
              </SimpleGrid>
            </div>
          </div>
        </div>
        <div className="softwareDevpl_bgImg mb-5  rounded-lg mt-5 mb-5">
          <div className="onsoftwareDevpl_BGlayer">
            <div className="container" style={{ marginTop: "25px" }}>
              <div className="row">
                <div className=" col-md-12   mt-5 mb-5 text-center">
                  <h2
                    className=" text-color mb-2 "
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    Successful Project Submission
                  </h2>
                  <p
                    className="lead text-light"
                    data-aos="fade-up"
                    data-aos-duration="600"
                  >
                    From Your Requirement To Your Success
                  </p>
                </div>
                <div className="col-md-12 mb-5 ">
                  <SimpleGrid
                    spacing={4}
                    className="stepProcess_section"
                    columns={{ base: 1, sm: 1, md: 2, lg: 4 }}
                    //  templateColumns="repeat(auto-fill, minmax(255px, 1fr))"
                  >
                    <Card data-aos="fade-right" data-aos-delay="100">
                      <CardHeader className=" d-flex justify-content-center align-items-center">
                        <div className="lottie_bg">
                          <Lottie animationData={req} />
                        </div>
                      </CardHeader>
                      <CardBody className="text-center">
                        <p className="text-blue lead ">Step 1</p>
                        <h5 size="md">Your Requirement</h5>
                        <p>
                          Our first objective is to understand the requirements,
                          according to the project and then we will start.
                        </p>
                      </CardBody>
                    </Card>
                    <Card data-aos="fade-right" data-aos-delay="200">
                      <CardHeader className=" d-flex justify-content-center align-items-center">
                        <div className="lottie_bg">
                          <Lottie animationData={dev} />
                        </div>
                      </CardHeader>
                      <CardBody className="text-center">
                        <p className="text-blue lead ">Step 2</p>
                        <h5 size="md">Development</h5>
                        <p>
                          Now our software engineers will work on the
                          development process of the software and then we will
                          start.
                        </p>
                      </CardBody>
                    </Card>
                    <Card data-aos="fade-right" data-aos-delay="300">
                      <CardHeader className=" d-flex justify-content-center align-items-center">
                        <div className="lottie_bg">
                          <Lottie animationData={test} />
                        </div>
                      </CardHeader>
                      <CardBody className="text-center">
                        <p className="text-blue lead ">Step 3</p>
                        <h5 size="md"> Testing</h5>
                        <p>
                          We have certain procedures for testing the software
                          that is essential for quality aspects. Now it's time
                          for.
                        </p>
                      </CardBody>
                    </Card>
                    <Card data-aos="fade-right" data-aos-delay="400">
                      <CardHeader className=" d-flex justify-content-center align-items-center">
                        <div className="lottie_bg">
                          <Lottie animationData={subm} />
                        </div>
                      </CardHeader>
                      <CardBody className="text-center">
                        <p className="text-blue lead ">Step 4 </p>
                        <h5 size="md">Project Submission</h5>
                        <p>
                          After examining all the lines of the code we will
                          submit the project to you in the allotted time frame.
                        </p>
                      </CardBody>
                    </Card>
                  </SimpleGrid>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container dot-pattern">
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div
              className="top_title text-center"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h2>
                <span> Our Projects</span>
              </h2>
            </div>
            <p className="lead " data-aos="fade-up" data-aos-duration="600">
              Our Latest Custom Build Projects <br />
              We have made industry leading softwares with cutting edge
              technology to offer you a seamless performance.
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={90}
              className="product_card"
              templateColumns="repeat(auto-fill, minmax(295px, 1fr))"
            >
              <Card
                data-aos="fade-up"
                data-aos-duration="200"
                borderTop="8px"
                borderColor="#056894"
                borderLeft="1px"
                borderRight="1px"
                className="product_card_css"
              >
                <CardHeader className=" pt-4 d-flex justify-content-center align-items-center">
                  <Image
                    src="/img/product-1.png"
                    alt="..."
                    borderRadius="lg"
                    maxWidth="295px"
                    maxHeight="205px"
                  />
                </CardHeader>
                <CardBody
                  style={{
                    border: "0",
                    borderTopLeftRadius: "10%",
                    borderTopRightRadius: "10%",
                    filter: "brightness(1.6)",
                  }}
                >
                  <div className=" title_prod ">
                    <h5
                      size="md"
                      className="mb-3 Product_text_color text-center  "
                    >
                      Hospital Management System
                    </h5>
                  </div>
                  <p className="fw-300 para_text">
                    The technology is continuous changes in healthcare
                    industries which allowed hospitals to arrange their
                    respective data electronically.
                  </p>
                  <CardFooter pt={0} className="w-100 p-0">
                    <Link
                      to="/products/clinicMS"
                      className="Product_text_color"
                    >
                      View More Details <ArrowForwardIcon />
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="200"
                borderTop="8px"
                borderColor="#056894"
                borderLeft="1px"
                borderRight="1px"
                className="product_card_css"
              >
                <CardHeader className="pt-4 d-flex justify-content-center align-items-center">
                  <Image
                    src="/img/product-2.png"
                    alt="..."
                    borderRadius="lg"
                    maxWidth="295px"
                    maxHeight="205px"
                  />
                </CardHeader>
                <CardBody
                  style={{
                    border: "0",
                    borderTopLeftRadius: "10%",
                    borderTopRightRadius: "10%",
                    filter: "brightness(1.6)",
                  }}
                >
                  <div className=" title_prod ">
                    <h5 className="mb-3 Product_text_color text-center  ">
                      Admission Management Software
                    </h5>
                  </div>
                  <p className="fw-300 para_text">
                    With the Admission Management Software, keep an eye on every
                    student. From private institutes to colleges, this admission
                    management software.
                  </p>
                  <CardFooter className="w-100 p-0">
                    <Link
                      to="/products/admissionMS"
                      className="Product_text_color"
                    >
                      View More Details <ArrowForwardIcon />
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="200"
                borderTop="8px"
                borderColor="#056894"
                borderLeft="1px"
                borderRight="1px"
                className="product_card_css"
              >
                <CardHeader className=" pt-4 d-flex justify-content-center align-items-center">
                  <Image
                    src="/img/product-3.png"
                    alt="..."
                    borderRadius="lg"
                    maxWidth="295px"
                    maxHeight="205px"
                  />
                </CardHeader>
                <CardBody
                  style={{
                    border: "0",
                    borderTopLeftRadius: "10%",
                    borderTopRightRadius: "10%",
                    filter: "brightness(1.6)",
                  }}
                >
                  <div className=" title_prod ">
                    <h5 className="mb-3 Product_text_color text-center  ">
                      Salon Management System
                    </h5>
                  </div>
                  <p className="fw-300 para_text">
                    Salon Management Software is here to manage all the
                    necessary things in one-step with the best salon management
                    software India.
                  </p>
                  <CardFooter className=" p-0">
                    <Link to="/products/salonMS" className="Product_text_color">
                      View More Details <ArrowForwardIcon />
                    </Link>
                  </CardFooter>
                </CardBody>
              </Card>
            </SimpleGrid>
          </div>
          <Faqs faqdata={faqdata} />
        </div>
        <RaiseaQuery />
      </div>
    </>
  );
};
export default SoftwareDevelopment;
