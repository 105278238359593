import React from "react";
import PageTitle from "../../Common/PageTitle";
import {
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  Box,
} from "@chakra-ui/react";
import {
  CheckIcon,
  MinusIcon,
  AddIcon,
} from "@chakra-ui/icons";

import feature from "./Feature/ecommersefeature.json";
import Timeline from "../../component/Timeline";
import timelinedata from "./Feature/ecommercetimeline.json";
import Featuredata from "../../component/Featuredata";
import Lastpoint from "../../component/Lastpoint";

import Testimonials from "../../component/CardContain/Testimonials";
import testimonialdata from "../../component/Testimonials/EcommerceTestimonials.json";

const ECommerseS = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="E-Commerce Software Application"
          desc="E-commerce, short for electronic commerce, is the practice of buying and selling goods and services over the internet. It has revolutionized the way businesses operate and how consumers shop."
          bimg="/img/banner/ecommerce.jpg"
          page="E-Commerce Software"
        />
        <div className="container ">
          <div className=" col-md-12   mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Do You Need</span>
              </h2>
            </div>
            <h5
              data-aos="fade-up"
              data-aos-duration="500"
              className="After_title mb-4"
            >
              E-Commerce Software Application?
            </h5>

            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              An e-commerce software application, also known as an e-commerce
              platform or e-commerce solution, is a software system designed to
              facilitate online buying and selling of goods and services. These
              applications provide a framework for businesses to set up and
              manage their online stores, allowing them to showcase products,
              process payments, and handle various aspects of e-commerce
              operations.
            </p>
          </div>
          <div className=" col-md-12    mb-5">
            <div className="row mt-n1-9 ">
              <div className="col-lg-7 wow fadeInUp mt-1-9  ">
                <div className="pe-2  mt-5">
                  <p className="lead mt-4 fw-300">
                    <strong>
                      Here are some key components and features commonly found
                      in e-commerce software applications:
                    </strong>
                  </p>
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      When choosing an e-commerce platform, it is important to
                      consider the needs of your business. Some factors to
                      consider include the number of products you plan to sell,
                      the features you need, and your budget.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      After you have built your store, you need to promote it so
                      that people can find it. There are a number of ways to
                      promote your store, such as search engine optimization,
                      social media marketing, and pay-per-click advertising.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      Once you have started selling products, you need to keep
                      track of your sales and inventory. Most e-commerce
                      platforms provide tools to help you do this.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      You also need to provide customer support. This includes
                      answering questions, processing orders, and resolving
                      issues.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      Running an e-commerce store can be a lot of work, but it
                      can also be very rewarding. If you are thinking about
                      starting an online store, an e-commerce platform can make
                      the process much easier.
                    </ListItem>
                  </List>
                </div>
              </div>
              <div className="col-lg-5 mt-1-9 wow fadeIn  mt-5">
                <img
                  src="/img/onlinestore.png"
                  className=" mb-4"
                  alt="..."
                  data-aos="fade-up"
                  data-aos-duration="500"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="softwareDevpl_bgImg">
          <div
            className="mb-5 rounded-lg mt-5 mb-5 onsoftwareDevpl_BGlayer"
            style={{
              // backgroundImage: "url('/img/bgn-testimonials.png')",
              // backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundRepeat: "no-repeat",
              padding: "20px",
              // backgroundColor: " #056894",
            }}
          >
            <section className="mb-10">
              <div className="container ">
                <div className="col-md-12 mt-5 mb-5 ">
                  <div className="col-md-12  mt-5 mb-5 ">
                    {/* <Card> */}
                    {/* <CardHeader> */}
                    <div className="green_title text-center">
                      <h2 data-aos="fade-up" data-aos-duration="500">
                        <span> Purpose To Start</span>
                      </h2>
                    </div>
                    <h5
                      data-aos="fade-up"
                      data-aos-duration="600"
                      className="After_title mb-4 text-white"
                    >
                      Your Business With E-Commerce Software Application
                    </h5>
                    <p className="lead mt-4 fw-300 text-white">
                      Starting your business with an e-commerce software
                      application can offer several compelling advantages,
                      depending on your business model and goals. Here are some
                      of the primary purposes and benefits of beginning your
                      business journey with e-commerce software:
                    </p>
                    <hr />
                    {/* </CardHeader> */}
                    {/* <CardBody> */}
                    <SimpleGrid
                      className="stepProcess_section"
                      columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
                      spacing={6}
                    >
                      <Card data-aos="fade-up" data-aos-duration="1000">
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/gr.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            Global Reach
                          </h5>
                          <p>
                            E-commerce enables you to reach a worldwide
                            audience. Your products or services are accessible
                            to customers from different geographic locations,
                            expanding your market reach beyond the confines of a
                            physical store.
                          </p>
                        </CardBody>
                      </Card>
                      <Card data-aos="fade-up" data-aos-duration="1000">
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/cost-e.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            Cost - Effective Setup
                          </h5>
                          <p>
                            Launching an e-commerce store is often more
                            cost-effective than establishing a brick-and-mortar
                            store. You can save on expenses like rent,
                            utilities, and in-store staff.
                          </p>
                        </CardBody>
                      </Card>
                      <Card data-aos="fade-up" data-aos-duration="1000">
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/img24-7.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            24/7 Accessibility
                          </h5>
                          <p>
                            Your online store is open 24/7, allowing customers
                            to shop at their convenience. This accessibility can
                            lead to increased sales and revenue, especially for
                            businesses that cater to global or diverse time
                            zones.
                          </p>
                        </CardBody>
                      </Card>
                      <Card data-aos="fade-up" data-aos-duration="1000">
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/mo.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            Marketing Opportunities
                          </h5>
                          <p>
                            E-commerce software applications offer various
                            marketing tools, including email campaigns, SEO
                            optimization, and social media integration, helping
                            you attract and retain customers.
                          </p>
                        </CardBody>
                      </Card>
                      <Card data-aos="fade-up" data-aos-duration="1000">
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/customer.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            Customer Convenience
                          </h5>
                          <p>
                            Online shopping offers customers convenience and
                            flexibility. They can browse and purchase products
                            from the comfort of their homes or on the go,
                            leading to improved customer satisfaction.
                          </p>
                        </CardBody>
                      </Card>
                      <Card data-aos="fade-up" data-aos-duration="1000">
                        <CardHeader className="d-flex justify-content-center align-items-center">
                          <div style={{ width: "100px", height: "100px" }}>
                            <img src="/img/new-img1.png" alt="..." />
                          </div>
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className=" text-blue text-center">
                            Security and Trust
                          </h5>
                          <p>
                            Most e-commerce platforms prioritize security,
                            offering features like SSL encryption and PCI
                            compliance. This helps build trust with customers
                            who are concerned about online transactions.
                          </p>
                        </CardBody>
                      </Card>
                    </SimpleGrid>
                    {/* </CardBody> */}
                    {/* </Card> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="container">
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Features For</span>
              </h2>
            </div>
            <h5
              data-aos="fade-up"
              data-aos-duration="600"
              className="After_title mb-4"
            >
              E-Commerce Software Application
            </h5>
            <p className="lead mt-4 fw-300">
              When developing an e-commerce software application, it's essential
              to incorporate a comprehensive set of features that facilitate
              online selling, enhance the shopping experience, and optimize
              business operations.
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={4}
              //  templateColumns="repeat(auto-fill, minmax(255px, 1fr))"
              columns={{ base: 1, sm: 1, md: 2, lg: 4 }}
            >
              <Featuredata feature={feature} />
            </SimpleGrid>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0">
        <div className=" col-md-12  py-4  mb-5 text-center mt-10 bg-blue gradientbg_blue">
          <h2
            className=" text-white mt-5   mb-2 font-itelic "
            data-aos="fade-up"
            data-aos-duration="500"
          >
            E-Commerce Software
          </h2>
          <h2
            className="text-white  mb-2 font-itelic "
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Application Works
          </h2>
          <p
            className="lead mt-4  text-white fw-300"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            E-commerce software applications work as the backbone of online
            stores, facilitating the entire process of buying and selling
            products or services over the internet. Here's an overview of how an
            e-commerce software application typically works:
          </p>
          <Timeline timelinedata={timelinedata} />
        </div>
      </div>

      <div className="container ">
        <div className="row">
          <div className=" col-md-12 mt-5 mb-5 text-center">
            <div className="top_title ">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> FAQ's About</span>
              </h2>
            </div>
            <h5
              className="After_title"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              E-Commerce Software Application
            </h5>

            <p
              className="lead mt-2 fw-300"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Plunge into the FAQs and clear out your doubts, else chat with our
              customer support team?
            </p>
          </div>
          <div className="col-md-12 mb-5 ">
            <Accordion allowToggle>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is an e-commerce software application?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      An e-commerce software application is a digital platform
                      that allows businesses to create and manage online stores,
                      facilitating the buying and selling of products or
                      services over the internet.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What features should I look for in e-commerce software
                          ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Features to consider include product management, shopping
                      cart functionality, payment processing, security, SEO
                      tools, analytics, and mobile responsiveness. The specific
                      features you need will depend on your business
                      requirements.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Can I integrate e-commerce software with other
                          business tools, like accounting software or CRM
                          systems?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Many e-commerce platforms offer integrations with various
                      third-party tools and services, allowing you to streamline
                      your business operations and connect with other software
                      you use.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How do I set up an online store using e-commerce
                          software?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Setting up an online store involves choosing a platform,
                      selecting a domain name, configuring the software, adding
                      products, setting up payment options, and customizing the
                      design. Many platforms offer step-by-step guides.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How can I ensure the security of my e-commerce
                          website?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      To ensure security, use SSL encryption, choose a
                      PCI-compliant payment gateway, regularly update your
                      software, and implement strong access controls. Monitor
                      for any suspicious activity or vulnerabilities.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How can I optimize my e-commerce website for search
                          engines (SEO)?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      SEO optimization involves using relevant keywords,
                      creating high-quality content, optimizing product pages,
                      improving site speed, and obtaining backlinks. Many
                      e-commerce software applications offer built-in SEO tools
                      to help with this.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What should I do in case of technical issues or
                          software updates?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Most e-commerce software providers offer customer support,
                      knowledge bases, and forums for addressing technical
                      issues. Regularly update your software to access new
                      features and security patches.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
      <Testimonials
        testimonialdata={testimonialdata}
        tHead={
          <span>
            What Our
            <br />
            Customer Says About The Software
          </span>
        }
        tText={
          "Customers often share their experiences and opinions about these applications, and their feedback can provide valuable insights for both potential users and the developers of the software. Here are some common things that customers might say about an e-commerce software application:"
        }
      />

      <Lastpoint />
    </>
  );
};
export default ECommerseS;
