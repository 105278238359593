import { Card, CardHeader, CardBody } from "@chakra-ui/react";
import Lottie from "lottie-react";

const AnimationCard = (props) => {
    return (
        <>
            <Card data-aos="fade-right" data-aos-delay={props.delay}>
                <CardHeader className="d-flex justify-content-center align-items-center">
                    <div className="lottie_bg">
                        <Lottie animationData={props.anim} />
                    </div>
                </CardHeader>
                <CardBody className="text-center">
                    <h5 size="md" className="text-blue text-center">  {props.title}      </h5>
                    <p className="text-justify">{props.txt} </p>
                </CardBody>
            </Card>
        </>
    )
}

export default AnimationCard
