import React, { useState, useRef } from "react";
import PageTitle from "../Common/PageTitle";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
import { Card, CardBody, IconButton, Image, Stack, SimpleGrid } from "@chakra-ui/react";
import Swal from "sweetalert2";

const Contact = () => {
  const refcontainer = useRef(null);
  const [Data, setData] = useState({});
  const handleInput = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const submitdata = async (e, v) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://api-obb.onebigbit.com/visitors/contact_obb",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Data),
        }
      );
      const responseData = await response.json();
      console.log("Response Body:", responseData);
      if (response.status === 200) {
        console.log(response.status, response.ok);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: responseData.message,
        });
      } else {
        console.log(response.status, response.ok);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
      refcontainer.current.reset();
    } catch (error) {
      console.log(error, "something went wrong");
    }
  };

  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          title="Contact Us"
          desc="We build websites, softwares and web applications with fanatical attention to detail,
         and offer a range of development services including mobile applications."
          bimg="/img/contact-us.png"
          page="Contact"
        />
        <section className="pt-0 mb-5">
          <div className="container">
            <div className="row  mt-5">
              <div className="col-lg-6 col-md-12  col-sm-12 mt-5  " data-aos="fade-up" data-aos-duration="500" data--delay="200ms"
                style={{
                  height: window.innerWidth < 768 ? "400px" : "500px",
                }} >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3558.4835601878253!2d80.93646811153232!3d26.888144461047666!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3999574966b00d11%3A0x877ecada07e90d24!2sOneBigBit%20Technologies%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1710399222938!5m2!1sen!2sin"
                  width="90%"
                  height="100%"
                  style={{
                    border: "0",
                    borderRadius: "25%",
                    borderTopLeftRadius: "0%",
                    borderBottomRightRadius: "0%",
                  }}
                  allowfullscreen="" loading="lazy" ></iframe>
              </div>
              <div className="col-lg-6 col-md-12   mt-5 mb-8  " data--delay="200ms" >
                <div className="mt-6">
                  <div className="top_title mb-3">
                    <h2 data-aos="fade-up" data-aos-duration="500">
                      <span> Have Any Queries? Write to Us</span>
                    </h2>
                  </div>
                  <div className="pe-lg-5">
                    <form className="contact quform" onSubmit={submitdata} method="post" ref={refcontainer} >
                      <div className="quform-elements">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="quform-element form-group mb-3">
                              <label htmlFor="name">
                                Your Name
                                <span className="quform-required">*</span>
                              </label>
                              <div className="quform-input">
                                <input className="form-control" id="name" type="text" name="name" required onChange={handleInput} placeholder="Your name here" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="quform-element form-group mb-3">
                              <label htmlFor="email">  Your Email <span className="quform-required">*</span> </label>
                              <div className="quform-input">
                                <input className="form-control" id="email" type="email" name="email" required onChange={handleInput} placeholder="Your email here" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="quform-element form-group mb-3">
                              <label htmlFor="mobile"> Your mobile  <span className="quform-required">*</span> </label>
                              <div className="quform-input">
                                <input className="form-control" id="mobile" type="number" name="mobile" required onChange={handleInput} placeholder="Your mobile here" />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="quform-element form-group mb-3">
                              <label htmlFor="message"> Message <span className="quform-required">*</span> </label>
                              <div className="quform-input">
                                <textarea className="form-control h-100" id="message" name="message"
                                  required
                                  onChange={handleInput}
                                  rows={6}
                                  placeholder="Tell us a few words"
                                  defaultValue={""}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 mt-2">
                            <div className="quform-submit-inner">
                              <button type="submit" className="all_btn">
                                <span>Send Now</span>
                              </button>
                            </div>
                            <div className="quform-loading-wrap text-start">
                              <span className="quform-loading" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-0  dot-pattern">
          <div className="container ">
            <div className="row align-items-center ">
              <div className="col-lg-12 mt-3 mb-5  " data--delay="200ms">
                <div className="pe-lg-5">
                  <div className="top_title  text-center mb-3">
                    <h2 data-aos="fade-up" data-aos-duration="500">
                      <span>Visit Us</span>
                    </h2>
                  </div>
                  <div
                    className="lead text-center mcontact_us"
                    data-aos="fade-up"
                    data-aos-duration="600"
                  >
                    <p>
                      {" "}
                      Visit our Website or simply send us an email anytime you
                      want. If you have any questions, please feel free to
                      contact us.
                    </p>
                  </div>
                </div>
              </div>
              <SimpleGrid
                spacing={4}
                columns={{ base: 1, sm: 1, md: 1, lg: 2 }}
              >
                <Card
                  direction={{ base: "column", sm: "row" }}
                  overflow="hidden"
                  variant="outline"
                >
                  <Image
                    data-aos="fade-up"
                    data-aos-duration="500"
                    objectFit="cover"
                    maxW={{ base: "70%", sm: "200px" }}
                    src="/img/lucknow.jpg"
                    alt="..."
                  // height="140px"
                  // width="140px"
                  />

                  <Stack>
                    <CardBody>
                      <h4
                        className="text-blue"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        Lucknow Office
                      </h4>

                      <p
                        className="mb-0 text-dark opacity8 small"
                        data-aos="fade-up"
                        data-aos-duration="600"
                      >
                        C-25, Aliganj, Lucknow - 226024, Uttar Pradesh, India.
                      </p>
                      <IconButton
                        variant="inline"
                        colorScheme="#056894"
                        aria-label="Send email"
                        fontSize="20px"
                        icon={<EmailIcon color="#03b5be" />}
                        data-aos="fade-up"
                        data-aos-duration="500"
                      />
                      <a
                        data-aos="fade-up"
                        data-aos-duration="500"
                        href="mailto:info@onebigbit.com"
                        className="mb-0 text-dark opacity8 small topindex"
                      >
                        info@onebigbit.com
                      </a>
                      <br />
                      <IconButton
                        variant="inline"
                        colorScheme="#056894"
                        aria-label="Call Sage"
                        fontSize="20px"
                        icon={<PhoneIcon color="#03b5be" />}
                        data-aos="fade-up"
                        data-aos-duration="500"
                      />
                      <a
                        href="tel:+91-522-431-6496"
                        className="mb-0 text-dark opacity8 small topindex"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        +91-522-431-6496
                      </a>
                    </CardBody>
                  </Stack>
                </Card>
                <Card
                  direction={{ base: "column", sm: "row" }}
                  overflow="hidden"
                  variant="outline"
                >
                  <Image
                    objectFit="cover"
                    maxWidth={{ base: "70%", sm: "200px" }}
                    src="/img/burj-khalifa.png"
                    alt="..."
                    data-aos="fade-up"
                    data-aos-duration="500"
                  // height="140px"
                  // width="140px"
                  />

                  <Stack>
                    <CardBody>
                      <h4
                        className="text-blue"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        Dubai Office
                      </h4>
                      <IconButton
                        variant="inline"
                        colorScheme="#056894"
                        aria-label="Send email"
                        fontSize="20px"
                        icon={<EmailIcon color="#03b5be" />}
                        data-aos="fade-up"
                        data-aos-duration="500"
                      />
                      <a
                        href="mailto:saima@onebigbit.com"
                        className="mb-0 text-dark opacity8 small topindex"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        saima@onebigbit.com
                      </a>
                      <br />
                      <IconButton
                        variant="inline"
                        colorScheme="#056894"
                        aria-label="Call Sage"
                        fontSize="20px"
                        icon={<PhoneIcon color="#03b5be" />}
                        data-aos="fade-up"
                        data-aos-duration="500"
                      />
                      <a
                        href="tel:+971-58-677-2622"
                        className="mb-0 text-dark opacity8 small topindex"
                        data-aos="fade-up"
                        data-aos-duration="500"
                      >
                        +971-58-677-2622
                      </a>
                    </CardBody>
                  </Stack>
                </Card>
              </SimpleGrid>
              <div>
                {/* <img src="/img/img-newsletter.png" alt="..." /> */}
                {/* <Lottie
                    animationData={cu}
                    data-aos="fade-up"
                    data-aos-duration="500"
                  /> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Contact;
