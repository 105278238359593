import React from 'react'
import { Image } from "react-bootstrap";

const CircleCard = ({data}) => {
    return (
        <>
            {/* <div className=" col-lg-3 col-md-12 col-sm-12 col-12 d-flex justify-content-center align-items-center ">
                <div className="circular_card " data-aos="zoom-out-up" data-aos-duration={props.delay}>
                    <div className="card_contain">
                        <img src={props.icon} className={props.img_css} alt="" />
                        <p>{props.text}</p>
                    </div>
                </div>
            </div> */}

            {data &&
                data.map((d, index) => (
                    <div
                        key={index}
                        className={`col-sm-12 d-flex justify-content-center align-items-center mt-5 ${window.innerWidth > 992 && window.innerWidth < 1200 && index === 3
                                ? "ml-circle"
                                : ""
                            }`}
                    >
                        <div className="circular_card" data-aos="zoom-out-up" data-aos-duration={d.delay}>
                            <div className="card_contain">
                                <div className="d-flex justify-content-center align-items-center">
                                    <Image src={d.icon} alt="....." className="img_css" />
                                </div>
                                <p>
                                    <span dangerouslySetInnerHTML={{ __html: d.name }} />
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
       
        </>
    )
}

export default CircleCard
