import { Box, chakra, Stack, Text, VisuallyHidden, useColorModeValue } from "@chakra-ui/react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whi#056894pha.100")}
      rounded={"full"} w={8} h={8} cursor={"pointer"} as={"a"} href={href}
      display={"inline-flex"} alignItems={"center"} justifyContent={"center"} transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whi#056894pha.200"),
      }} >
      <VisuallyHidden>{label}</VisuallyHidden> {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithNewsletter() {
  return (
    <>
      <footer>
        <Box className="footer_svg_set text-white pb-0 " variant="dark">
          <div className="bg_ar_img">
            <Container className="footer_box_ff  py-5">
              <div className="row Footer_svgCSs">
                <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                  <Stack>
                    <Box>  <Link to="/">  <img src="/img/footer-logo.png" className="mb-0" alt="..." />  </Link></Box>
                    <Text fontSize={"sm"} fontWeight={300} className="footer_txt  p-4">
                      Objectively integrate enterprise wide strategic theme areas
                      with functionalized infrastructures. Interactively
                      productize premium mobile technologies.
                    </Text>
                    <Stack direction={"row"} spacing={6} pl={6} style={{ marginTop: '-20px' }}>
                      <SocialButton label={"FB"} href={"#"}>
                        <a href="https://www.facebook.com/onebigbittech/" target="_blank">
                          <i className="fab fa-facebook-f" />
                        </a>
                      </SocialButton>
                      <SocialButton label={"Instagram"} href={"#"}>
                        <a href="https://www.instagram.com/onebigbit/" target="_blank">
                          <i className="fab fa-instagram" />
                        </a>
                      </SocialButton>
                      <SocialButton label={"Likedin"} href={"#"}>
                        <a href="https://www.linkedin.com/company/onebigbit/" target="_blank" >
                          <i className="fab fa-linkedin-in" />
                        </a>
                      </SocialButton>
                    </Stack>
                  </Stack>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                  <Stack>
                    <ListHeader color="#03b5be">Services</ListHeader>
                    <Box py={1}><Link to="/service/softwareDevelopment">Software Development</Link> </Box>
                    <Box py={1}><Link to="/service/appDevelopment"> App Development</Link></Box>
                    <Box py={1}><Link to="/service/mixedReality"> Mixed Reality</Link> </Box>
                    <Box py={1}><Link to="/service/digitalMarketing"> Digital Marketing </Link> </Box>
                    <Box py={1}><Link to="/"> Serverless Hosting</Link></Box>
                  </Stack>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-12">
                  <Stack>
                    <ListHeader color="#03b5be">Quick Link</ListHeader>
                    <Box py={1}> <Link to="/approach"> Our Approach</Link> </Box>
                    <Box py={1}> <Link to="/products"> Our Products</Link> </Box>
                    <Box py={1}><Link to="/career"> Career With Us</Link> </Box>
                    <Box py={1}> <Link href="/">Site Map</Link></Box>
                    <Box py={1}><Link href="/">Blog</Link></Box>
                  </Stack>
                </div>
                <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6 col-12">
                  <Stack>
                    <ListHeader color="#03b5be">Contact US</ListHeader>
                    <Stack>
                      <Text fontSize={"sm"} className="footer_txt mb-0">
                        <a href="tel:+91-522-431-6496"> <i className="fa fa-phone"></i> &nbsp;  +91-522-431-6496 </a>
                        , <a href="tel:+91-800-400-8123">+91-800-400-8123 </a>
                      </Text>
                      <Text fontSize={"sm"} className="footer_txt mb-0">
                        <a href="mailto:info@onebigbit.com"> <i className="far fa-envelope" />  &emsp; info@onebigbit.com </a>
                      </Text>
                      <Text fontSize={"sm"} className="footer_txt mb-0">
                        <a  href="https://maps.app.goo.gl/yzNAjtEW6QC8rMy56" target="_blank" >
                          <i className="fa fa-location-arrow" />
                          &emsp; C-25, Aliganj, Lucknow - 226024, Uttar Pradesh, India.
                        </a>
                      </Text>
                      <Text fontSize={"sm"} className="footer_txt mb-0 mt-1">
                        <img src="/img/iso.png" alt="" />
                      </Text>
                    </Stack>
                  </Stack>
                </div>
              </div>
            </Container>
            <div className="footer-bar wow fadeIn" data-wow-delay="200ms">
              <div className="container">
                <div className="row">
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10 col-md-12 col-sm-12 col-12 text-center">
                    <p style={{ fontSize: "14px" }}>
                      Copyright © 2022  <span className="current-year" />
                      All Rights Reserved -
                      <Link to="https://onebigbit.com/" className="text-white-hover" >
                        &nbsp;OneBigBit Technologies Private Limited
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </footer>
    </>
  );
}
