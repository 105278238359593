import React from "react";
import PageTitle from "../Common/PageTitle";
import productdata from "./product.json";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const Products = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          title="Our Products That You Might Need"
          desc="Get started with us and get yourself bulked up with the most optimized software designed by us. We've built more than 250 successful projects. Call us now and get your desired IT solution."
          bimg="img/product-banner.jpg"
          page="Products"
        />
        <section className="dot-pattern">
          <div className="container ">
            <div className="col-md-12 mt-5  ">
              <SimpleGrid
                spacing={90}
                className="product_card"
                templateColumns="repeat(auto-fill, minmax(295px, 1fr))"
                //columns={{ base: 1, md: 2, lg: 3 }}
              >
                {productdata &&
                  productdata.map((data, index) => (
                    <Card
                      data-aos="fade-up"
                      data-aos-duration="200"
                      borderTop="8px"
                      borderColor="#056894"
                      borderLeft="1px"
                      borderRight="1px"
                      className="product_card_css topindex"
                    >
                      <CardHeader className=" pt-4 d-flex justify-content-center align-items-center">
                        <Image
                          src={data.image}
                          alt="..."
                          borderRadius="lg"
                          maxWidth="295px"
                          maxHeight="205px"
                        />
                      </CardHeader>
                      <CardBody
                        style={{
                          border: "0",
                          borderTopLeftRadius: "10%",
                          borderTopRightRadius: "10%",
                          filter: "brightness(1.6)",
                        }}
                        className=""
                      >
                        <div className=" title_prod ">
                          <h5
                            size="md"
                            className="mb-3 Product_text_color text-center  "
                          >
                            {data.title}
                          </h5>
                        </div>
                        <p className="fw-500 para_text">{data.desc}</p>
                        <CardFooter className="p-0  ">
                          <Link to={data.link} className="Product_text_color ">
                            Know More <ArrowForwardIcon />
                          </Link>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  ))}
              </SimpleGrid>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Products;
