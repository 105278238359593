import React from "react";
import PageTitle from "../../Common/PageTitle";
import {
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  Box,
} from "@chakra-ui/react";
import { CheckIcon, MinusIcon, AddIcon, StarIcon } from "@chakra-ui/icons";

import Aprocess from "./Feature/Admissionprocess.json";
import timelinedata from "./Feature/Admissiontimeline.json";
import module from "./Feature/Admissionmodule.json";
import Timeline from "../../component/Timeline";
import Lastpoint from "../../component/Lastpoint";

const AdmissionMS = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Software Development"
          title="Admission Management Software"
          desc="With the Admission Management Software, keep an eye on every student. From private institutes to colleges, this admission management software is your best bet to grow admission software."
          bimg="/img/admission-management-software-banner.jpg"
          page="Admission Management Software"
        />
        <div className="container ">
          <div className=" col-md-12   mt-5  text-center ">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Do You Need</span>
              </h2>
            </div>
            <h5
              className="After_title"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Admission Management Software?
            </h5>
          </div>
          <div className=" col-md-12    mb-5">
            <div className="row mt-n1-9 ">
              <div className="col-lg-7 wow fadeInUp mt-1-9  ">
                <div className="pe-2  mt-5">
                  <List spacing={3}>
                    <ListItem data-aos="fade-up" data-aos-duration="500">
                      <ListIcon as={CheckIcon} color="#056894" />
                      After COVID-19 many industries got upraised but some
                      experienced a legit downfall. Online technology and other
                      solutions are the only choices to feft.
                    </ListItem>
                    <ListItem data-aos="fade-up" data-aos-duration="500">
                      <ListIcon as={CheckIcon} color="#056894" />
                      We came up with an idea to offer you online admission
                      software. The admission management software is bulked up
                      with Aprocesss and runs on cutting-edge technology. With
                      the help of our admission management software, you can
                      experience an instant abundance of leads.
                    </ListItem>
                  </List>
                </div>
              </div>
              <div className="col-lg-5 mt-1-9 wow fadeIn  mt-5">
                <img
                  src="/img/admission-management-1.png"
                  className=" mb-4"
                  alt="..."
                  data-aos="fade-up"
                  data-aos-duration="500"
                />
              </div>
              <div className="col-lg-5 mt-1-9 wow fadeIn  mt-5">
                <img
                  src="/img/admission-management-2.png"
                  className=" mb-4"
                  alt="..."
                  data-aos="fade-up"
                  data-aos-duration="500"
                />
              </div>
              <div className="col-lg-7 wow fadeInUp mt-1-9  ">
                <div className="pe-2  mt-5">
                  <List spacing={3}>
                    <ListItem data-aos="fade-up" data-aos-duration="500">
                      <ListIcon as={CheckIcon} color="#056894" />
                      The increasing number of students seeking admissions in
                      school and colleges are causing bumper pressure on the
                      administrative department of schools and colleges to
                      manage the complete process related to admission. At
                      present time, it is difficult to arrange and manage all
                      the complete processes timely and accurately.
                    </ListItem>
                    <ListItem data-aos="fade-up" data-aos-duration="500">
                      <ListIcon as={CheckIcon} color="#056894" />
                      So we obviously need online admission management software
                      for a piece of accurate related information for your
                      school growth and for parents who need to know about
                      school and management via using digital platforms.
                    </ListItem>
                  </List>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="softwareDevpl_bgImg">
          <div
            className=" mb-5 rounded-lg mt-5 mb-5  onsoftwareDevpl_BGlayer"
            style={{
              padding: "20px",
            }}
          >
            <section className="mb-10">
              <div className="container ">
                <div className="col-md-12   mt-5 mb-5 ">
                  <div className="text-color text-center text-white">
                    <h2 data-aos="fade-up" data-aos-duration="400">
                      <span>Why Use Our</span>
                    </h2>
                  </div>
                  <h5
                    className="After_title mb-4 text-white"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    Admission Management Software?
                  </h5>

                  <p
                    className="lead mt-4 fw-300 text-white"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    The admission management software keeps an eye on every
                    activity related to school and college. Choosing the best
                    admission management software to get rid of lots of problems
                    faced by the school staff and student’s parents. Some of the
                    advantages of using the admission management software are as
                    follows
                  </p>

                  <SimpleGrid
                    className="stepProcess_section"
                    columns={{ base: 1, sm: 2, md: 2, lg: 3 }}
                    spacing={8}
                  >
                    <Card
                      borderBottom="8px"
                      borderColor="#056894"
                      data-aos="fade-up"
                      data-aos-duration="100"
                    >
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <img src="/img/availability.png" alt="..." />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-center text-blue">
                          24*7 Availability
                        </h5>
                        <p>
                          Choosing the online admission management system helps
                          you in saving your valuable time and you don't need to
                          worry about the submission according to the framed
                          time. The admission management software is available
                          all the time to help you in the activity related to
                          school admission, fees submission, etc.
                        </p>
                      </CardBody>
                    </Card>
                    <Card
                      borderBottom="8px"
                      borderColor="#056894"
                      data-aos="fade-up"
                      data-aos-duration="300"
                    >
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <img src="/img/increase-accuracy.png" alt="..." />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-center text-blue">
                          Increase Accuracy
                        </h5>
                        <p>
                          An online admission management system helps in
                          increasing the accuracy of the document related to the
                          admission of the student. The staff of school or
                          college also has a lot of work to do which increases
                          the chances of human error. Online admission
                          management helps in reducing the chances of mistakes
                          and misconceptions.
                        </p>
                      </CardBody>
                    </Card>
                    <Card
                      borderBottom="8px"
                      borderColor="#056894"
                      data-aos="fade-up"
                      data-aos-duration="500"
                    >
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <img src="/img/convenience.png" alt="..." />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-center text-blue">
                          Convenience
                        </h5>
                        <p>
                          Online AMS offers you to do all the essential work
                          related to school and college effectively and
                          efficiently. An Admission and enrollment management
                          software help in proceeding with all the related
                          activities smoothly and instantly. Online admission
                          management systems become more convenient for schools
                          and colleges.
                        </p>
                      </CardBody>
                    </Card>
                    <Card
                      borderBottom="8px"
                      borderColor="#056894"
                      data-aos="fade-up"
                      data-aos-duration="100"
                    >
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <img src="/img/ican/geographical.png" alt="..." />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-center text-blue">
                          No Geographical Barriers
                        </h5>
                        <p>
                          College Admission Management Software provides the
                          ability to break all the geographical barriers related
                          to the admission process. With the help of online
                          admission management, students can apply from anywhere
                          and from any religion which results in a good quality
                          reputation of colleges and Schools.
                        </p>
                      </CardBody>
                    </Card>
                    <Card
                      borderBottom="8px"
                      borderColor="#056894"
                      data-aos="fade-up"
                      data-aos-duration="300"
                    >
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <img src="/img/cost-effective.png" alt="..." />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-center text-blue">
                          Cost - Effectivity
                        </h5>
                        <p>
                          The Admission & Enrollment Management Software is not
                          more costly as compared to another offline admission
                          process. The online AMS reduced all the extra efforts
                          and costs needed for admission. The online AMS reduces
                          extra Manpower, cost of advertisements for the
                          promotion.
                        </p>
                      </CardBody>
                    </Card>
                    <Card
                      borderBottom="8px"
                      borderColor="#056894"
                      data-aos="fade-up"
                      data-aos-duration="500"
                    >
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <img src="/img/sustainability.png" alt="..." />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-center text-blue">
                          Sustainability
                        </h5>
                        <p>
                          Student admission and registration management software
                          offer to become the related process more sustainable
                          and user-friendly. In school and college, the
                          admission process needs a lot of paperwork as also
                          documents related to submission, Student admission,
                          and registration management Software.
                        </p>
                      </CardBody>
                    </Card>
                  </SimpleGrid>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="container ">
          <div className=" col-md-12   mt-5 mb-5 text-center dot-pattern">
            <div
              className="top_title text-center"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              <h2>
                <span>Online Admission</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Management System’s Process
            </h5>

            <p
              className="lead mt-4 fw-300 ml-10 mr-10"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              The online admission management system has some processes on which
              the admission management system works. Steps and processes
              followed by the admission management system are the following.
            </p>
          </div>

          <div className="col-md-12 mt-5">
            <SimpleGrid
              spacing={4}
              //  templateColumns="repeat(auto-fill, minmax(255px, 1fr))"
              columns={{ base: 1, sm: 1, md: 2, lg: 4 }}
            >
              {Aprocess &&
                Aprocess.map((data, index) => (
                  <Card
                    className=" d-flex justify-content-center align-items-center"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <CardHeader>
                      <Image src={data.image} alt="....." />
                    </CardHeader>
                    <CardBody>
                      <h5 size="md" className="text-center text-color">
                        step - {index + 1}
                      </h5>
                      <p>{data.desc}</p>
                    </CardBody>
                  </Card>
                ))}
            </SimpleGrid>
          </div>
        </div>
        <div className=" col-md-12   mt-5 mb-5 text-center gradientbg_blue ">
          <h2
            className=" text-white mb-2 mt-10 font-itelic "
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Required Features For
          </h2>
          <h2
            className="text-white  mb-2 font-itelic "
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Admission Management Software
          </h2>
          <p
            className="lead mt-4 text-white fw-300"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Some of the most important and required features of the admission
            management software are as follows
          </p>
          <br />

          <Timeline timelinedata={timelinedata} />
        </div>
        <div className="container ">
          <div className=" col-md-12   mt-5 mb-5 text-center dot-pattern ">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="200">
                <span> Modules Of Best</span>
              </h2>
            </div>
            <h5
              className="After_title"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Online Admission Software?
            </h5>

            <p
              className="lead mt-4  fw-300 ml-10 mr-10 "
              data-aos="fade-up"
              data-aos-duration="500"
            >
              School admission management software has some modules which are
              used in school and college which manage the entire process of
              admission of students.
              <br /> Some of the modules admission and enrollment management
              software uses are as follows
            </p>
            <br />
          </div>
          <div className="col-md-12 mt-5 mb-10 dot-pattern">
            <SimpleGrid
              spacing={4}
              templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
            >
              {module &&
                module.map((data, index) => (
                  // <Card className=" d-flex justify-content-center align-items-center">
                  <Card data-aos="fade-up" data-aos-duration="500">
                    <CardBody>
                      <List spacing={3}>
                        <ListItem className=" text-color">
                          <ListIcon as={StarIcon} color="#03b5be" />

                          {data.title}
                        </ListItem>
                      </List>
                      <p>{data.desc}</p>
                    </CardBody>
                  </Card>
                ))}
            </SimpleGrid>
          </div>
          <div className=" col-md-12   mt-5 mb-5 text-center dot-pattern ">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>FAQ's For</span>
              </h2>
            </div>
            <h5
              className="After_title mb-2"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Admission Management System
            </h5>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <Accordion allowToggle>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is an admission management system ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Admission management system is a process that is
                      responsible for managing all the related activities of
                      schools and colleges including online fees payment, online
                      registration, etc.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is an online admission management system ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Online admission management system is the procedure that
                      allows schools and colleges to manage all the activities
                      digitally. School admission management software helps in
                      providing all the related information accurately and in
                      minimum time.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is the student Admission Management System ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      A student management system is a type of software that
                      will be responsible for providing all the necessary
                      details related to a student’s admission, enrollment,
                      exams notification, etc. Student management software
                      provides all the details digitally.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What are the advantages of Admission Management
                          Software ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Admission management software has some advantages and
                      these are the following
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Best admission management software for schools is 24*7
                          available
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Best admission management software for schools
                          increases accuracy
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Best admission management software for schools is also
                          cost-effective
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          There will be geographical barriers by using Best
                          admission management software for schools
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Many more
                        </ListItem>
                      </List>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Do you develop a fantasy sports app for kabaddi and
                          bricks?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Obviously Yes, we develop a fantasy sports app for all
                      kinds of games including cricket, kabaddi, chess, ludo,
                      bricks, run boy run, and many more for daily and
                      season-long fantasy sports Web
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is Admissions and Enrollment Management Software
                          ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      The admission and enrollment management software keeps an
                      eye on every activity related to school and college.
                      Choosing the best admission management software for
                      schools to get rid of lots of problems faced by the school
                      staff and student’s parents. The admission and enrollment
                      management software helps in managing the admission and
                      enrollment process completely.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Why Use Admissions and Enrollment Management Software
                          ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Admissions and Enrollment Management Software used because
                      of the following Reasons
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          One piece of software, multiple campuses
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Have the power of mobile-ready admission
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Online admission forms
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Empowered teams with data insights
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Many more
                        </ListItem>
                      </List>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </div>
          <Lastpoint />
        </div>
      </div>
    </>
  );
};
export default AdmissionMS;
