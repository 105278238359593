import { Card, CardHeader, CardBody } from "@chakra-ui/react";

const ReasonsToChoose = (props) => {
    return (
        <>
            <Card
                borderTop="8px"
                borderColor="#056894"
                data-aos="fade-up"
                data-aos-duration="700"
            >
                <CardHeader className="d-flex justify-content-center align-items-center">
                    <img
                        src={props.img}
                        alt=",,,"
                    />
                </CardHeader>
                <CardBody>
                    <h5 size="md" className=" text-blue text-center">{props.title}

                    </h5>
                    <p>{props.txt}
                    </p>
                </CardBody>
            </Card>
        </>
    )
}

export default ReasonsToChoose
