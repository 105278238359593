import React from "react";
import PageTitle from "../../Common/PageTitle";
import { SimpleGrid, Accordion, AccordionItem, AccordionButton, AccordionPanel, List, ListItem, ListIcon, Box} from "@chakra-ui/react";
import { CheckIcon, MinusIcon, AddIcon} from "@chakra-ui/icons";
import feature from "./Feature/learningfeatures.json";
import Customercare from "../../component/Customercare";
import Featuredata from "../../component/Featuredata";
import Lastpoint from "../../component/Lastpoint";

const LearningMS = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="Learning Management System (LMS)"
          desc="A Learning Management System (LMS) is a software application or platform designed to facilitate the administration, management, and delivery of educational courses and training programs."
          bimg="https://t4.ftcdn.net/jpg/04/65/50/47/360_F_465504734_jaUznFLdYP7BTgapq5KzR2lGdUUz2Res.jpg"
          page="Learning Management System "
        />
        <div className="container ">
          <div className=" col-md-12   mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>Do You Know About</span>
              </h2>
            </div>
            <h5  className="After_title mb-4"  data-aos="fade-up"  data-aos-duration="500" >
              Learning Management System (LMS)  </h5>
            <p className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              A CRM (Customer Relationship Management) project is a specific
              undertaking within an organization aimed at implementing CRM
              software or processes to manage and enhance interactions with
              customers and improve overall customer relationships. CRM projects
              are typically focused on achieving specific goals related to
              customer satisfaction, sales growth, marketing effectiveness, and
              customer service improvement. Here are key elements of a CRM
              project.
            </p>
          </div>
          <div className=" col-md-12    mb-5">
            <div className="row mt-n1-9 ">
              <div className="col-lg-7 wow fadeInUp mt-1-9  ">
                <div className="pe-2  mt-5">
                  <p className="lead mt-4 fw-300">
                    <strong>
                      Here are some key components and features of an LMS:
                    </strong>
                  </p>
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />A Learning
                      Management System (LMS) can be thought of as a digital
                      platform or software solution that serves as a
                      one-stop-shop for all things related to learning and
                      training. It's like an online campus or a virtual school
                      where instructors and learners come together to access,
                      manage, and interact with educational content and
                      resources.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      It acts as a library where all course materials, lectures,
                      assignments, and resources are neatly organized, making it
                      easy for both instructors and learners to find what they
                      need Instructors use it as their virtual classroom or
                      training center. They can upload course content, set up
                      assessments, communicate with students, and track their
                      progress—all from one place.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      For students or employees, it's like a personalized
                      learning portal. They can access course materials, engage
                      with fellow learners, submit assignments, take quizzes,
                      and receive feedback, all while keeping track of their own
                      learning journey. Administrators have control over user
                      management. They can add new learners, assign roles and
                      permissions, and ensure the system is secure and compliant
                      with data privacy regulations.
                    </ListItem>
                  </List>
                </div>
              </div>
              <div className="col-lg-5 mt-1-9 wow fadeIn  mt-5">
                <img
                  src="/img/lms2.png"
                  className=" mb-4"
                  alt="..."
                  data-aos="fade-up"
                  data-aos-duration="600"
                />
              </div>
              <div className="col-lg-5 mt-1-9 wow fadeIn  mt-5">
                <img
                  src="/img/lms4.png"
                  className=" mb-4"
                  alt="..."
                  data-aos="fade-up"
                  data-aos-duration="600"
                />
              </div>
              <div className="col-lg-7 wow fadeInUp mt-1-9  ">
                <div className="pe-2  mt-5">
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      Many modern LMSs provide mobile-friendly interfaces or
                      dedicated mobile apps, allowing learners to access content
                      and resources on smartphones and tablets.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      LMSs can integrate with other software and systems, such
                      as HR software, content authoring tools, video
                      conferencing platforms, and analytics tools. These
                      integrations enhance the overall functionality of the LMS.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      LMSs offer analytics and reporting features that provide
                      insights into learner behavior and course effectiveness.
                      This data is valuable for making informed decisions and
                      improving courses.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      Some LMSs incorporate gamification elements, such as
                      badges, leaderboards, and rewards, to increase learner
                      engagement and motivation.
                    </ListItem>
                  </List>
                </div>
              </div>
            </div>
          </div>
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>Features For</span>
              </h2>
            </div>
            <h5
              data-aos="fade-up"
              data-aos-duration="500"
              className="After_title mb-4"
            >
              Learning Management System (LMS)
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Learning Management Systems (LMS) come with a wide range of
              features and functionalities to support various aspects of the
              learning and training process. Here are some common features you
              can expect to find in an LMS:
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={4}
              templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
              //columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
            >
              <Featuredata feature={feature} />
            </SimpleGrid>
          </div>
          <div className=" col-md-12   mt-5 mb-5 ">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> FAQ's About</span>
              </h2>
            </div>
            <h5
              data-aos="fade-up"
              data-aos-duration="600"
              className="After_title mb-4"
            >
              Learning Management System (LMS) Project
            </h5>
            <p className="lead mt-4 fw-300">
              Certainly, here are some frequently asked questions (FAQs) related
              to Learning Management System :
              <br />
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <Accordion allowToggle>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is a Learning Management System (LMS)?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      An LMS is a software platform that facilitates the
                      management, delivery, and tracking of educational courses
                      and training programs. It serves as a central hub for
                      learners, instructors, and administrators.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What types of content can be hosted on the LMS?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      LMSs support various content types, including text,
                      images, videos, documents, quizzes, assignments, and more.
                      They often accommodate multimedia and interactive content.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How will user data and privacy be managed?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      The LMS should adhere to data privacy regulations and
                      include security measures to protect user data.
                      Information on data handling and privacy policies should
                      be communicated to users.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What are the technical requirements for using the LMS?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Ensure that users have the necessary hardware, software,
                      and internet connectivity to access and use the LMS
                      effectively.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is the timeline for the LMS project's
                          implementation?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Understand the project timeline, including milestones,
                      testing phases, and the expected launch date.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is the process for content creation and
                          management within the LMS?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Clarify how courses will be created, updated, and managed,
                      including who will be responsible for content development.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <Customercare />
        <Lastpoint />
      </div>
    </>
  );
};
export default LearningMS;
