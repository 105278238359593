import React from "react";
import PageTitle from "../../Common/PageTitle";
import {
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  Stack,
  Box,
} from "@chakra-ui/react";
import { CheckIcon, MinusIcon, AddIcon } from "@chakra-ui/icons";
import Lottie from "lottie-react";
import a from "../../component/jsonfile/appointment.json";
import m from "../../component/jsonfile/mobilebok.json";
import o from "../../component/jsonfile/online.json";
import feature from "./Feature/saloonfeature.json";
import timelinedata from "./Feature/salontimeline.json";
import Timeline from "../../component/Timeline";
import RaiseaQuery from "../../component/RaiseaQuery";
import Featuredata from "../../component/Featuredata";
import Testimonials from "../../component/CardContain/Testimonials";
import testimonialdata from "../../component/Testimonials/SalonTestimonials.json";

const SalonMS = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="Salon Management System"
          desc="Salon Management Software is here to manage all the necessary things in one-step with the best salon management software India. Best salon management software provides everything which you need to enhance appointment scheduling, customer management, sales, product, and much more. Salon Management Software India gives an ALL-in-ONE management process that goes beyond POS and appointment booking."
          bimg="/img/banner/sms.png"
          page="Salon Management System"
        />
        <div className="container ">
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Main Features</span>
              </h2>
            </div>
            <h5
              data-aos="fade-up"
              data-aos-duration="600"
              className="After_title mb-4"
            >
              For Salon Management System
            </h5>

            <p className="lead mt-4 fw-300">
              Our best beauty salon management software is fitted with
              everything you want to generate your revenue, saves your time, and
              helps in making smart decisions in the salon and beauty industry.
              Inspect the features of this most loved tool with management
              software for salons. Are you ready to make your business better?
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            {window.innerWidth > 992 && (
              <>
                <SimpleGrid
                  className="mb-3"
                  spacing={4}
                  columns={{ base: 1, sm: 1, md: 1, lg: 3 }}
                >
                  <Featuredata feature={feature.slice(0, 3)} />
                </SimpleGrid>
                <SimpleGrid
                  className="mb-3"
                  spacing={4}
                  columns={{ base: 1, sm: 1, md: 1, lg: 2 }}
                >
                  <Featuredata feature={feature.slice(3, 5)} />
                </SimpleGrid>
              </>
            )}
            {window.innerWidth < 992 && (
              <>
                <SimpleGrid
                  className="mb-3"
                  spacing={4}
                  columns={{ base: 1, sm: 1, md: 2, lg: 2 }}
                >
                  <Featuredata feature={feature.slice(0, 4)} />
                </SimpleGrid>
                <SimpleGrid
                  className="mb-3"
                  spacing={4}
                  columns={{ base: 1, sm: 1, md: 1, lg: 1 }}
                >
                  <Featuredata feature={feature.slice(4, 5)} />
                </SimpleGrid>
              </>
            )}
            {/* {window.innerWidth < 768 && (
              <>
                <SimpleGrid
                  className="mb-3"
                  spacing={4}
                  columns={{ base: 1, sm: 1, md: 2, lg: 3 }}
                >
                  <Featuredata feature={feature} />
                </SimpleGrid>
              </>
            )} */}
          </div>
          <br />
          <br />
        </div>
        <div className="softwareDevpl_bgImg">
          <div
            className=" mb-5 rounded-lg mt-5 mb-5  onsoftwareDevpl_BGlayer"
            style={{
              padding: "20px",
            }}
          >
            <section className="mb-10">
              <div className="container ">
                <div className="col-md-12   mt-5 mb-5 ">
                  {/* <Card> */}
                  {/* <CardHeader> */}
                  <div className="green_title text-center ">
                    <h2 data-aos="fade-up" data-aos-duration="500">
                      <span> Make Everyday Easy With The</span>
                    </h2>
                  </div>
                  <h5
                    className="After_title mb-4 text-white"
                    data-aos="fade-up"
                    data-aos-duration="600"
                  >
                    Best Salon Management Software Solutions.
                  </h5>

                  <p className="lead mt-4 fw-300 text-white">
                    Best Salon Management Software Solutions make your daily
                    life very easy. You can make your every day a peaceful day
                    with the industry's best salon booking software. You can
                    book from anywhere and at any time with the best salon
                    management software India.
                  </p>
                  {/* <hr /> */}
                  {/* </CardHeader> */}
                  {/* <CardBody className="mb-10"> */}
                  <SimpleGrid
                    className="stepProcess_section"
                    columns={{ base: 1, md: 3 }}
                    spacing={8}
                  >
                    <Card
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      borderBottom="8px"
                      borderColor="#056894"
                    >
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <Lottie animationData={a} />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className=" text-blue text-center">
                          Appointment Booking
                        </h5>
                      </CardBody>
                    </Card>

                    <Card
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      borderBottom="8px"
                      borderColor="#056894"
                    >
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <Lottie animationData={o} />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className=" text-blue text-center">
                          Online Booking
                        </h5>
                      </CardBody>
                    </Card>
                    <Card
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      borderBottom="8px"
                      borderColor="#056894"
                    >
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <div style={{ width: "100px", height: "100px" }}>
                          <Lottie animationData={m} />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className=" text-blue text-center">
                          Mobile Booking
                        </h5>
                      </CardBody>
                    </Card>
                  </SimpleGrid>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="container ">
          {/* <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>Video Gallery</span>
              </h2>
            </div>
            <h5
              data-aos="fade-up"
              data-aos-duration="600"
              className="After_title mb-4"
            >
              Salon Management Software
            </h5>
            <p className="lead mt-4 fw-300">
              Checkout our salon management software demo and let's get started
              with the best salon software in India. With this salon management
              software demo video you will get an overview about the best salon
              management softare in India.
            </p>
          </div>
          <div className="mb-2  col-md-12 d-flex justify-content-center align-items-center ">
            <video controls>
              <source src="/assets/salon_management.mp4" type="video/mp4" />
            </video>
          </div> */}
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>Work In</span>
              </h2>
            </div>
            <h5
              data-aos="fade-up"
              data-aos-duration="600"
              className="After_title mb-4"
            >
              A Process That Works Best For You
            </h5>

            <p className="lead mt-4 fw-300">
              Salon account manager software will work in a manner that will
              work best for you which results in increasing your salon’s name in
              the market effectively. The process on which salon account manager
              software works are as follows
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={4}
              className="addcss_onPara"
              //  templateColumns="repeat(auto-fill, minmax(355px, 1fr))"
              columns={{ base: 1, sm: 1, md: 1, lg: 3 }}
            >
              <Card>
                <CardHeader className=" d-flex justify-content-center align-items-center">
                  <div style={{ width: "100px", height: "100px" }}>
                    <Image src="/img/support-2.png" alt="....." />
                  </div>
                </CardHeader>
                <CardBody>
                  <h5 size="md" className=" text-blue text-center">
                    Support
                  </h5>
                  <p>
                    24*7 We are here to help you, our team members are always
                    there to help you. Your experience and success with using
                    beauty salon management software are important to us.
                  </p>
                </CardBody>
              </Card>
              <Card>
                <CardHeader className=" d-flex justify-content-center align-items-center">
                  <div style={{ width: "100px", height: "100px" }}>
                    <Image src="/img/easy-access.png" alt="....." />
                  </div>
                </CardHeader>
                <CardBody>
                  <h5 size="md" className=" text-blue text-center">
                    Easy Access
                  </h5>
                  <p>
                    With the help of beauty salon management software, the owner
                    of the salon will easily access all the activities
                    digitally. Beauty salon management software helps in making
                    online scheduling and management easy.
                  </p>
                </CardBody>
              </Card>
              <Card>
                <CardHeader className=" d-flex justify-content-center align-items-center">
                  <div style={{ width: "100px", height: "100px" }}>
                    <Image src="/img/comfortable.png" alt="....." />
                  </div>
                </CardHeader>
                <CardBody>
                  <h5 size="md" className=" text-blue text-center">
                    Comfortable
                  </h5>
                  <p>
                    Best salon management software will help in managing all the
                    activities of your salon in one place. By using your desktop
                    or your smartphone, you will get notifications directly like
                    follow-up history of the customers.
                  </p>
                </CardBody>
              </Card>
            </SimpleGrid>
          </div>
          <br />
        </div>

        <div className="container-fluid p-0">
          <div className=" col-md-12 py-4 mt-5 mb-5 text-center bg-blue gradientbg_blue">
            <h2 className=" text-white pt-3  mb-2 font-itelic ">Why Are We</h2>
            <h2 className="text-white  mb-2 font-itelic ">
              Always Being Selected
            </h2>
            <p className="lead mt-4 text-white fw-300">
              Salon management software India makes your daily life very easy.
              You can make your every day a peaceful day with the industry's
              best salon booking software.
            </p>
            <br />

            <Timeline timelinedata={timelinedata} />
          </div>
        </div>

        <div className="container">
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>Payment Processing Of Salon</span>
              </h2>
            </div>
            <p className="lead mt-4 fw-300">
              We are a salon business management software that also helps you in
              providing a solution by which you will get more visibility and
              control over your finances. Some of the main solutions we will
              provide are
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-10 ">
            <SimpleGrid
              spacing={4}
              //  templateColumns="repeat(auto-fill, minmax(355px, 1fr))"
              columns={{ base: 1, sm: 1, md: 1, lg: 3 }}
            >
              <Card>
                <CardHeader className=" d-flex justify-content-center align-items-center">
                  <Image src="/img/gif/unified-payment.png" alt="....." />
                </CardHeader>
                <CardBody>
                  <h5 size="md" className=" text-blue text-center">
                    Unified payments
                  </h5>
                </CardBody>
              </Card>
              <Card>
                <CardHeader className=" d-flex justify-content-center align-items-center">
                  <Image src="/img/gif/point-of-sale.png" alt="....." />
                </CardHeader>
                <CardBody>
                  <h5 size="md" className=" text-blue text-center">
                    Point of Sale
                  </h5>
                </CardBody>
              </Card>
              <Card>
                <CardHeader className=" d-flex justify-content-center align-items-center">
                  <Image src="/img/gif/membership.png" alt="....." />
                </CardHeader>
                <CardBody>
                  <h5 size="md" className=" text-blue text-center">
                    Membership
                  </h5>
                </CardBody>
              </Card>
            </SimpleGrid>
          </div>
        </div>

        <div className="container">
          <div className=" col-md-12   mt-10 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Benefits Of Work With</span>
              </h2>
            </div>
            <h5
              data-aos="fade-up"
              data-aos-duration="500"
              className="After_title mb-4"
            >
              Salon Management Software
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Salon business management software will work in a manner that will
              work best for you. Some of the benefits you will get after using
              the best salon management software India are as follows
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid spacing={4} columns={{ base: 1, sm: 1, md: 1, lg: 1 }}>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/gif/saves-times.png"
                    alt="..."
                  />
                </div>
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-darkblue">
                      Saves Time
                    </h5>
                    <p py="2">
                      With the help of salon business management software, you
                      will save quality time. Salon management software manages
                      all the activities of the salon management at one desk
                      which results in saving your time.
                    </p>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Automatic appointment reminder
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" /> Marketing
                        automation
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Customer’s history and database
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-darkblue">
                      Easily Managed
                    </h5>
                    <p py="2">
                      Salon Salon business management software helps the owner
                      to manage things easily. Salon business management
                      software would here to manage all the necessary things in
                      one step with the best salon management software India.
                    </p>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Online Access
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" /> Employee
                        Management
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Stock Control
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/gif/easily-managed-salon.png"
                    alt="..."
                  />
                </div>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/gif/attract-customer.png"
                    alt="..."
                  />
                </div>
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-darkblue">
                      Attract More Customers
                    </h5>
                    <p py="2">
                      Salon business management software helps to attract
                      clients to your salon by which the salon will be able to
                      maintain a healthier connection with the customers in the
                      market. Join us, The best salon management software India.
                    </p>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Loyalty Programme
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" /> 24*7
                        available for online booking
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Text and Email Campaign
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-darkblue">
                      Measure Your Success
                    </h5>
                    <p py="2">
                      Measure your success with salon business management
                      software and get the awesome and highest growth in the
                      market.
                    </p>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Real-time cash audit
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" /> Financial
                        Reports & Sales Strategy Employee
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Effectiveness & Communication Report
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/gif/measure-your-sucess.png"
                    alt="..."
                  />
                </div>
              </Card>
            </SimpleGrid>
          </div>
        </div>
        <br />
        <Testimonials
          testimonialdata={testimonialdata}
          tHead={
            <span>
              Customer Review
              <br />
              Salon Satisfactions
            </span>
          }
          tText={
            "Some of our customers who support us for their business growth are:-"
          }
        />
        <div className="container">
          <div className=" col-md-12   mt-5 mb-5  text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> FAQ's About</span>
              </h2>
            </div>
            <h5
              className="After_title mb-2"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Salon Management System
            </h5>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <Accordion allowToggle>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is the best software for a salon?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      We offer the best software according to your need and
                      requirement. Our best software is totally dependent on
                      your requirement which you need for your business growth
                      in the market.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is salon management software?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Salon management software is an application that provides
                      everything which you need to enhance such as appointment
                      scheduling, customer management, sales, product, and much
                      more. Salon Management Software India gives an ALL-in-ONE
                      management process that goes beyond POS and appointment
                      booking.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How much does salon software cost?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      The pricing of salon management software will also be
                      totally dependent on your campaign. All of the related
                      roles require qualified specialists that we have. We
                      charge a reasonable amount for the work we do. What
                      software do sa
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What software do salons use?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      We use software for salons according to the client’s taste
                      and preference i.e. what type of software they need for
                      their salon business and we have worked on that to provide
                      growth to their business.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How can I make my salon successful?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      By choosing Management Software For Salons. We will work
                      in a manner that will work best for you which results in
                      increasing your salon’s name in the market effectively
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How do you manage a salon?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      We manage a salon by following these of the following
                      Terms
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Team management
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Customer relationship management
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" /> Improve
                          Social Media Marketing
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Security
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" /> Many more
                        </ListItem>
                      </List>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Which is the best salon management software?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Who works on customer needs and requirements and will be
                      able to grow your business on the right path, that would
                      be the only best salon management software and we have
                      worked on customer satisfaction and their business growth
                      passionately.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Can you make custom salon management software?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Obviously Yes! We can make custom salon management
                      software according to your campaign.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Which technology do you use to build custom salon
                          management software?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      The technologies we use to build custom salon management
                      software that results in improving business management
                      such as team management, CRM system, social media
                      marketing, and many more.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <RaiseaQuery />
      </div>
    </>
  );
};
export default SalonMS;
