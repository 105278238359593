import React from "react";
import { Container, Row, Col } from "reactstrap";
import PageTitle from "../../Common/PageTitle";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Accordion,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import f from "../../component/jsonfile/frontend.json";
import b from "../../component/jsonfile/backend.json";
import d from "../../component/jsonfile/database.json";
import RaiseaQuery from "../../component/RaiseaQuery";
import Customercare from "../../component/Customercare";
import CardOne from "./Cards/CardOne";
import Testimonials from "../../component/CardContain/Testimonials";
import testimonialdata from "../../component/Testimonials/App$mixedTestimonials.json";
import AppCard from "./Cards/AppCard";
import FaqItem from "../../component/faq/FaqItem";

const AppDevelopment = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          title="Mobile App Development Company"
          desc="Get started with the most customized & flexible mobile app development company. We utilize cutting edge technology to offer you a robust app development solution."
          bimg="/img/banner/app-development.png"
          page="App Development"
        />
        <Container className=" dot-pattern pb-lg-5">
          <Row>
            <Col md={12} className=" mt-5 mb-5 text-center">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span> Services we Offer </span>
                </h2>
              </div>
              <p className="lead" data-aos="fade-up" data-aos-duration="600">
                We offer the most prominent, Android and IOS app development
                services.
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={6} sm={12}>
              <div className="app_dev_content pt-4">
                <div className="andriod_head ">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span> Android app development</span>
                  </h2>
                  <hr />
                </div>
                <div className="para_txt_here">
                  <p>
                    Our android applications need to be as easy as possible. Our
                    engineers will make you sure that your Android app gives the
                    best experience to your users. We have a strong team of
                    backend developers to make sure your Android app's services
                    scale as fast as your business.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={6} sm={12} className="text-center">
              <div className="app_dev_img">
                <img src="/assets/app/Android.png" alt="" />
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={6} sm={12}>
              <div className="app_dev_img">
                <img src="/assets/app/ios.png" alt="" />
              </div>
            </Col>
            <Col md={6} sm={12}>
              <div className="app_dev_content pt-4">
                <div className="andriod_head mt-3 pt-4">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span> IOS app development</span>
                  </h2>
                  <hr />
                </div>
                <div className="para_txt_here">
                  <p>
                    Our android applications need to be as easy as possible. Our
                    engineers will make you sure that your Android app gives the
                    best experience to your users. We have a strong team of
                    backend developers to make sure your Android app's services
                    scale as fast as your business.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className=" softwareDevpl_bgImg  rounded-lg  mb-5">
          <div className="onsoftwareDevpl_BGlayer add_newcard">
            <Container className=" mt-5 pt-5 pb-5 mb-5">
              <Row>
                <CardOne
                  delay="300"
                  anim={f}
                  title="Front End"
                  txt=" It includes everything that users experience directly. Text, colors, styles, images, graphs, tables, buttons, colors, navigation menu, HTML, CSS, and JavaScript.
                         "
                  listone="HTML , CSS , JS , React "
                  listtwo="JQuery , Angular , Swift "
                />
                <CardOne
                  delay="400"
                  anim={b}
                  title="Backend"
                  txt="In mobile app development, the backend of an
                          application contains behind the scene activities that
                          occur when performing any actions on application."
                  listone=".Net , Java , Php , Node"
                  listtwo="Ruby On Rails , Python"
                />
                <CardOne
                  delay="600"
                  anim={d}
                  title="Database"
                  txt="Databases are structured in mobile app development to facilitate the storage, modification and deletion of data along with various data processing."
                  listone="Solite , Realm , Core data"
                  listtwo="Firebase , MySQL , MongoDB"
                />
              </Row>
            </Container>
          </div>
        </div>
        <Customercare />
        <section
          className="dot-pattern pt-5"
          style={{ marginTop: "-40px", background: "#e3f1f2" }}
        >
          <Container className="pb-4">
            <Col md={12} className="mb-5 text-center">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span> Features That Every Business Admires</span>
                </h2>
              </div>
              <p className="lead" data-aos="fade-up" data-aos-duration="600">
                We carry out the mobile Features That Every Business Admires
                application development strategy which is focused on alignment,
                designing, development and overall goals of the application.
                <br />
              </p>
            </Col>
            <Col md={12} className="mt-5 pb-5 ">
              <SimpleGrid
                spacing={4}
                className="addcss_onPara"
                templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
              >
                <AppCard
                  delay="100"
                  imgsrc="/assets/SDIcon/App strategy.png"
                  title=" App Strategy"
                  txt=" We carry out the Mobile application development strategy
                        visioning workshop which is focused on bringing alignment
                        between key sketch holders and overall goals of the
                        application, business needs and choices of technology."
                />
                <AppCard
                  delay="200"
                  imgsrc="/assets/SDIcon/Designing of Apps.png"
                  title="  Designing Of Apps"
                  txt="  Designing of Apps Designing combines the user interface and
                      user experience. It focuses on the actual functionality and
                      utility by which we will be able to create highly engaging,
                      easy to use and compatible clients."
                />
                <AppCard
                  delay="300"
                  imgsrc="/assets/SDIcon/Unique app development.png"
                  title="  Unique App Development"
                  txt="   Our company has a team of experienced android app developers
                      who understands your taste, preferences, motive and then
                      creates the mobile application accordingly. That will be
                      unique and secure in its segment."
                />
              </SimpleGrid>
            </Col>
          </Container>
        </section>
        <section>
          <div className=" softwareDevpl_bgImg rounded-lg ">
            <div className=" onsoftwareDevpl_BGlayer ">
              <div className="container ">
                <div className="col-md-12   mt-5 mb-5 ">
                  <Card className="bg-transparent add_shadow">
                    <CardHeader>
                      <div className=" text-center">
                        <h2
                          className="text-color mt-2"
                          data-aos="fade-up"
                          data-aos-duration="500"
                        >
                          <span>
                            {" "}
                            Why Choose Our Mobile App Development Company?
                          </span>
                        </h2>
                      </div>
                    </CardHeader>
                    <CardBody className="text-light ">
                      <List spacing={3}>
                        <ListItem mb={3}>
                          We have created many applications for reputed clients
                          worldwide. We follow the best practices in the
                          industry to give our users the best exprience
                          possible.
                        </ListItem>
                        <ListItem color="#fff">
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          We provide award winning mobile application
                          development solutions.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          Budget friendly mobile app development.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          Profitability from the application.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          Post project support.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          Competitive rates for mobile application design and
                          development talent.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          Solid open technology expertise across multiple
                          platforms.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          We thoroughly research and implement our strategy to
                          deliver our projects successfully.
                        </ListItem>
                      </List>
                    </CardBody>
                    <CardFooter
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Link to="/contact" className="all_btn mb-5">
                        <span> Get Started</span>
                      </Link>
                    </CardFooter>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="pt-5 pb-5 dot-pattern"
          style={{ background: "#e9f1f5" }}
        >
          <Container>
            <Row>
              <Col md={12} className=" text-center">
                <div className="top_title text-center">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span> We Just Don’t Create Applications</span>
                  </h2>
                </div>
                <p className="lead " data-aos="fade-up" data-aos-duration="700">
                  We Give End-To-End Service. <br />
                  We have made industry leading softwares with cutting edge
                  technology to help you maximize your <br /> bisiness goals
                  with seamless performance.
                </p>
              </Col>
              <Col md={12} className="pb-3 mt-3 ">
                <SimpleGrid
                  spacing={4}
                  className="addcss_onPara"
                  templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
                >
                  <AppCard
                    delay="100"
                    imgsrc="/assets/icon/Security.png"
                    title="Security"
                    txt="  Our iOS and android development solutions are protected
                        under a secure environment. We use the latest technology to
                        secure your data."
                  />
                  <AppCard
                    delay="200"
                    imgsrc="/assets/icon/Support.png"
                    title="Support"
                    txt=" We offer round the clock technical support for hassle-free
                        working with your teammates rather than worrying about the
                        technicalities."
                  />

                  <AppCard
                    delay="300"
                    imgsrc="/assets/icon/Deployment.png"
                    title="Deployment"
                    txt=" Post development, we provide the solution onto your hosting
                        server and manage all dependencies to ensure smooth user
                        experience."
                  />
                </SimpleGrid>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="mb-4">
          <Container>
            <Col md={12} className=" mt-5 mb-2 text-center">
              <div className="top_title ">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span> FAQ's About</span>
                </h2>
              </div>
              <h5
                className="After_title "
                data-aos="fade-up"
                data-aos-duration="600"
              >
                App Development Company
              </h5>
            </Col>
            <Col md={12} className=" mb-5 ">
              <Accordion allowToggle>
                <FaqItem
                  query=" What is app development ?"
                  answer="App development is the process of creating a computer
                        programme or a set of programmes to perform the different
                        tasks that a business requires. From calculating monthly
                        expenses to scheduling sales reports, applications help
                        businesses machanical processes and increase efficiency.
                        Every app-building process follows the same steps:
                        gathering requirements, designing prototypes, testing,
                        implementation, and integration. Application development
                        is the name of the profession that our application
                        development company’s employees will design, develop, and
                        deploy these applications."
                />
                <FaqItem
                  query="  How do you plan before building a mobile application ?"
                  answer=<span>
                    <p>
                      {" "}
                      A mobile app with excellent user experience can help your
                      business gain brand exposure, improve operational
                      effectiveness, create a new sales channel, and a direct
                      line to your users.
                    </p>
                    <strong>
                      Things to consider when planning a mobile application
                    </strong>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Create an app flow chart.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Define your concept.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Understand your user.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Conduct market research Establish goals for the
                        application.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Determine your mobile app’s business value.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Create app wireframes. Confirm technical feasibility.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Plan to analyze.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Plan for resource management.
                      </ListItem>
                    </List>
                  </span>
                />
                <FaqItem
                  query=" Do you give AMC of the mobile application ?"
                  answer=<span>
                    We had clients asking for an all-in-one maintenance solution
                    and so we came up with a 360-degree maintenance plan which
                    encloses your website, mobile app and other services.
                    <br /> AMC of a mobile application is a flexible strategy or
                    a process to maintain the quality of an application by which
                    the organization is continuously improving and getting the
                    best user experience. <br />
                    OneBigBit is the leading mobile application development
                    company that provides affordable AMC for mobile apps.
                    <br /> With the most suitable and productive AMC mobile app,
                    clients get the smooth layer of mobile app experts in a
                    nominal package.
                    <br /> In order to give you the hassle-free experience we
                    came up with the concept of AMC also known as annual
                    maintenance charge.
                    <br /> Contact us if you want AMC service from us.
                  </span>
                />
                <FaqItem
                  query="How much does mobile app development cost ?"
                  answer=" WhenAll of the related roles require qualified specialists
                        that we have. We charge a reasonable amount for the work
                        we do. Moreover, your project might require more than one
                        developer. Therefore, work hours constitute a big part of
                        your mobile app cost. The cost of mobile application
                        development will completely depend on the project. In
                        reality, each app development process is unique according
                        to the project, if your project is more complex then it
                        will demand bespoke coding and third-party APIs, bringing
                        the cost up again."
                />
                <FaqItem
                  query="Which framework is best for mobile app development ?"
                  answer=<span>
                    <h4> IOS Framework includes </h4>
                    <p>
                      <strong> React Native:- </strong>React Native, built and
                      supported by Facebook. It is an approachable,
                      cross-platform application development framework that has
                      quickly become the preferred option of programmers. React
                      Native facilitates the development of Android and iOS
                      mobile applications.
                    </p>
                    <p>
                      <strong>Flutter :- </strong>Flutter is an open and free
                      framework from Google that allows you to create native
                      Android and iOS applications with a simple codebase. It’s
                      a ground-breaking SDK for cross-platform application
                      development, it is different because it takes a novel way
                      of creating native-like apps.
                    </p>
                    <p>
                      <strong> Swiftic :-</strong> Swiftic is a do-it-yourself
                      mobile app platform that seeks to make it easy for anybody
                      to build unique applications for their company. It
                      simplifies app development by allowing programmers to
                      integrate current material from the internet rather than
                      starting fresh.
                    </p>
                    <h4>Android framework includes</h4>
                    <p>
                      <strong> Ionic:-</strong> Ionic is an MIT-certified, free,
                      and open-source app development framework that uses a
                      combination of HTML5 + CSS3 + JavaScript for
                      cross-platform application development. It allows
                      developing interactive hybrid apps and PWAs (Progressive
                      Web Applications.)
                    </p>
                    <p>
                      <strong> React Native:- </strong>React Native, built and
                      supported by Facebook, is an approachable , cross-platform
                      application development framework that has quickly become
                      the preferred option of programmers. React Native
                      facilitates the development of Android and iOS mobile
                      applications.
                    </p>
                    <p>
                      <strong>Flutter:- </strong>Flutter is an open and free
                      framework from Google that allows you to create native
                      Android and iOS applications with a simple codebase. It’s
                      a ground-breaking SDK for cross-platform application
                      development, which differentiates it because it takes a
                      novel way of creating native-like apps.
                    </p>
                  </span>
                />
                <FaqItem
                  query=" How to choose the best mobile app development company?"
                  answer=<span>
                    Every year huge investments are made just for getting
                    enterprise mobile apps so that it could prove to be a game
                    changer for their respective owners. An effective mobile app
                    plays a key role in any company’s business success and thus
                    it is important for owners to choose for custom mobile app
                    development company services.
                    <strong>
                      Some of the key factors that can help in identifying the
                      best mobile app development company are:-
                    </strong>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Choose the Mobile App Development Companies Based on
                        Customers’ Reviews and Feedback.
                      </ListItem>

                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Security of the app idea.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Effective delivery management.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        App designing standards.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Trusted Q/A and Testing Measures.
                      </ListItem>
                    </List>
                    Choosing the services from the right mobile app development
                    companies is a big investment and that is why it is
                    important to invest the money in the right direction.
                  </span>
                />
                <FaqItem
                  query=" What can a mobile app development company do for you ?"
                  answer=<span>
                    <p>
                      If you’re thinking of getting a mobile app for your
                      business, then without any doubts, reach out with us,
                      which is a mobile app development company that promises
                      quality products, has reliable and proven past
                      experience,and understands your requirements well.
                    </p>
                    <p>
                      Following are some of the most immidiate points that show
                      what a mobile app development company does for you.
                    </p>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Better for startups and big projects.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Budget- Friendly.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Availability of technical expertise and resources.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Tuned workflow.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Round the clock support team.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Companies care about their products.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Long term relationship.
                      </ListItem>
                    </List>
                  </span>
                />
                <FaqItem
                  query="Which is the best app development company in Lucknow ?"
                  answer=<span>
                    <p>
                      OneBigBit is a mobile app development company in Lucknow
                      who believes in the development of high end mobile
                      applications with a natural user interface to attract the
                      user and take the customer’s business one step ahead of
                      the competitors.
                    </p>
                    <p>
                      We have a huge number of years of experience in providing
                      personalized mobile application development the
                      competitors.
                    </p>
                    <p>
                      We have a huge number of years of services. We achieved
                      heights with the support of dedicated professionals aimed
                      at providing the best the competitors.
                    </p>
                    <p>
                      We have a huge number of years of mobile app development
                      solutions in our class. Our main motive is to design,
                      develop, and produce solutions that are customized the
                      competitors.
                    </p>
                    <p>
                      We have a huge number of years of according to the
                      client’s business requirements. We are one of the best
                      mobile app development company in Lucknow, our goal is to
                      provide advanced mobile applications that are developed
                      with the latest technologies to provide robust and
                      scalable mobile applications.
                    </p>
                  </span>
                />
                <FaqItem
                  query="Which is the best app development company in India ?"
                  answer=<span>
                    Mobile app development is growing increasingly and popular
                    around the world and is in high demand across businesses.
                    <p>
                      The world has been divided into two groups for the past
                      ten years: Android and iOS.
                    </p>
                    <p>
                      India's tech industry developed a lot in 2021. Due to the
                      pandemic, mobile apps with the latest technology are
                      demanded in many sectors, including professional services,
                      education, medical, financial, healthcare, logistics, and
                      more.
                    </p>
                    <p>
                      The app development companies in India play a vital role
                      in the latest technology.
                    </p>
                    <p>
                      <strong>OneBigBit </strong>has been providing the best
                      mobile app development & web development services.
                    </p>
                    <p>
                      We have a team of developers who are highly skilled and
                      always ready to develop the most appropriate complex
                      solutions.
                    </p>
                  </span>
                />
              </Accordion>
            </Col>
          </Container>
        </section>
        <Testimonials
          testimonialdata={testimonialdata}
          tHead={"Customer Review"}
          tText={"See What Our Customers are Saying"}
        />
        <RaiseaQuery />
      </div>
    </>
  );
};
export default AppDevelopment;
