import React from "react";
import PageTitle from "../../Common/PageTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { Link } from "react-router-dom";
import {
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Image,
  Accordion,
  List,
  ListItem,
  Stack,
  ListIcon,
  Card,
} from "@chakra-ui/react";
import industries from "./Industries.json";
import { CheckCircleIcon } from "@chakra-ui/icons";
import RaiseaQuery from "../../component/RaiseaQuery";
import Testimonials from "../../component/CardContain/Testimonials";
import testimonialdata from "../../component/Testimonials/DigitalTestimonial.json";
import ReasonsToChoose from "./Cards/ReasonsToChoose";
import TextTab from "./Cards/TextTab";
import FaqItem from "../../component/faq/FaqItem";

const DigitalMarketing = () => {
  const slider_img = [
    "/img/ahrefs.jpg",
    "/img/google-ads.jpg",
    "/img/google-analystics.jpg",
    "/img/google-adSense.jpg",
    "/img/google-search-console.jpg",
    "/img/grannarkly.jpg",
    "/img/semrush.jpg",
  ];
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          title="Digital Marketing Agency in India"
          desc="A strong business needs the best digital marketing agency 
        that provides tools and perks to explore your business and sales to back it up with the 21st century."
          bimg="/img/banner/digital-marketing.png"
          page="Digital Marketing "
        />
        <div className="container ">
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>Digital Marketing Services We Offer To Every User</span>
              </h2>
            </div>
            <p className=" fw-300" data-aos="fade-up" data-aos-duration="600">
              We provide the best Digital marketing services in India and focus
              on the entire goal for your business. Some of our services for{" "}
              <br />
              digital marketing are as follows:-
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 addcss_onPara digiTal_cards">
            <SimpleGrid spacing={4} columns={{ base: 1, sm: 1, md: 1, lg: 1 }}>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/assets/home/seo.png"
                    alt="..."
                  />
                </div>
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-maincolor mt-3">
                      {" "}
                      Search Engine Optimization{" "}
                      <span className="text-blue"> (SEO)</span>
                    </h5>
                    <p py="2">
                      In Digital Marketing Agency, SEO is an ongoing commitment
                      for success. SEO makes it possible to get in front of
                      their target audiences for products and services. Our SEO
                      experts help in increasing your ROI(return on investment),
                      staying ahead of the competition, also working on business
                      thinking and as a business leading, SEO also monitors
                      analytics. your website.
                    </p>
                    <h5 size="md" className="text-darkblue">
                      Search Engine Marketing
                      <span className="text-blue"> (SEM)</span>
                    </h5>
                    <p py="2">
                      To get a website on top search engine ranking, we use
                      Search Engine Marketing.
                    </p>
                    <p>
                      We have combined strategy plans like AdWords, Bing Ads,
                      PPC, affiliate marketing, and more to get immediate
                      traffic for your website.
                    </p>
                  </CardBody>
                </Stack>
              </Card>

              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-maincolor">
                      Social Media Marketing
                      <span className="text-blue"> (SMM)</span>
                    </h5>
                    <p py="2">
                      To make your business available on a digital platform,
                      Social Media Marketing is one of the most effective and
                      efficient ways we all know.
                    </p>
                    <p>
                      As a top Digital Marketing Agency, we generated a
                      successful marketing procedure to implement the power of
                      various social media channels to explore your audience.
                    </p>
                    <p>
                      To build custom social media brand management, we use data
                      and analytics in our Digital Marketing Agency. Social
                      Media Optimization (SMO) Today Digital Marketing is
                      flooded with a number of websites just to differentiate
                      yourself from the cloud if you need to have something
                      special. Social Media Optimization will help in
                      influencing the customer’s opinion about your business and
                      brand. We create brand awareness amongst a wider audience
                      and social networks.
                    </p>
                    <h5 size="md" className="text-darkblue">
                      Social Media Optimization
                      <span className="text-blue"> (SMO)</span>
                    </h5>
                    <p py="2">
                      Today Digital Marketing is flooded with a number of
                      websites just to differentiate yourself from the cloud if
                      you need to have something special.
                    </p>
                    <p>
                      Social Media Optimization will help in influencing the
                      customer’s opinion about your business and brand.
                    </p>
                    <p>
                      We create brand awareness amongst a wider audience and
                      social networks.
                    </p>
                  </CardBody>
                </Stack>
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/assets/home/smm.png"
                    alt="..."
                  />
                </div>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/assets/home/ccm.png"
                    alt="..."
                  />
                </div>
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-maincolor">
                      Content Creation / Marketing
                      <span className="text-blue"> (CCM)</span>
                    </h5>
                    <p py="2">
                      Suitable, applicable, and valuable content is a lifeline
                      in the Digital Marketing business. Content is the present
                      and future of Digital Marketing.
                    </p>
                    <p>
                      Our Content Marketing helping hand focused to attract and
                      retain a clearly defined audience Our Content Marketing
                      Experts will make sure that our customers should be
                      informed, entertained, and should be engaged at every
                      stage of their purchasing cycle.
                    </p>
                    <h5 size="md" className="text-darkblue">
                      Website Optimizations
                    </h5>
                    <p py="2">
                      Website optimization contains some tools, procedures, or
                      experience which ultimately help your business in
                      achieving its goal.
                    </p>
                    <p>
                      A business optimizes its website to capture more potential
                      leads for coverage leads.
                    </p>
                  </CardBody>
                </Stack>
              </Card>
            </SimpleGrid>
          </div>
        </div>
        <section className="bg-light  mt-5 pb-5 dot-pattern">
          <div className="container">
            <div className="row">
              <div className=" col-md-12  mt-5 text-center ">
                <div className="top_title text-center">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span> We Focus These Industries</span>
                  </h2>
                </div>
                <p
                  className="lead fw-300"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  We provide the best Digital marketing services in India and
                  focus on the entire goal for your business. <br /> Some of our
                  services for digital marketing are as follows:-
                </p>
              </div>
              <div className="col-md-12   mt-3 ">
                <SimpleGrid spacing={4} columns={{ base: 1, sm: 2, md: 5 }}>
                  {industries &&
                    industries.map((data, index) => (
                      <Card
                        key={index}
                        className="DigitalMarketing_services "
                        data-aos="flip-right"
                        data-aos-duration="600"
                      >
                        <CardHeader className=" d-flex justify-content-center align-items-center">
                          <Image
                            src={data.image}
                            alt="....."
                            maxWidth="300px"
                            maxHeight="500px"
                          />
                        </CardHeader>
                        <CardBody>
                          <h5 size="md" className="text-dark">
                            {data.title}
                          </h5>
                        </CardBody>
                      </Card>
                    ))}
                </SimpleGrid>
              </div>
            </div>
          </div>
        </section>
        <section style={{ background: "#e3f1f2" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mt-5 mb-5 ">
                <Swiper
                  //  slidesPerView={5}
                  loop={true}
                  grabCursor={true}
                  spaceBetween={30}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                  breakpoints={{
                    600: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 2,
                    },

                    992: {
                      slidesPerView: 3,
                    },

                    1200: {
                      slidesPerView: 5,
                    },
                  }}
                >
                  {slider_img.map((image, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <img src={image} alt="" />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <div className="container ">
          <div className="row">
            <div className=" col-md-12   mt-5 text-center dot-pattern">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span> Reasons To Choose</span>
                </h2>
              </div>
              <p
                className="lead  fw-300"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                OneBigBit For Your Digital Marketing Solutions <br />
                We have some adorable tools and techniques for choosing us for
                your digital marketing agency. These are as follows
              </p>
            </div>

            <div className=" col-md-12 mb-5 mt-4 d-flex justify-content-center align-items-center">
              <SimpleGrid spacing={4} columns={{ base: 1, md: 2, lg: 4 }}>
                <ReasonsToChoose
                  img="/img/digital_mar/Acquire more leads & Sales.png"
                  title=" Acquire more leads & Sales"
                  txt="
                        We will help in acquiring leads as much as possible. We will
                        help in generating quality sales and leads that will help in
                        becoming more likely for your customers."
                />
                <ReasonsToChoose
                  img="/img/digital_mar/Improve an ineffective website.png"
                  title=" Improve an ineffective website"
                  txt="
                        Our other reason for choosing us for your digital marketing
                    solutions is that we will help in improving an ineffective
                    website with our digital techniques which convert your
                    website into a lead creation."
                />
                <ReasonsToChoose
                  img="/img/digital_mar/Build brand awareness.png"
                  title=" Build brand awareness"
                  txt="
                         Our digital marketing experts help in building brand value
                    and create a foster trust with potential customers.
                    Maintaining relationships with customers, healthy
                    conversation and a proper strategy."
                />
                <ReasonsToChoose
                  img="/img/digital_mar/We know Digital Marketing.png"
                  title="We know digital marketing"
                  txt="
                        We know Digital Marketing Our strategies are proven to work.
                    In fact, most of our clients pay for their complete website
                    in the first month from new business leads as a direct
                    result of our online marketing and SEO efforts."
                />
              </SimpleGrid>
            </div>
          </div>
        </div>
        <div className=" softwareDevpl_bgImg rounded-lg mt-5">
          <div className=" onsoftwareDevpl_BGlayer ">
            <div className="container ">
              <div className="row">
                {" "}
                <div className="col-md-12   mt-5 mb-5 ">
                  <Card className="bg-transparent">
                    <CardHeader>
                      <div className="text-center">
                        <h2
                          className="text-color"
                          data-aos="fade-up"
                          data-aos-duration="500"
                        >
                          <span>
                            {" "}
                            Reasons Behind Choosing A Digital Marketing Agency{" "}
                          </span>
                        </h2>
                      </div>
                      <p
                        className=" text-light fw-300 mt-4"
                        data-aos="fade-up"
                        data-aos-duration="600"
                      >
                        We always have a choice when it comes to choosing a
                        partnership with a digital marketing agency.
                        Approximately 8.5 of 10 big businessmen will choose an
                        agency partner.
                      </p>
                      <hr />
                      <h5 className="text-color">
                        {" "}
                        Provided Digital Marketing Services to 900+ Businesses
                        Worldwide
                      </h5>
                      <p
                        className=" text-light fw-300"
                        data-aos="fade-up"
                        data-aos-duration="600"
                      >
                        We provide the best Digital Marketing Solutions in India
                        according to the problems of the user. We understand the
                        organization and customer's preferences then make a
                        procedure that helps us in implementing extraordinary
                        solutions.
                      </p>
                    </CardHeader>
                    <CardBody className="text-light">
                      <List spacing={3}>
                        <ListItem data-aos="fade-up" data-aos-duration="200">
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          User-centric approach
                        </ListItem>
                        <ListItem data-aos="fade-up" data-aos-duration="400">
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          Business thinking
                        </ListItem>
                        <ListItem data-aos="fade-up" data-aos-duration="600">
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          transparency
                        </ListItem>
                        <ListItem data-aos="fade-up" data-aos-duration="800">
                          <ListIcon as={CheckCircleIcon} color="#03b5be" />
                          Interdisciplinary experience
                        </ListItem>
                      </List>
                    </CardBody>
                    <CardFooter
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Link to="/contact" className="all_btn mb-3">
                        <span> Get Started</span>
                      </Link>
                    </CardFooter>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="mt-5 mb-5">
          <div className="container mt-5 mb-5">
            <div className="row">
              <div className=" col-md-12 pt-4 text-center">
                <div className="top_title">
                  <h2>
                    <span> Our Process For Your Growth</span>
                  </h2>
                </div>
                <p className="lead fw-300 mb-5">
                  Choosing A Digital Marketing Agency <br />
                  We have some of our steps that will be used by a digital
                  marketing agency for the growth of the business. Steps
                  followed by the digital <br /> marketing agency are as follow
                </p>
              </div>
              <div className=" col-md-12 pb-5">
                <div className="row">
                  <div className="col-md-1"></div>
                  <TextTab txt="Research" />
                  <TextTab txt="Plan" />
                  <TextTab txt="Implement" />
                  <TextTab txt="Measure" />
                  <TextTab txt="Optimize" />
                  <div className="col-md-1"></div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Testimonials
          testimonialdata={testimonialdata}
          tHead={
            <span>
              Our Testimonials <br />
              Why WebMasters Admire Our Digital Marketing Services
            </span>
          }
          tText={
            "Here are some of our client’s who support us and admire our digital marketing services for their business growth"
          }
        />
        <section>
          <div className="container ">
            <div className=" col-md-12 mt-5  text-center">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span> FAQ's About</span>
                </h2>
              </div>
              <h5 className="After_title ">Digital Marketing?</h5>
            </div>
            <div className="col-md-12   mt-3">
              <Accordion allowToggle>
                <FaqItem
                  query=" What is Digital Marketing?"
                  answer=<span>
                    <p>
                      It is the process of selling goods and services with the
                      help of mobile devices, social media, SEO, the internet,
                      and other digital communications to reach our targeted
                      audience.
                    </p>{" "}
                    <p>
                      {" "}
                      It is an online marketing site used for the promotion of
                      brands and services{" "}
                    </p>
                  </span>
                />
                <FaqItem
                  query=" Why are digital marketing services useful for
                            business?"
                  answer="The most common reason for using digital marketing
                        services is it helps in reaching our targeted audience
                        through digital channels. It is in our budget, flexible
                        in nature, and affordable. It has the ability to track
                        customers’ buying journeys."
                />
                <FaqItem
                  query=" What are the different roles within a digital
                            marketing agency?"
                  answer=<span>
                    {" "}
                    Some of the main and important roles within digital
                    marketing agency are as follows
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Social Media Experts
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Analytics Experts
                      </ListItem>{" "}
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Email Marketing
                      </ListItem>{" "}
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Specialist Customer Experience/User Experience(UX)
                        Designer
                      </ListItem>{" "}
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Search Engine Optimization(SEO),
                      </ListItem>{" "}
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Search Engine Marketing Expert(SEM)
                      </ListItem>{" "}
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Expert Pay-Per-Click Specialist
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Content Strategist And many more
                      </ListItem>
                    </List>
                  </span>
                />
                <FaqItem
                  query="In how many days or months can I expect a good
                            amount of traffic on my site?"
                  answer=" Well a good amount of traffic on your website will
                        usually take some days, Digital Marketing is quite
                        difficult as we all know. Getting a good amount of
                        traffic on your website will contain a proper
                        optimization for your products and services which takes
                        some appropriate time."
                />
                <FaqItem
                  query=" What are the solutions a digital marketing agency
                            provides?"
                  answer=<span>
                    {" "}
                    Some solutions provided by a digital marketing agency are as
                    follows
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Website development
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Search engine optimization
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Google ads Branding
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Social media engagement
                      </ListItem>
                    </List>
                  </span>
                />

                <FaqItem
                  query=" How can I choose a good digital marketing agency?
"
                  answer=<span>
                    There are some factors for choosing a good digital marketing
                    agency and these are as follows:- Identifying customers’
                    needs and budget.?
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Knowing the customer's objective and motive.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />{" "}
                        Specialization in delivering multiple services according
                        to the customer’s needs.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        Classifying the industrial experience and technical
                        ability of the service provider.
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckCircleIcon} color="#056894" />
                        And many more.
                      </ListItem>
                    </List>
                  </span>
                />
                <FaqItem
                  query=" Is it worth hiring a digital marketing agency?"
                  answer="  Hiring a Digital marketing agency is obviously worth it
                        because it can help you acquire a balanced perspective
                        on things and it is gradually more cost-effective and
                        highly productive for investment."
                />
                <FaqItem
                  query="Do you offer SEO services?"
                  answer="Yes, we offer SEO services In Digital Marketing Agency,
                        SEO is an ongoing commitment for success. SEO makes it
                        possible to get in front of their target audiences for
                        products and services. Our SEO experts help in
                        increasing your ROI(return on investment), staying ahead
                        of the competition, also working on business thinking
                        and as a business leading, SEO also monitors analytics.
                      "
                />
              </Accordion>
            </div>
          </div>
        </section>
        <RaiseaQuery />
      </div>
    </>
  );
};
export default DigitalMarketing;
