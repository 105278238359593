import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Page/Home";
import About from "../Page/About";
import Approach from "../Page/Approach";
import Products from "../Page/Products";
import Contact from "../Page/Contact";
import SoftwareDevelopment from "../Page/Service/SoftwareDevelopment";
import AppDevelopment from "../Page/Service/AppDevelopment";
import MixedReality from "../Page/Service/MixedReality";
import DigitalMarketing from "../Page/Service/DigitalMarketing";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import ClinicMS from "../Page/Product/ClinicMS";
import SalonMS from "../Page/Product/SalonMS";
import ColdSS from "../Page/Product/ColdSS";
import ECommerseS from "../Page/Product/ECommerseS";
import LearningMS from "../Page/Product/LearningMS";
import CustomerRM from "../Page/Product/CustomerRM";
import GameParlorMS from "../Page/Product/GameParlorMS";
import EnterpriseRP from "../Page/Product/EnterpriseRP";
import BakerySM from "../Page/Product/BakerySM";
import FantasyMAD from "../Page/Product/FantasyMAD";
import AdmissionMS from "../Page/Product/AdmissionMS";
import EsportML from "../Page/Product/EsportML";
import Career from "../Page/Career";
import Four04page from "../Page/Four04page";

const AllRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/approach" element={<Approach />} />
          <Route path="/products" element={<Products />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/career" element={<Career />} />

          <Route
            path="/service/softwareDevelopment"
            element={<SoftwareDevelopment />}
          />
          <Route path="/service/appDevelopment" element={<AppDevelopment />} />
          <Route path="/service/mixedReality" element={<MixedReality />} />
          <Route
            path="/service/digitalMarketing"
            element={<DigitalMarketing />}
          />
          <Route path="/products/clinicMS" element={<ClinicMS />} />
          <Route path="/products/salonMS" element={<SalonMS />} />
          <Route path="/products/coldSS" element={<ColdSS />} />
          <Route path="/products/ecommerseS" element={<ECommerseS />} />
          <Route path="/products/learningMS" element={<LearningMS />} />
          <Route path="/products/customerRM" element={<CustomerRM />} />
          <Route path="/products/gameParlorMS" element={<GameParlorMS />} />
          <Route path="/products/enterpriseRP" element={<EnterpriseRP />} />
          <Route path="/products/bakerySM" element={<BakerySM />} />
          <Route path="/products/fantasyMAD" element={<FantasyMAD />} />
          <Route path="/products/esport" element={<EsportML />} />
          <Route path="/products/admissionMS" element={<AdmissionMS />} />
          <Route path="/*" element={<Four04page/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
};

export default AllRoutes;
