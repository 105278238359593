import { Link } from "react-router-dom";
import { Card, CardHeader, CardBody, CardFooter, Image, } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

const ServiceCard = (props) => {
    return (
        <>
            <Card  data-aos="fade-up" data-aos-duration={props.delay} borderTop="8px" borderColor="#056894" className="d-flex justify-content-center align-items-center" >
                <CardHeader>
                    <Image src={props.img} alt="service img"  className="img-fluid" />
                </CardHeader>
                <CardBody>
                    <h5 size="md" className="text-blue">  {props.title}</h5>
                    <p>  {props.txt} </p>
                </CardBody>
                <CardFooter>
                    <Link to={props.link}> Click Here <ArrowForwardIcon />  </Link>
                </CardFooter>
            </Card>
        </>
    )
}

export default ServiceCard
