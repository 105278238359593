import React from "react";
import PageTitle from "../../Common/PageTitle";
import {
  SimpleGrid,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  Box,
} from "@chakra-ui/react";
import {
  CheckIcon,
  MinusIcon,
  AddIcon,
} from "@chakra-ui/icons";
import feature from "./Feature/customerfeature.json";
import RaiseaQuery from "../../component/RaiseaQuery";
import Featuredata from "../../component/Featuredata";
import Lastpoint from "../../component/Lastpoint";

const CustomerRM = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="Customer Relationship Management"
          desc="A Customer Relationship Management (CRM) project refers to the planning, development, implementation, and management of systems and processes that help organizations build and maintain strong relationships with their customers."
          bimg="/img/banner/crm2.jpg"
          page="Customer Relationship Management"
        />
        <div className="container ">
          <div className=" col-md-12   mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Do You Need</span>
              </h2>
            </div>
            <h5
              className="After_title mb-2"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              CRM Project
            </h5>

            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              A CRM (Customer Relationship Management) project is a specific
              undertaking within an organization aimed at implementing CRM
              software or processes to manage and enhance interactions with
              customers and improve overall customer relationships. CRM projects
              are typically focused on achieving specific goals related to
              customer satisfaction, sales growth, marketing effectiveness, and
              customer service improvement. Here are key elements of a CRM
              project.
            </p>
          </div>
          <div className=" col-md-12    mb-5">
            <div className="row mt-n1-9 ">
              <div className="col-lg-7 wow fadeInUp mt-1-9  ">
                <div className="pe-2  mt-5">
                  <p className="lead mt-4 fw-300"></p>
                  <List spacing={3}>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      <strong> Project Initiation:</strong> The project starts
                      with recognizing the need for a CRM system, often prompted
                      by challenges in managing customer data, communication, or
                      sales processes. Clearly define the project's goals and
                      objectives, which may include improving customer
                      retention, increasing sales, or enhancing customer
                      support.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      <strong> Planning:</strong> Determine the scope of the CRM
                      project, including the features and functionalities
                      required. Allocate resources, both financial and human,
                      for the project.Develop a project timeline with milestones
                      and deadlines.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      <strong> Data Gathering and Analysis:</strong> Analyze the
                      organization's existing customer data to understand its
                      quality, completeness, and usability.Clean and organize
                      data to ensure its accuracy and reliability for use in the
                      CRM system.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      <strong>CRM Software Selection and Customization:</strong>
                      Choose the CRM software that best aligns with the
                      organization's needs, goals, and budget. Tailor the chosen
                      CRM software to fit the specific business processes and
                      requirements.
                    </ListItem>
                    <ListItem>
                      <ListIcon as={CheckIcon} color="#056894" />
                      <strong> Implementation:</strong> Transfer existing
                      customer data into the CRM system.Integrate the CRM system
                      with other relevant systems (e.g., ERP, email, marketing
                      automation).
                    </ListItem>
                  </List>
                </div>
              </div>
              <div className="col-lg-5 mt-1-9   mt-5">
                <img
                  src="https://www.wordstream.com/wp-content/uploads/2021/05/customer-relationship-management-CRM-for-small-business_0.png"
                  className=" mb-4"
                  alt="..."
                  data-aos="fade-up"
                  data-aos-duration="600"
                />
              </div>
            </div>
          </div>
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Features For</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Customer Relationship Management
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              A CRM (Customer Relationship Management) project typically
              involves implementing CRM software or developing CRM processes to
              improve customer interactions and relationships. The specific
              features you need in a CRM project can vary depending on your
              organization's size, industry, and unique requirements.
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={4}
              //    templateColumns="repeat(auto-fill, minmax(255px, 1fr))"
              columns={{ base: 1, sm: 1, md: 2, lg: 4 }}
            >
              <Featuredata feature={feature} />
            </SimpleGrid>
          </div>
          <div className=" col-md-12   mt-5 mb-5 ">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> FAQ's For</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Customer Relationship Management Project
            </h5>

            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Certainly, here are some frequently asked questions (FAQs) related
              to CRM project:
              <br />
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <Accordion allowToggle>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is a CRM project?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      A CRM project is an initiative undertaken by an
                      organization to implement CRM software or processes
                      designed to improve customer interactions, manage customer
                      data, and enhance overall customer relationships.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Why is a CRM project important for businesses?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Why is a CRM project important for businesses? CRM
                      projects are essential because they help businesses better
                      understand their customers, improve customer satisfaction,
                      increase sales, and enhance customer support, ultimately
                      leading to increased revenue and profitability.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What are the main benefits of implementing a CRM
                          system?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      The main benefits include improved customer relationships,
                      streamlined sales and marketing processes, enhanced
                      customer service, better data management, and the ability
                      to make data-driven decisions.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What are the key features to look for in a CRM
                          software solution?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Key features include contact management, lead and
                      opportunity management, sales and marketing automation,
                      customer support and service management, analytics and
                      reporting, and integration capabilities.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How do I ensure the successful implementation of a CRM
                          project?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Success factors include clear project goals, executive
                      buy-in, user training, data migration, ongoing support,
                      and a focus on aligning CRM with business processes.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How can I ensure data privacy and security in a CRM
                          project?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Implement robust data security measures, including user
                      access controls, encryption, regular data backups, and
                      compliance with data protection regulations such as GDPR
                      (General Data Protection Regulation).
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Can a CRM system integrate with other business
                          software applications?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Yes, many CRM systems offer integration capabilities to
                      connect with other software, such as ERP, marketing
                      automation, email, and e-commerce platforms, to streamline
                      processes and data flow.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <RaiseaQuery />
        <Lastpoint />
      </div>
    </>
  );
};
export default CustomerRM;
