import React from "react";
import PageTitle from "../../Common/PageTitle";
import Customercare from "../../component/Customercare";
import {
  Card, CardHeader, CardBody, SimpleGrid, Image, Accordion,
} from "@chakra-ui/react";
import Testimonials from "../../component/CardContain/Testimonials";
import testimonialdata from "../../component/Testimonials/App$mixedTestimonials.json";
import Lottie from "lottie-react";
import m from "../../component/jsonfile/maintenance.json";
import p from "../../component/jsonfile/production.json";
import a from "../../component/jsonfile/analysis.json";
import q from "../../component/jsonfile/quality.json";
import h from "../../component/jsonfile/hologram.json";
import e from "../../component/jsonfile/education.json";
import aviation from "../../component/jsonfile/aviation.json";
import RaiseaQuery from "../../component/RaiseaQuery";
import { Container, Col, Row } from "reactstrap";
import MixRealCard from "./Cards/MIx_Real_Card";
import AnimationCard from "./Cards/AnimationCard";
import MixedApp from "./Cards/MixedApp";
import TextTab from "./Cards/TextTab";
import FaqItem from "../../component/faq/FaqItem";

const MixedReality = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          title="Mixed Reality App Development"
          desc="Mixed reality application is the result of adding or merging the physical world with the virtual world to create new surroundings where physical and digital objects interchange with each other in real-time. In short, 
        mixed reality application is the combination of our physical and digital worlds."
          bimg="/img/banner/mixed-reality.png"
          page="Mixed Reality"
        />
        <section className="  mt-5  dot-pattern It_solution_section">
          <Container >
            <Row >
              <Col md={12} className="text-center">
                <div className="  top_title  mb-2  ">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span>Services we offer</span>
                  </h2>
                </div>
                <p
                  className="lead text-center"
                  data-aos="fade-up"
                  data-aos-duration="600"
                > With Our Mixed Reality Solutions <br />
                  According to our user”s environment, we help you to generate
                  rich,enveloping events accordingly aligning 3D content with <br />
                  real device. we also offer services like virtual reality,augmented
                  reality and extended reality.
                </p>
              </Col>
              <Col md={12} className=" mt-4 mb-5 ">
                <SimpleGrid spacing={4} className="addcss_onPara " columns={{ base: 1, sm: 1, md: 2, lg: 3 }}>
                  <MixRealCard delay="100" img="/img/augmented-reality.png" title=" Augmented Reality (AR)"
                    txt="  AR adding a digital element to a live view repeatedly by
                        using the camera on a smartphone. AR restoring artificial
                        objects in the real world."/>
                  <MixRealCard delay="200" img="/img/virtual-reality.png" title="Virtual Reality (VR)"
                    txt="   Virtual Reality (VR) is the use of computer technology to
                        create an assumed environment. Major players in VR include
                        HTC Vive, Oculus Rift, and PSVR."/>
                  <MixRealCard delay="300" img="/img/extended-reality.png" title="Extended Reality (XR)"
                    txt=" XR stands for “Extended Reality” and it is an umbrella term
                        that covers AR, VR, & MR as it covers all the technologies
                        that enhance our scenes, whether they provide additional
                        information."/>
                </SimpleGrid>
              </Col>
            </Row>
          </Container>
        </section>
        <Customercare />
        <section style={{ background: '#e9f1f5', marginTop: '-40px' }}>
          <Container>
            <Row>
              <Col md={12} className="pt-5 text-center  dot-pattern">
                <div className="top_title text-center">
                  <h2 data-aos="fade-up" data-aos-delay="500">
                    <span>System of Mixed Reality</span>
                  </h2>
                </div>
                <p className="lead  fw-300" data-aos="fade-up" data-aos-delay="600">
                  There are two systems of mixed reality applications that are used
                  to merge virtual objects with the real world.
                </p>
              </Col>
              <Col md={12} className=" mt-3 pb-3 mb-5 ">
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                  <Card size="sm" data-aos="fade-up" data-aos-delay="500">
                    <Image src="/img/gif/markbased.gif" alt="..." />

                    <CardBody>
                      <h5 size="sm" className="text-blue text-center mt-2">
                        Marker System
                      </h5>
                      <p>
                        In the actual or real scene, special markers are placed that
                        are recognized and changed by virtual objects during the
                        period.
                      </p>
                    </CardBody>
                  </Card>
                  <Card size="sm" data-aos="fade-up" data-aos-delay="700">
                    <Image src="/img/gif/markless.gif" alt="..." />

                    <CardBody>
                      <h5 size="sm" className="text-blue text-center mt-2">
                        Marker-Less System
                      </h5>
                      <p>
                        Real-world objects naturally placed at the exact position in
                        marker-less systems (e.g. t,v, remote control, a cup, or a
                        book) are used as markers.
                      </p>
                    </CardBody>
                  </Card>
                </SimpleGrid>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="softwareDevpl_bgImg  rounded-lg ">
          <div className="onsoftwareDevpl_BGlayer">
            <Container style={{ marginTop: "25px" }}>
              <Row>
                <Col md={12} className=" mt-3 mb-4 text-center">
                  <h2 className=" text-color mb-2 " data-aos="fade-up" data-aos-duration="500">
                    <span>Mixed Reality  On The Business Floor</span>
                  </h2>
                  <p className="lead text-light fw-light">
                    Mixed reality application is being used in small businesses
                    for providing the proper training and developing the
                    project.
                  </p>
                </Col>
                <Col md={12} className=" mb-5">
                  <SimpleGrid
                    spacing={4}
                    columns={[1, 1, 2, 4]}
                    className="stepProcess_section"
                  >
                    <AnimationCard delay="100" anim={m} title=" Maintenance and operations" txt="
                        Smart specs provide remote support, instruction, and
                        metrics for the maintenance of office workers. Complex
                        data will be generated by sensors that are on machines
                        and machine learning helps in figuring out the reasons
                        for the failure and also improves productivity."/>
                    <AnimationCard delay="200" anim={p} title="Production" txt=" Safety vests and hard hats will help in monitoring the
                          surroundings of the office as these are considered
                          smart equipment. For programmed production, smart
                          robustness will be required."/>
                    <AnimationCard delay="300" anim={a} title="Reporting and Analytics" txt="The data management system is responsible for
                          connecting all the devices which digitally document
                          each step of the process."/>
                    <AnimationCard delay="400" anim={q} title=" Quality control" txt=" During the production,3D models give inspectors to
                          access all product specs during the production.
                          Photogrammetry is used by machines to evaluate
                          products against standards."/>
                  </SimpleGrid>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section>
          <Container>
            <MixedApp />
          </Container>
        </section>
        <section className="mt-5 pt-5 dot-pattern" style={{ background: '#e9f1f5' }}>
          <Container>
            <Row>
              <Col md={12} className="text-center  ">
                <div className="top_title text-center">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span> Benefits of Using Mixed Reality Solutions</span>
                  </h2>
                </div>
                <p
                  className="lead fw-300"
                  data-aos="fade-up"
                  data-aos-duration="700"
                >
                  Take a look at the beneifits, which you can expect form us. Below
                  are some examples that you can exprience <br /> when you get stareted
                  with us.
                </p>
              </Col>

              <Col md={12} className="mb-5 mt-3 ">
                <SimpleGrid
                  spacing={4}
                  templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
                >
                  <Card
                    align="center"
                    borderTop="8px"
                    borderColor="#056894"
                    data-aos="fade-up"
                    data-aos-duration="100"
                  >
                    <CardBody>
                      <h5 size="md" className="text-blue text-center">
                        Faster Quality Assurance Process
                      </h5>
                      <p>
                        It can help in embedding quality assurance in the production
                        process by allowing real-time visual inspection of the
                        assembled product.
                      </p>
                    </CardBody>
                  </Card>
                  <Card
                    borderTop="8px"
                    borderColor="#056894"
                    data-aos="fade-up"
                    data-aos-duration="200"
                  >
                    <CardBody>
                      <h5 size="md" className="text-blue text-center">
                        Reduction in Maintenance Calls
                      </h5>
                      <p>
                        The organization uses mixed reality applications to limit
                        call-out times to maintenance. They work with outdated
                        manuals and are slowly leaving that behind.
                      </p>
                    </CardBody>
                  </Card>
                  <Card
                    borderTop="8px"
                    borderColor="#056894"
                    data-aos="fade-up"
                    data-aos-duration="300"
                  >
                    <CardBody>
                      <h5 size="md" className="text-blue text-center">
                        Employee Training Advancement
                      </h5>
                      <p>
                        Our Mixed Reality solutions will help your employees get
                        proper training and work on their productivity. Train your
                        employees with modern-technology, & save your money, man
                        power, time, etc.
                      </p>
                    </CardBody>
                  </Card>
                </SimpleGrid>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Row>
              <Col md={12} className=" mt-5 text-center  dot-pattern">
                <div className="top_title text-center">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span> Industries That Can Utilize Mixed Reality</span>
                  </h2>
                </div>
                <p
                  className="lead  fw-300 mb-5"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  There are a lot of industries that can derive maximum advantages
                  of mixed reality including augmented reality applications. <br /> Some of
                  the industries that can quickly deploy mixed reality are
                </p>
              </Col>
              <Col md={12}>
                <Row>
                  <TextTab txt="E-commerce" />
                  <TextTab txt="Automative" />
                  <TextTab txt="Fashion" />
                  <TextTab txt="Gaming" />
                  <TextTab txt="Events" />
                  <TextTab txt="Commercial" />
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="mt-5 bg-light">
          <Container>
            <Row>
              <Col md={12} className="pt-5 text-center  dot-pattern">
                <div className="top_title text-center">
                  <h2 data-aos="fade-up" data-aos-duration="5000">
                    <span> Applications of Mixed Reality</span>
                  </h2>
                </div>
                <p
                  className="lead mt-2 fw-300"
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  There are some applications that are used in mixed reality by
                  which we can experience a new visualization and environment.
                </p>
              </Col>
              <Col md={12} className="mb-3 ">
                <div className="col-md-12   mt-5 mb-5 ">
                  <SimpleGrid
                    spacing={4}
                    className="stepProcess_section"
                    templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
                  >
                    <Card data-aos="fade-up" data-aos-duration="100">
                      <CardHeader className=" d-flex justify-content-center align-items-center">
                        <div className="lottie_bg">
                          <Lottie animationData={h} />
                        </div>
                      </CardHeader>
                      <CardBody className="text-center">
                        <h5 size="md" className=" text-blue ">
                          Hologram
                        </h5>
                        <p className="text-black">
                          Holograms are generated by people to show objects or
                          scenes that never existed. It is a photographic technique
                          that records the light that are scattered from an object.
                        </p>
                      </CardBody>
                    </Card>
                    <Card data-aos="fade-up" data-aos-duration="300">
                      <CardHeader className=" d-flex justify-content-center align-items-center">
                        <div className="lottie_bg">
                          <Lottie animationData={e} />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-blue text-center">
                          Education
                        </h5>
                        <p className="text-black ">
                          Our Mixed Reality solutions can help the early age
                          students get motivated and learn more about their course
                          without losing the interest
                        </p>
                      </CardBody>
                    </Card>
                    <Card data-aos="fade-up" data-aos-duration="500">
                      <CardHeader className=" d-flex justify-content-center align-items-center">
                        <div className="lottie_bg">
                          <Lottie animationData={aviation} />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className=" text-blue text-center">
                          Aviation
                        </h5>
                        <p className="text-black">
                          Aviation solutions use Mixed Reality which provides
                          real-time experiential training and drives up digital
                          manufacturing strategy.
                        </p>
                      </CardBody>
                    </Card>
                  </SimpleGrid>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container>
            <Col md={12} className=" mt-3  text-center  dot-pattern">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span> FAQ's About</span>
                </h2>
              </div>
              <h5 className="After_title">Mixed Reality</h5>
            </Col>
            <Col md={12} className=" mt-5 mb-5 ">
              <Accordion allowToggle>
                <FaqItem query="What is a Mixed Reality Production System (MRPS)?" answer="
             While the industry calls it “Virtual Production”, we like
                      to stick to “Mixed Reality”: (real) actors perform a head
                      for virtual backdrop powered by a Game Engine for
                      green/blue screen real-time chroma formation or a LED
                      Wall. A Mixed Reality Production System is the complete
                      environment of a virtual set in which the camera, lights,
                      LED wall, and the rest of the structure all operate
                      together."/>
                <FaqItem query="   What is the most important technical challenge in VR?" answer="   Holding patterns in creating new content is the most
                        extensive challenge AR and VR face today. It has been
                        since the beginning of these ideas."/>
                <FaqItem query="  How do we protect customer and user data?" answer="   
                          Transport Layer Security (TLS) is extensively used in the
                        HTTPS protocol for forwarding classified data like
                        passwords or financial statistics. If there’s a lock next
                        to your browser’s search bar, then the site you’re on is
                        using Transport Layer Security(TLS)."/>
                <FaqItem query="How should I evaluate which Tech to use: AR, VR, or
                            MR?" answer="  Augmented Reality covers virtual objects into existence
                        where MR(Mixed Reality) goes one pace forward by anchoring
                        virtual gadgets onto the real world environment. VR also
                        stands for Virtual Reality which is a completely immersive
                        computerized environment. A top case use is learning and
                        development because it allows traniess to do work in a
                        risk-free environment."/>
                <FaqItem query=" What is the most important technique challenge in AR?" answer=" 
                        The most important technique challenge in Augmented
                        Reality is to be sure about the user's awareness about the
                        technologies used in AR and design of economical augmented
                        reality headset."/>
                <FaqItem query="Does AR and MR improve user experience ?" answer=" 
                         Obviously Yes, AR (Augmented Reality) and MR (Mixed
                        Reality) will improve user experience."/>
                <FaqItem query="What are Virtual Web Applications? Are they Virtual
                            Reality?" answer="  No, People often confuse anything virtual with Virtual
                        Reality because virtual reality always involves a
                        mechanism to engage users in a distinct world."/>
              </Accordion>
            </Col>
          </Container>
        </section>
        <Testimonials
          testimonialdata={testimonialdata}
          tHead={"Customer Review"}
          tText={"See What Our Customers are Saying"}
        />
        <RaiseaQuery />
      </div>
    </>
  );
};
export default MixedReality;
