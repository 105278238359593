import React from "react";
import PageTitle from "../../Common/PageTitle";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Button,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  AspectRatio,
  Stack,
  Box,
  IconButton,
} from "@chakra-ui/react";
import {
  EmailIcon,
  PhoneIcon,
  ArrowRightIcon,
  ArrowForwardIcon,
  CheckIcon,
  CheckCircleIcon,
  MinusIcon,
  AddIcon,
} from "@chakra-ui/icons";

import Lottie from "lottie-react";
import feature from "./Feature/bakeryfeature.json";
import Featuredata from "../../component/Featuredata";
import Lastpoint from "../../component/Lastpoint";
const BakerySM = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="Bakery Shop Management Application"
          page="Bakery Shop Management"
          desc="Developing a Bakery Shop Management Application can help streamline operations, enhance customer service, and improve overall efficiency in a bakery business."
          bimg="https://www.posist.com/restaurant-times/wp-content/uploads/2020/03/Baked-Goods.jpg"
        />
        <div className="container ">
          <div className=" col-md-12   mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Do You Need</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Bakery Shop Management Application
            </h5>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid spacing={4} columns={{ base: 1, sm: 1, md: 1, lg: 1 }}>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                //    maxHeight={2500}
                style={{ padding: "10px" }}
              >
                <Stack>
                  <CardBody>
                    <List spacing={3}>
                      <ListItem data-aos="fade-up" data-aos-duration="300">
                        <ListIcon as={CheckIcon} color="#056894" />A Bakery Shop
                        Management Application is a software solution designed
                        to streamline the operations and management of a bakery
                        business. Such an application provides tools and
                        features that help bakery owners and staff efficiently
                        handle various aspects of their business, from order
                        management and inventory tracking to customer engagement
                        and sales analysis.
                      </ListItem>
                      <ListItem data-aos="fade-up" data-aos-duration="500">
                        <ListIcon as={CheckIcon} color="#056894" />
                        Create and manage a digital catalog of bakery products,
                        including bread, cakes, pastries, and more. Include
                        product details such as name, description, pricing,
                        ingredients, and images. Organize products into
                        categories (e.g., bread, desserts) for easy navigation.
                        Allow customers to browse the menu and place orders
                        online through a website or mobile app. Enable
                        customization options (e.g., cake size, frosting type,
                        special requests). Implement shopping cart functionality
                        for order review and modification. Track inventory
                        levels of ingredients and finished products in
                        real-time. Receive automated alerts for low-stock items
                        and ingredients. Manage suppliers and purchase orders
                        for ingredient restocking. Receive and manage incoming
                        orders from various channels (online, in-store, phone).
                        Assign orders to specific time slots for production and
                        delivery/pickup. Send order confirmation notifications
                        to customers.
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="cover"
                    maxW={{ base: "100%", sm: "450px" }}
                    src="https://c4.wallpaperflare.com/wallpaper/928/581/137/cake-chocolate-piece-cut-wallpaper-preview.jpg "
                    alt="..."
                  />
                </div>
              </Card>
            </SimpleGrid>
          </div>

          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Features For</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Bakery Shop Management Application
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              A Bakery Shop Management Application should have a comprehensive
              set of features to efficiently manage bakery operations, improve
              customer service, and streamline processes. Here are some
              essential features for such an application
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={4}
              templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
            >
              <Featuredata feature={feature} />
            </SimpleGrid>
          </div>
          <div className=" col-md-12   mt-5 mb-5 text-center ">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>FAQ's For</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Bakery Shop Management Application
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Certainly! Here are some frequently asked questions (FAQs) that
              customers and bakery owners might have about a Bakery Shop
              Management Application:
              <br />
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <Accordion allowToggle>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is a Bakery Shop Management Application?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      A Bakery Shop Management Application is a software
                      solution that helps bakery owners manage their operations
                      efficiently. It includes features like order management,
                      inventory tracking, online ordering, and customer
                      engagement tools.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How can I place an order through the Bakery Shop
                          Management Application?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      To place an order, visit our website or mobile app, browse
                      the menu, select the items you want, customize them if
                      needed, add them to your cart, and proceed to checkout.
                      You can also place orders in-store or by phone.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Is it possible to track my order once it's placed?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Yes, you can track the status of your order through the
                      application. You will receive notifications when your
                      order is confirmed, in preparation, out for delivery, or
                      ready for pickup.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Do you offer delivery services, and how do I select a
                          delivery time?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Yes, we provide delivery services. When placing an order,
                      you can select a delivery time and provide your delivery
                      address. Delivery charges may vary based on distance.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Can I view my previous orders and order history?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Yes, you can view your order history and reorder your
                      favorite items through the application. Your past orders
                      and preferences are saved in your account.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How do I make payments through the Bakery Shop
                          Management Application?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      We accept various payment methods, including credit cards,
                      digital wallets, and cash for in-store pickups. Online
                      payments are securely processed through the application.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Is the Bakery Shop Management Application available on
                          mobile devices ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Yes, we offer a mobile application for both iOS and
                      Android devices, making it convenient for you to order
                      from your smartphone or tablet.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </div>
          <Lastpoint />
        </div>
      </div>
    </>
  );
};
export default BakerySM;
