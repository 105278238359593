import { AccordionItem, AccordionButton, AccordionPanel, Box} from "@chakra-ui/react";
import { MinusIcon, AddIcon } from "@chakra-ui/icons";

const FaqItem = (props) => {
  return (
    <>
      <AccordionItem
        data-aos="fade-up"
        data-aos-duration="500"
        className="accordion-content"
      >
        {({ isExpanded }) => (
          <>
            <h2
              style={{
                backgroundColor: "#f0f0f0",
                border: "1px solid #056894",
                borderRadius: "5px",
              }}
            >
              <AccordionButton
                _expanded={{
                  bg: " #056894",
                  color: "white",
                  border: "1px solid  lightgray",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <Box as="span" py={2} flex="1" textAlign="left">
                  {props.query}
                </Box>
                {isExpanded ? (
                  <MinusIcon fontSize="12px" />
                ) : (
                  <AddIcon fontSize="12px" />
                )}
              </AccordionButton>
            </h2>
            <AccordionPanel
              borderRadius="5px"
              mb={3}
              borderBottom="1px solid lightgray"
              borderRight="1px solid lightgray"
              borderLeft="1px solid lightgray"
              pb={4}
              color="#000"
            >
              {props.answer}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </>
  );
};

export default FaqItem;
