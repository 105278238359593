import React from "react";
import PageTitle from "../../Common/PageTitle";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  SimpleGrid,
  Button,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  AspectRatio,
  Stack,
  Box,
  IconButton,
} from "@chakra-ui/react";
import {
  EmailIcon,
  PhoneIcon,
  ArrowRightIcon,
  ArrowForwardIcon,
  CheckIcon,
  CheckCircleIcon,
  MinusIcon,
  AddIcon,
} from "@chakra-ui/icons";

import Lottie from "lottie-react";
import feature from "./Feature/erpfeature.json";
import RaiseaQuery from "../../component/RaiseaQuery";
import Featuredata from "../../component/Featuredata";
import Lastpoint from "../../component/Lastpoint";

const EnterpriseRP = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="Enterprise Resource Planning"
          page="Enterprise Resource Planning"
          desc="Enterprise Resource Planning (ERP) is a comprehensive software solution designed to help organizations manage and streamline their business processes and operations."
          bimg="https://www.cxtoday.com/wp-content/uploads/2022/11/erp-system-850.jpg"
        />
        <div className="container ">
          <div className=" col-md-12   mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>Do You Need</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Enterprise Resource Planning (ERP) Project
            </h5>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid spacing={4} columns={{ base: 1, sm: 1, md: 1, lg: 1 }}>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                //  maxHeight={1500}
                style={{ padding: "0px" }}
              >
                <Stack>
                  <CardBody>
                    <List spacing={3}>
                      <ListItem data-aos="fade-up" data-aos-duration="300">
                        <ListIcon as={CheckIcon} color="#056894" /> ERP systems
                        integrate various functional areas of a business, such
                        as finance, human resources, manufacturing, supply chain
                        management, customer relationship management (CRM), and
                        more, into a single unified platform. This integration
                        enables better communication, collaboration, and data
                        sharing among different departments, leading to improved
                        efficiency and decision-making.
                      </ListItem>
                      <ListItem data-aos="fade-up" data-aos-duration="500">
                        <ListIcon as={CheckIcon} color="#056894" />
                        ERP systems use a centralized database where all
                        relevant data from different departments and processes
                        are stored. This ensures data consistency and accuracy,
                        as information is entered once and is available for use
                        throughout the organization.ERP software typically
                        consists of various modules, each catering to a specific
                        business function. Common modules include finance, human
                        resources, procurement, inventory management,
                        production, sales, and marketing. Organizations can
                        choose which modules to implement based on their
                        specific needs.ERP systems automate business processes
                        by providing predefined workflows and rules. This
                        automation reduces manual data entry, minimizes errors,
                        and speeds up task completion. For example, an ERP can
                        automate the process of generating invoices, managing
                        inventory levels, or tracking employee attendance.
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="cover"
                    maxW={{ base: "100%", sm: "450px" }}
                    src="/img/erp_img.png"
                    alt="..."
                  />
                </div>
              </Card>
            </SimpleGrid>
          </div>

          <br />
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Features For</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Enterprise Resource Planning (ERP)
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Enterprise Resource Planning (ERP) systems come with a wide range
              of features and functionalities to support various business
              processes and operations. The specific features you need in an ERP
              system depend on your organization's industry, size, and unique
              requirements. However, here are some common features found in ERP
              systems:
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={4}
              templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
            >
              <Featuredata feature={feature} />
            </SimpleGrid>
          </div>
          <div className=" col-md-12   mt-5 mb-5 text-center ">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> FAQ's For</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Enterprise Resource Planning (ERP)
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Certainly! Here are some frequently asked questions (FAQs) related
              to Enterprise Resource Planning (ERP):
              <br />
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <Accordion allowToggle>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What is ERP?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      ERP stands for Enterprise Resource Planning. It's a
                      software solution that integrates various business
                      functions and processes into a single unified system to
                      streamline operations and improve efficiency.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Why do organizations need ERP systems?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      ERP systems help organizations manage their resources
                      effectively, reduce manual tasks, improve data accuracy,
                      enhance decision-making, and adapt to changing business
                      needs.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How do I choose the right ERP system for my
                          organization?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      To choose the right ERP system, assess your organization's
                      needs, conduct a thorough vendor evaluation, consider
                      scalability, and involve key stakeholders in the
                      decision-making process.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Can ERP systems be customized to fit specific business
                          processes?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Yes, ERP systems often offer customization options to
                      align with an organization's unique processes and
                      requirements.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What are the advantages of cloud-based ERP over
                          on-premises ERP?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Cloud-based ERP offers benefits like lower upfront costs,
                      scalability, accessibility from anywhere, automatic
                      updates, and reduced IT infrastructure management.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How long does it typically take to implement an ERP
                          system?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      ERP implementation timelines vary depending on the
                      complexity of the system and the organization's readiness.
                      It can take several months to a year or more.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What role does data security play in ERP systems?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Data security is critical in ERP systems due to the
                      sensitive nature of business data. ERP systems typically
                      include security features like user authentication, access
                      controls, and encryption.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </div>

          <Lastpoint />
        </div>
      </div>
    </>
  );
};
export default EnterpriseRP;
