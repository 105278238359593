import React from "react";
import PageTitle from "../../Common/PageTitle";
import feature from "./Feature/EsportML.json";
import { Card, CardBody, SimpleGrid } from "@chakra-ui/react";

import Customercare from "../../component/Customercare";
import Lastpoint from "../../component/Lastpoint";

const EsportML = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="E-Sports League Mobile App"
          desc="Got pumped E-Sports players to compete in an epic gaming tournament? We got you covered with our E-Sports league hosting platform."
          bimg="/img/banner/hms.jpg"
          page="E-Sports League Mobile App"
        />
        <div className="container">
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Features For</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              E-Sports League Mobile App
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              For an eSports league mobile app, you'd want to include features
              that cater to both players and fans. Here's a list of features you
              might consider:
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={4}
              templateColumns="repeat(auto-fill, minmax(255px, 1fr))"
            >
              {feature &&
                feature.map((data, index) => (
                  <Card
                    data-aos="fade-up"
                    data-aos-duration="500"
                    borderTop="8px"
                    borderColor="#056894"
                    className=" d-flex justify-content-center align-items-center "
                    style={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.9)" }}
                  >
                    <CardBody>
                      <h5 size="md" className=" text-blue text-center">
                        {data.feature}
                      </h5>
                      <p>{data.description}</p>
                    </CardBody>
                  </Card>
                ))}
            </SimpleGrid>
          </div>
        </div>
        <Customercare />
        <Lastpoint />
      </div>
    </>
  );
};
export default EsportML;
