import React from "react";
import { Link } from "react-router-dom";
import PageTitle from "../../Common/PageTitle";
import feature from "./Feature/Coldfeature.json";
import { Card, Button, CardBody, SimpleGrid } from "@chakra-ui/react";

import Customercare from "../../component/Customercare";
import Lastpoint from "../../component/Lastpoint";
const ColdSS = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="Cold Storage Software"
          desc="Provide your customers with real-time access to their statements, by using a cold storage software solution. Imagine giving your customers."
          bimg="/img/banner/coldss.jpg"
          page="Cold Storage Software"
        />
        <div className="container">
          <div className=" col-md-12   mt-5 mb-5 text-center">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Features For</span>
              </h2>
            </div>
            <h5
              data-aos="fade-up"
              data-aos-duration="500"
              className="After_title mb-4"
            >
              Cold Storage Software Application
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              When developing an Cold storage software it typically includes a
              variety of features designed to streamline operations, ensure food
              safety, and enhance efficiency. Here are some common features you
              might find in cold storage software
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              spacing={4}
              templateColumns="repeat(auto-fill, minmax(255px, 1fr))"
            >
              {feature &&
                feature.map((data, index) => (
                  <Card
                    data-aos="fade-up"
                    data-aos-duration="500"
                    borderTop="8px"
                    borderColor="#056894"
                    className=" d-flex justify-content-center align-items-center "
                    style={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.9)" }}
                  >
                    <CardBody>
                      <h5 size="md" className=" text-blue text-center">
                        {data.feature}
                      </h5>
                      <p>{data.description}</p>
                    </CardBody>
                  </Card>
                ))}
            </SimpleGrid>
          </div>
        </div>
        <Customercare />
        <Lastpoint />
      </div>
    </>
  );
};
export default ColdSS;
