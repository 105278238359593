import React from "react";
import PageTitle from "../Common/PageTitle";
import { Link } from "react-router-dom";

const four04page = () => {
    return (
        <>
            <div className="slidersection_here ">
                <div className="pageheader bg-blue"></div>
                <PageTitle
                    title="404 Error"
                    bimg="https://www.elegantthemes.com/blog/wp-content/uploads/2021/06/shutterstock_1075475231.jpg"
                    page="404 Error"
                />
                <div className="container about_page" style={{ marginBottom: "-80px" }}>
                    <div className="row dot-pattern">
                        <div className="col-lg-6 wow fadeInUp ">
                            <img src="/assets/404gif.gif" alt="" style={{ height: '500px' }} />
                        </div>
                        <div className="col-lg-6 wow fadeInUp   ">
                            <div className=" mt-lg-5 pt-lg-5">
                                <div className="">
                                    <br /><br /><br />
                                    <h1>Page Not Found!</h1>
                                </div>
                                <br /><br />
                                <Link to="/" className="all_btn topindex">
                                        <span>Back To Home</span>
                                    </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default four04page
