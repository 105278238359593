import { ListItem, List, ListIcon } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PageTitle from "../Common/PageTitle";
import { Link } from "react-router-dom";

const Career = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          title="Career With Us"
          desc=<span>
            Our finest moments are most likely to occur when we are feeling
            deeply uncomfortable, unhappy, or unfulfilled. For it is only in
            such moments, propelled by our discomfort, that we are likely to
            step out of our ruts and start searching for different ways or truer
            answers.” <br />
            M. Scott Peck
          </span>
          bimg="/img/contact-us.png"
          page="Career"
        />
        <section className="mt-5 pt-5">
          <Container>
            <Row className=" mt-n1-9">
              <Card className="android_app_develp ">
                <CardBody>
                  <Col lg={12} className=" wow fadeInUp mt-1-9 career_pagecss">
                    <div className="pe-2 ml-5 mt-5">
                      <div className="mb-2-2 mx-auto It_solution_section ">
                        <div className="top_title ">
                          <h2>Developer - .Net </h2>
                        </div>
                        <span className=" text-blue letter-spacing-2 fw-semibold fs-5 mb-4 mt-3">
                          Key Responsibilities:
                        </span>
                      </div>
                      <br />
                      <p className="lead fw-300">
                        Work on all development life cycle activities that
                        include Analysis, Design, Coding, & Implementation.
                      </p>
                      <p className="lead fw-300">
                        {" "}
                        Execute all phases of software application projects
                        including resolving issues and helping to ensure
                        successful delivery.
                      </p>
                      <p className="lead fw-300">
                        {" "}
                        Proactively adapt to the changing business and
                        technological landscape of various.Net platforms.
                      </p>

                      <p className="lead fw-300">
                        {" "}
                        Design and add value in all stages of project work
                        (Definition, Development, Delivery).
                      </p>
                      <p className="lead fw-300">
                        {" "}
                        Play a crucial role in evaluating new .Net Technologies
                        and develop plans for their implementation.
                      </p>
                      <br />
                      <h6 className="text-blue letter-spacing-2 fw-bold fs-5 mb-4">
                        Note:
                      </h6>
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Passive tools including laptop will be provided by
                          vendor.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Shifts will be normal.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          On site support in
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Experience - 2 year (Required)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Full Time
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Lucknow
                        </ListItem>
                        <ListItem py={5}>
                          <Link to="/Contact" className="all_btn">
                            <span>Apply Now</span>
                          </Link>
                        </ListItem>
                      </List>
                    </div>
                  </Col>
                </CardBody>
              </Card>

              <Card className="android_app_develp mt-4">
                <CardBody>
                  <Col lg={12} className=" wow fadeInUp mt-1-9 career_pagecss">
                    <div className="pe-2 ml-5 mt-5">
                      <div className="mb-2-2 mx-auto It_solution_section ">
                        <div className="top_title ">
                          <h2>React Developer </h2>
                        </div>
                        <span className=" text-blue letter-spacing-2 fw-semibold fs-5 mb-4 mt-3">
                          Key Responsibilities:
                        </span>
                      </div>
                      <br />
                      <p className="lead fw-300">
                        You have a good grasp of Javascript and React
                        fundamentals - how things work under the hood and what
                        makes an app performant.
                      </p>
                      <p className="lead fw-300">
                        You have an excellent understanding of CSS and have
                        experience with (+ an opinion on) CSS frameworks like a
                        tailwind, etc.
                      </p>
                      <p className="lead fw-300">
                        You have worked with React hooks, Redux (or another
                        state management library).
                      </p>
                      <p className="lead fw-300">
                        {" "}
                        You understand well what causes latency in applications
                        and how to avoid such problems.{" "}
                      </p>
                      <p className="lead fw-300">
                        {" "}
                        You are good with drilling down errors, understanding
                        why they are showing up and how they could be resolved.
                      </p>
                      <p className="lead fw-300">
                        {" "}
                        You have a good understanding of Git and have been using
                        a Git workflow
                      </p>
                      <p className="lead fw-300">
                        You understand how the web works (request-response cycle
                        and REST APIs).
                      </p>
                      <p className="lead fw-300">
                        You are curious about engineering and javascript - how
                        things work, what's new and in general, spend a
                      </p>
                      <p className="lead fw-300">
                        good amount of time incrementing your skills.
                      </p>
                      <br />
                      <h6 className="text-blue letter-spacing-2 fw-bold fs-5 mb-4">
                        Note:
                      </h6>
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Experience - 2 year (Required)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Full Time
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Lucknow
                        </ListItem>
                        <ListItem py={5}>
                          <Link to="/Contact" className="all_btn">
                            <span>Apply Now</span>
                          </Link>
                        </ListItem>
                      </List>
                    </div>
                  </Col>
                </CardBody>
              </Card>

              <Card className="android_app_develp mt-4">
                <CardBody>
                  <Col lg={12} className=" wow fadeInUp mt-1-9 career_pagecss">
                    <div className="pe-2 ml-5 mt-5">
                      <div className="mb-2-2 mx-auto It_solution_section ">
                        <div className="top_title ">
                          <h2>Angular Developer</h2>
                        </div>
                        <span className=" text-blue letter-spacing-2 fw-semibold fs-5 mb-4 mt-3">
                          Job Description:
                        </span>
                      </div>
                      <br />
                      <p className="lead fw-300">
                        Are you a front-end developer with Angular skills ? Are
                        you acquainted with the terms such as spring boot,
                        hibernate, and restful web services? Do you have
                        experience working on these technologies in an agile
                        environment ? Virtue Analytics is currently looking for
                        a dynamic candidate with an entrepreneurial mindset to
                        take up the above responsibility.{" "}
                      </p>
                      <p className="lead fw-300">
                        The Angular developer position is part of the core
                        Virtue team. This position is responsible for conducting
                        research, identifying effective ways of building
                        responsive web applications. The role is the part of the
                        technology team developing a cutting-edge product
                        pioneering to revolutionize university recruitment
                        decisions. Utilizing feedback from back-end developers
                        and leveraging the emerging technology to secure, scale,
                        and refine the product UI.{" "}
                      </p>

                      <br />
                      <h6 className="text-blue letter-spacing-2 fw-bold fs-5 mb-4">
                        Required Experience and Skills:
                      </h6>
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          2+ years of relevant development experience.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Experience with Angular (5,7,8)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Experience with security principles and frameworks
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Strong communication skills along with the ability to
                          work in a highly collaborative environment.
                        </ListItem>
                        <ListItem py={5}>
                          <Link to="/Contact" className="all_btn">
                            <span>Apply Now</span>
                          </Link>
                        </ListItem>
                      </List>
                    </div>
                  </Col>
                </CardBody>
              </Card>

              <Card className="android_app_develp mt-4">
                <CardBody>
                  <Col lg={12} className=" wow fadeInUp mt-1-9 career_pagecss">
                    <div className="pe-2 ml-5 mt-5">
                      <div className="mb-2-2 mx-auto It_solution_section ">
                        <div className="top_title ">
                          <h2>Technical Project Manager</h2>
                        </div>
                        <span className=" text-blue letter-spacing-2 fw-semibold fs-5 mb-4 mt-3">
                          Responsibilities:
                        </span>
                      </div>
                      <br />
                      <p className="lead fw-300">
                        Monitor, troubleshoot, and escalate production problems
                        per operations processes, affecting the network
                        infrastructure
                      </p>
                      <p className="lead fw-300">
                        Supervising responsibilities including interviewing and
                        training/coaching team members
                      </p>
                      <p className="lead fw-300">
                        Participate in team projects and project/technology
                        transition
                      </p>
                      <p className="lead fw-300">
                        Identify and resolve chronic issues.
                      </p>
                      <p className="lead fw-300">
                        Help team to Create RCA whether you have been involved
                        directly or indirectly
                      </p>
                      <p className="lead fw-300">
                        Additional duties as required and possible assistance on
                        Special Projects
                      </p>
                      <p className="lead fw-300">
                        Day today operation and maintenance of the customer
                        cloud network, supported by a worldwide robust and
                        flexible level 2 team. Network consists primarily of
                        Cisco, Checkpoint and F5 devices but also Juniper (not
                        mandatory)
                      </p>
                      <p className="lead fw-300">
                        Creation of network documentation from the existing
                        infrastructure, planned infrastructure as well as
                        updating existing documentation.
                      </p>
                      <br />
                      <h6 className="text-blue letter-spacing-2 fw-bold fs-5 mb-4">
                        Note:
                      </h6>
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Passive tools including laptop will be provided by
                          vendor.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Shifts will be normal.
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          On site support in
                        </ListItem>
                        <ListItem py={5}>
                          <Link to="/Contact" className="all_btn">
                            <span>Apply Now</span>
                          </Link>
                        </ListItem>
                      </List>
                    </div>
                  </Col>
                </CardBody>
              </Card>
              <Card
                className="android_app_develp mt-4"
                style={{ position: "relative", zIndex: "9" }}
              >
                <CardBody>
                  <Col lg={12} className=" wow fadeInUp mt-1-9 career_pagecss">
                    <div className="pe-2 ml-5 mt-5">
                      <div className="mb-2-2 mx-auto It_solution_section ">
                        <div className="top_title ">
                          <h2>Assistant Software Engineer (MERN Stack)</h2>
                        </div>
                        <span className=" text-blue letter-spacing-2 fw-semibold fs-5 mb-4 mt-3">
                          Job Summary:
                        </span>
                      </div>
                      <br />
                      <p className="lead fw-300">
                        We are looking for a Full Stack Web Developer (MERN
                        Stack). Passionate about programming end to end, i.e
                        both frontend and backend modules and applications. Open
                        and keen to learn new technologies.{" "}
                      </p>

                      <div className="mb-2-2 mx-auto It_solution_section ">
                        <span className=" text-blue letter-spacing-2 fw-semibold fs-5 mb-4 mt-3">
                          Job Description:
                        </span>
                      </div>
                      <p className="lead fw-300">
                        Bachelors or Masters Degree in Computer Science.
                      </p>
                      <p className="lead fw-300">Good communication skills.</p>
                      <p className="lead fw-300">
                        Strong problem solving, debugging and analytical skills.
                      </p>
                      <p className="lead fw-300">
                        Must have strong knowledge of Object-Oriented
                        JavaScript, ES6, or Typescript.
                      </p>
                      <p className="lead fw-300">
                        Experience building applications in React and Node.js.
                      </p>
                      <p className="lead fw-300">
                        Understanding of Javascript libraries including
                        Chart.js, BackboneJS, jQuery etc.
                      </p>
                      <p className="lead fw-300">
                        Experience with Containerisation(Docker), Linux, Cloud
                        services (AWS) and CICD pipe lines.
                      </p>
                      <p className="lead fw-300">
                        Good knowledge of CSS and HTML.
                      </p>
                      <p className="lead fw-300">
                        Knowledge of SQL and relational databases.
                      </p>
                      <p className="lead fw-300">
                        Experience working with languages such as Java or Python
                        will be an added advantage.
                      </p>
                      <p className="lead fw-300">
                        Flair for keeping up to date with the new technologies
                        and use them in efficient manner.
                      </p>
                      <br />
                      <h6 className="text-blue letter-spacing-2 fw-bold fs-5 mb-4">
                        Note:
                      </h6>
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          3-6 Years
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Full Time
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#03b5be" />
                          Lucknow
                        </ListItem>
                        <ListItem py={5}>
                          <Link to="/Contact" className="all_btn">
                            <span>Apply Now</span>
                          </Link>
                        </ListItem>
                      </List>
                    </div>
                  </Col>
                </CardBody>
              </Card>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default Career;
