import React from "react";
import PageTitle from "../Common/PageTitle";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { ListItem, List, ListIcon } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const Approach = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          title="We're the Whole Package"
          desc="Software needs may vary significantly. To cover the full spectrum of possibilities,
         we break our approaches into these five distinct models. Each one is customizable to fit your specific needs with an almost acrobatic-level of flexibility."
          bimg="/img/banner/approach.png"
          page="Approach"
        />
        <section
          style={{ marginBottom: "-80px" }}
          //    className="bg-img cover-background"
          //  style={{ backgroundImage: "url(img/)" }}
          //  className=" gradientbg_blue"
        >
          <div className="container approch_pages">
            <div className="row mt-5 ">
              <div className="col-lg-12">
                <div className="position-relative">
                  <Swiper
                    loop={true}
                    grabCursor={true}
                    spaceBetween={30}
                    autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination]}
                    pagination={{ clickable: true }}
                    className="mySwiper pb-5"
                  >
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-lg-6 wow fadeInUp mt-1-9 ">
                          <div className="pe-2 ml-5 mt-5">
                            <div className="mb-2-2 mx-auto It_solution_section ">
                              <div className="top_title ">
                                <h2>
                                  <span>Rapid Approach</span>
                                </h2>
                              </div>
                              <p className="lead mb-1-6 fw-300">
                                This approach is asequential process often using
                                CASE tools which allows the rapid development of
                                software applications. It requires constant
                                communication between developers and the end
                                users in order to understand the problem
                                properly.
                              </p>
                              <span className=" text-maincolor letter-spacing-2 fw-bold mb-2">
                                The Approach
                              </span>
                            </div>
                            <p className="lead mt-4 fw-300">
                              Rapid development of software applications using
                              libraries of code and tools.
                            </p>
                            <h6 className="lead mb-1-6 fw-bold text-maincolor">
                              Perfect When
                            </h6>
                            <List pl={1}>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                You have a small scale project.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Low budget.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Short development time.
                              </ListItem>
                            </List>
                          </div>
                        </div>

                        <div className="col-lg-6 mt-1-9 wow fadeIn">
                          <img
                            src="/assets/approach/rapid.png"
                            className=" mb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row">
                        <div className="col-lg-6 wow fadeInUp mt-1-9 ">
                          <div className="pe-2 ml-5 mt-5">
                            <div className="mb-2-2 mx-auto It_solution_section ">
                              <div className="top_title ">
                                <h2>
                                  <span> Prototyping Approach</span>
                                </h2>
                              </div>
                              <p className="lead mb-1-6 fw-300">
                                Develop a working model which may then be
                                developed further into a fully functioning
                                solution.
                              </p>
                              <span className=" text-maincolor letter-spacing-2 fw-bold mb-2">
                                The Approach
                              </span>
                            </div>
                            <p className="lead mt-4 fw-300">
                              We simply make a small scale model of the proposed
                              program so that users can give feedback and ensure
                              it meets their needs. We focus on what the users
                              interacts with, the input and output requirements,
                              encouraging end user participation and quickly
                              modifying on user feedback.
                            </p>
                            <h6 className=" mb-1-6 fw-bold text-maincolor">
                              Perfect When
                            </h6>
                            <List pl={1}>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                You’re not totally sure what you want.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                You don’t have clear goals.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Short development time.
                              </ListItem>
                            </List>
                          </div>
                        </div>

                        <div className="col-lg-6 mt-1-9 wow fadeIn">
                          <img
                            src="/assets/approach/prototyping.png"
                            className=" mb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row ">
                        <div className="col-lg-6 wow fadeInUp mt-1-9 ">
                          <div className="pe-2 ml-5 mt-5">
                            <div className="mb-2-2 mx-auto It_solution_section">
                              <div className="top_title ">
                                <h2>
                                  <span> Agile Approach</span>
                                </h2>
                              </div>
                              <p className="lead mb-1-6 fw-300">
                                Quick thinking, co-ordinated, active and lean.
                                Adapts well to changing situations.
                              </p>
                              <span className=" text-maincolor letter-spacing-2 fw-bold mb-2">
                                The Approach
                              </span>
                            </div>
                            <p className="lead mt-4 fw-300">
                              We place emphasis on the team developing the
                              system rather than following a predefined
                              structured development processes in this approach.
                              We simply remove the need to detailed requirements
                              and complex design documentations, encourage
                              cooperation and team work.
                            </p>
                            <h6 className="lead mb-1-6 fw-bold text-maincolor">
                              Perfect When
                            </h6>
                            <List pl={1}>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                You have a web-based software development
                                project.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Your application needs timely modifications.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Moderate budget.
                              </ListItem>
                            </List>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-1-9 wow fadeIn">
                          <img
                            src="/assets/approach/agile.png"
                            className=" mb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row ">
                        <div className="col-lg-6 wow fadeInUp mt-1-9 ">
                          <div className="pe-2 ml-5 mt-5">
                            <div className="mb-2-2 mx-auto It_solution_section ">
                              <div className="top_title ">
                                <h2>
                                  <span>Structured Approach</span>
                                </h2>
                              </div>

                              <p className="lead mb-1-6 fw-300">
                                When there is a high budget, where there is a
                                preferably long time period to complete the
                                project.
                              </p>
                              <span className=" text-maincolor letter-spacing-2 fw-bold mb-2">
                                The Approach
                              </span>
                            </div>
                            <p className="lead mt-4 fw-300">
                              The structured design approach helps in dealing
                              with the size and complexity of large scale
                              projects. This approach is a process oriented
                              approach, aiming to break a large complex project
                              into a series of smaller, more manageable modules.
                              The structured approach is usually associated with
                              large-scale projects that have long time periods
                              and large budgets.
                            </p>
                            <h6 className="lead mb-1-6 fw-bold text-maincolor">
                              Perfect When
                            </h6>
                            <List pl={1}>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                You have a large scale project.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Large budget.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Long development Time.
                              </ListItem>
                            </List>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-1-9 wow fadeIn">
                          <img
                            src="/assets/approach/structured.png"
                            className=" mb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="row mt-n1-9">
                        <div className="col-lg-6 wow fadeInUp mt-1-9 ">
                          <div className="pe-2 ml-5 mt-5">
                            <div className="mb-2-2 mx-auto It_solution_section ">
                              <div className="top_title ">
                                <h2>
                                  <span>Waterfall Approach</span>
                                </h2>
                              </div>

                              <p className="lead mb-1-6 fw-300">
                                Good for you if you don’t have ambiguous
                                requirements and have a clear image of what you
                                need.
                              </p>
                              <span className=" text-maincolor letter-spacing-2 fw-bold mb-2">
                                The Approach
                              </span>
                            </div>
                            <p className="lead mt-4 fw-300">
                              When the development procedure starts with knowing
                              the requirements and ends with maintenance this
                              methodology is called as waterfall development
                              approach. This approach consists of knowing the
                              design, then implementing it and it will be
                              followed by verification. On total there will be 5
                              steps before submitting the final project.
                            </p>
                            <h6 className="lead mb-1-6 fw-bold text-maincolor">
                              Perfect When
                            </h6>
                            <List pl={1}>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                When you need optimised solution.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Development timeline can be declared.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Easier to capture milestones.
                              </ListItem>
                              <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Good for the customer if you don’t have any new
                                requirements.
                              </ListItem>
                            </List>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-1-9 wow fadeIn">
                          <img
                            src="/assets/approach/Waterfall Approach.png"
                            className=" mb-4"
                            alt="..."
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Approach;
