import React from "react";
import CountCard from "./CountCard";

const CounterSection = () => {
  return (
    <>
      <section className="counter_Section">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12 shape_one  mt-1-9">
              <img
                src="assets/home/img-awesome-fun.png"
                alt="..."
                className="img-fluid testing_imghere"
                data-aos="zoom-in-right"
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 mt-1-9  for_setSize">
              <div className="top_title text-left ">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span>
                    What Makes Us The <br /> Best IT Company In India
                  </span>
                </h2>
              </div>
              <p
                className="lead mt-4"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                We have more than 13 years of exprience and wrote 11M lines of
                code. Our exprience makes us the best IT company in India.
              </p>
              <hr />
              <div className="row counterCard mb-4">
                <CountCard
                  delay="200"
                  color="text-warning"
                  text="Successful Projects"
                  num="256"
                  icon="/assets/icon/1.png"
                />
                <CountCard
                  delay="400"
                  color="text-success"
                  text="Years of Experience"
                  num="13"
                  icon="/assets/icon/2.png"
                />
                <CountCard
                  delay="600"
                  color="text-blue"
                  text="Hours of Coding"
                  unit="K"
                  num="68"
                  icon="/assets/icon/time.png"
                />
                <CountCard
                  delay="800"
                  color="text-danger"
                  text=" Lines of Code"
                  unit="M"
                  num="11"
                  img_css="set_img"
                  icon="/assets/icon/4.png"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CounterSection;
