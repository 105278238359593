import React from 'react'

const PreLoader = () => {
    return (
        <>
            <div className="preloader_here">
                <img src="assets/Preview.gif" alt="" />
            </div>
        </>
    )
}

export default PreLoader
