import React from "react";
import PageTitle from "../Common/PageTitle";
import { TypeAnimation } from "react-type-animation";
import { StarIcon } from "@chakra-ui/icons";

const About = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          title="About OneBigBit"
          desc="We are just a group of technology radicals who are constantly at work to surpass their previous best work. At OneBigBit."
          bimg="/img/banner/about-us.jpg"
          page="About OneBigBit"
        />
        <div className="container about_page" style={{ marginBottom: "-80px" }}>
          <div className="row dot-pattern">
            <div className="col-lg-6 wow fadeInUp   ">
              <div className="pe-2  mt-5">
                <div className=" mx-auto ">
                  <div className="top_title mb-3">
                    <h2 data-aos="fade-up" data-aos-duration="500">
                      <span>Who We are?</span>
                    </h2>
                  </div>
                </div>
                <br />
                <p className="lead" data-aos="fade-up" data-aos-duration="600">
                  <StarIcon color=" #056894" /> &nbsp; We are just a group of
                  technology radicals who are constantly at work to surpass
                  their previous best work. At OneBigBit.
                </p>

                <p className="lead " data-aos="fade-up" data-aos-duration="600">
                  <StarIcon color=" #056894" /> &nbsp;we consult our clients to
                  understand their requirements and brainstorm profusely to
                  create a revolutionary solution.
                </p>

                <p className="lead " data-aos="fade-up" data-aos-duration="600">
                  <StarIcon color=" #056894" /> &nbsp;Our office in Lucknow is
                  the nerve centre of our execution process. It is from there,
                  that our team researches, rack brains, and delivers the result
                  that your company desires.
                </p>
              </div>
            </div>
            <div className="col-lg-6  mt-5">
              <img
                src="/assets/approach/Waterfall Approach.png"
                className="about_page_img" 
                alt="..."
              />
            </div>
            <div className="col-lg-10 wow fadeInUp  ">
              <div className="pe-2  mt-5">
                <div className="mb-2-2 mx-auto ">
                  <div className="top_title mb-3">
                    <h2 data-aos="fade-up" data-aos-duration="500">
                      <span>What do we want?</span>
                    </h2>
                  </div>
                </div>
                <p
                  className="lead mt-4 "
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  <StarIcon color=" #056894" /> &nbsp;We believe in competition
                  and its power to create the best solution for the clients.
                  OneBigBit is constantly on the edge to design innovative
                  solutions using state-of-the-art technology so that the
                  clients always gain ‘something extra’ to work with. Our
                  exhaustive range of services provides a rainbow of solutions
                  for every aspect of your company. Starting from software
                  development and reaching the digital marketing zone, OneBigBit
                  is the single answer to all your IT concerns.
                  <br /> <br />
                  <StarIcon color=" #056894" /> &nbsp;We desire to implement the
                  existing technology with the perfect amalgamation of our
                  ingenious solutions to produce services that take our client’s
                  company to new heights. We seek growth and opportunities that
                  season our minds to provide better IT solutions to our
                  clients, as time passes by.
                </p>
              </div>
            </div>
            <div className="col-lg-10 wow fadeInUp  ">
              <div className="pe-2  mt-5">
                <div className="mb-2-2 mx-auto ">
                  <div className="top_title mb-3">
                    <h2 data-aos="fade-up" data-aos-duration="500">
                      <span>What makes us better?</span>
                    </h2>
                  </div>
                </div>
                <p
                  className="lead mt-4 "
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  <StarIcon color=" #056894" /> &nbsp;We aren’t afraid of
                  challenges; in fact, our team thrives on them. With 15 years
                  of bearing the summer and wind in this industry, the
                  professionals in our team are seasoned for creation. We see
                  every opportunity as a challenge to outperform our previous
                  job. This productive clash inches us closer in becoming the
                  bellwether of the IT industry.
                  <br /> <br />
                  <StarIcon color=" #056894" /> &nbsp;We always deliver more
                  than what we say. This is credited to our bond with the client
                  company and our values that drive us. Every company aims for
                  growth and we aim to smoothen that process out by providing
                  revolutionary technology and marketing services like SEO. So,
                  the reality of your company exceeds the picture in your
                  imagination.
                </p>
              </div>
            </div>
            <div className="col-lg-10 wow fadeInUp  ">
              <div className="pe-2  mt-5">
                <div className="mb-2-2 mx-auto ">
                  <div className="top_title mb-3">
                    <h2 data-aos="fade-up" data-aos-duration="500">
                      <span>Our Team?</span>
                    </h2>
                  </div>
                </div>
                <p
                  className="lead mt-4 "
                  data-aos="fade-up"
                  data-aos-duration="600"
                >
                  <StarIcon color=" #056894" /> &nbsp;Our team of brains work
                  around the clock (with obvious breaks) to devise innovative
                  solutions for our clients. Coupled with their experience and
                  theoretical knowledge, our team presents the clients with the
                  solutions that they would have never thought of. We focus on
                  quality, and deliver performance with concrete results that
                  establish a strong foundation for your company to grow on.
                </p>
              </div>
            </div>
            <div className="col-lg-10  wow fadeInUp ">
              <div
                className="p-12  mt-5  gradientbg_blue text-color rounded"
                style={{
                  borderRadius: "10px",
                  borderLeft: "6px solid transparent",
                  // color: "white",
                  //"linear-gradient(128deg, #00081b 25%, #00658d 100%)",
                }}
              >
                <TypeAnimation
                  data-aos="fade-up"
                  data-aos-duration="600"
                  sequence={[
                    "The testimonials from our clients reaffirm our confidence in our products and services and drive us towards better performances.",
                    1000,

                    "The testimonials from our clients reaffirm our confidence in our products and services and drive us towards better performances. - Steve jobs",
                    1000,
                  ]}
                  wrapper="span"
                  speed={50}
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    fontSize: "2em",
                    display: "inline-block",
                    fontFamily: "Poppins, sans-serif",
                  }}
                  repeat={Infinity}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
