import { Card, CardHeader, CardBody, Image } from "@chakra-ui/react";

const ItSolutionCard = (props) => {
  return (
    <>
      <div className="ItSolution_swiper">
        <Card>
          <CardHeader className="d-flex justify-content-center align-items-center">
            <Image src={props.img} alt="..." borderRadius="lg" />
          </CardHeader>
          <CardBody>
            <h5 size="md" className="text-center  text-blue">
              {" "}
              {props.title}
            </h5>
            <p>{props.txt}</p>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ItSolutionCard;
