import { Accordion } from "@chakra-ui/react";

import FaqItem from "./FaqItem";

const Faqs = ({ faqdata }) => {
  return (
    <>
      <div className=" col-md-12   mt-5 mb-5 text-center">
        <div className="top_title text-center">
          <h2 data-aos="fade-up" data-aos-duration="500">
            <span> FAQ's About</span>
          </h2>
        </div>
        <h5
          className="After_title mb-2"
          data-aos="fade-up"
          data-aos-duration="600"
        >
          Software Development Company
        </h5>
      </div>
      <div className="col-md-12 mb-2 ">
        <Accordion allowToggle>
          {faqdata.map((data, index) => (
            <FaqItem query={data.query} answer={data.answer} />
          ))}
        </Accordion>
      </div>
    </>
  );
};

export default Faqs;
