import { Card, Image } from "@chakra-ui/react";

const MixRealCard = (props) => {
    return (
        <>
            <Card className="mixed_real_card" >
                <Image src={props.img} alt="..." className="mixed_real_img" />
                <div className="mixed_real_overlay">
                    <div className="overlay_txt">
                        <h5 size="md" className="text-blue ">{props.title}  </h5>
                        <p>{props.txt}</p>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default MixRealCard
