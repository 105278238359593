import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import { Autoplay } from "swiper/modules";
import Customercare from "../component/Customercare";
import RaiseaQuery from "../component/RaiseaQuery";
import Industries from "../Common/Industries";
import CounterSection from "../component/CardContain/CounterSection";
import Testimonials from "../component/CardContain/Testimonials";
import testimonialdata from "../component/Testimonials/HomeTestimonials.json";
import Slider from "../component/HomeComponents/Slider";
import HomeService from "../component/HomeComponents/HomeService";
import ItSolutionCard from "../component/HomeComponents/ItSolutionCard";

const Home = () => {
  const slide_img = [
    "/img/flatter.png",
    "/img/html.png",
    "/img/next.png",
    "/img/node.png",
    "/img/angular.png",
    "/img/google.png",
    "/img/python.png",
    "/img/php.png",
    "/img/ionic.png",
    "/img/microsoft.png",
    "/img/web-services.png",
  ];
  const comp_img = [
    "img/client-logo/@.png",
    "img/client-logo/aligarh-muslim.png",
    "img/client-logo/asianpaints.png",
    "img/client-logo/bhartiyas.png",
    "img/client-logo/bmw.png",
    "img/client-logo/cannon.png",
    "img/client-logo/capital-city.png",
    "img/client-logo/dcp.png",
    "img/client-logo/elixir.png",
    "img/client-logo/gandhi.png",
    "img/client-logo/gap.png",
    "img/client-logo/grv.png",
    "img/client-logo/hospitality.png",
    "img/client-logo/jbl.png",
    "img/client-logo/livspace.png",
    "img/client-logo/magicbricks.png",
    "img/client-logo/mangoshala.png",
    "img/client-logo/meridien.png",
    "img/client-logo/metro.png",
    "img/client-logo/modicare.png",
    "img/client-logo/nishat-hospital.png",
    "img/client-logo/ormitas.png",
    "img/client-logo/nitco.png",
    "img/client-logo/ormitas.png",
    "img/client-logo/red-bridge.png",
    "img/client-logo/sheroes.png",
    "img/client-logo/vanheusen.png",
  ];

  return (
    <>
      <div className="page_start">
        <Slider />
        <HomeService />
        <section>
          <div
            className="container-fluid dot-pattern"
            style={{ paddingLeft: "0px" }}
          >
            <div className="row mt-n1-9 pl-0 mt-5">
              <div className="col-lg-5 col-md-10 ">
                <img
                  src="img/Trending IT Solution.png"
                  alt="..."
                  className="img-fluid"
                  maxW={{ base: "100%" }}
                  objectFit="contain"
                />
              </div>
              <div className="col-lg-6 col-md-10  mt-5 pb-5 It_solution_section">
                <div className="top_title mb-3">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span>Trending IT Solutions</span>
                  </h2>
                </div>
                <p className="lead" data-aos="fade-up" data-aos-duration="600">
                  With the help of below IT solutions you can experience an
                  instant upraise in your growth. All the below mentioned
                  solutions are latest in the industry. We strive to offer the
                  most cutting edge solutions for your business.
                </p>
                <hr />
                <div className=" mt-4 ">
                  <div className="col-lg-12 ">
                    <Swiper
                      loop={true}
                      slidesPerView={1}
                      grabCursor={true}
                      spaceBetween={10}
                      autoplay={{
                        delay: 1800,
                        disableOnInteraction: false,
                      }}
                      modules={[Autoplay]}
                      className="mySwiper p-4"
                      breakpoints={{
                        768: {
                          slidesPerView: 1,
                        },

                        992: {
                          slidesPerView: 2,
                        },

                        1200: {
                          slidesPerView: 2,
                        },
                      }}
                    >
                      <SwiperSlide>
                        <ItSolutionCard
                          txt="  We offer robust solutions with modern data
                              collection, storage, processing and analysis. We
                              provide legit data governance. You can contact our team for more
                              information."
                          title=" Big Data"
                          img="/assets/it_sol/big data.png"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ItSolutionCard
                          txt="    With proper planning and forecasting take your
                              business to another level. We offer AI & MR
                              technologies that energize human intelligence."
                          title="  Artificial Intelligence"
                          img="/assets/it_sol/artificial intelligance.png"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ItSolutionCard
                          txt="Get actionable detection of visual defects and
                              anomalies with 3D modeling and mapping. We do
                              provide barcode/QR validation & verification."
                          title=" Computer Vision"
                          img="/assets/it_sol/computer vision.png"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ItSolutionCard
                          txt=" We offer IoT solution development and collect,
                              store, analyze sensored data with smart operations.
                              We do provide sensor software, and many other
                              technologies."
                          title=" Internet Of Things"
                          img="/assets/it_sol/internet of things.png"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <ItSolutionCard
                          txt="Get started with the best tracking solution which
                              has enhanced traceability, security and data
                              transitions. We do offer blockchain development,
                              contact us now."
                          title=" Block Chain "
                          img="/assets/it_sol/block chain.png"
                        />
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Industries />
        <Customercare />
        <CounterSection />
        <Testimonials
          testimonialdata={testimonialdata}
          tHead={"Our Testimonials"}
          tText={"What Our Clients Say About Us?"}
        />
        <section className="pt-1 top_technologies">
          <div className="container ">
            <div className="row mt-n1-9 mt-5 mb-5">
              <div className="col-lg-12 col-md-12 mt-1-9 ">
                <div className="top_title mb-3">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span>Top Trending Technologies</span>
                  </h2>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p
                      className="lead"
                      data-aos="fade-up"
                      data-aos-duration="600"
                    >
                      We Work On
                    </p>
                    <hr />
                  </div>
                </div>
                <br />
                <Swiper
                  // slidesPerView={4}
                  loop={true}
                  grabCursor={true}
                  spaceBetween={30}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                  }}
                  breakpoints={{
                    600: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },

                    992: {
                      slidesPerView: 2,
                    },

                    1200: {
                      slidesPerView: 5,
                    },
                  }}
                  modules={[Autoplay]}
                  className="mySwiper p-4"
                >
                  {slide_img.map((image, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <img src={image} className="technologies_img" alt="" />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </section>
        <RaiseaQuery />
        <div className="container ">
          <div className="row mt-n1-9 mt-5">
            <div className="col-md-6">
              <div className="top_title mb-3">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span>Our Clients</span>
                </h2>
              </div>
              <hr />
            </div>
            <div className="col-lg-12 ">
              <Swiper
                // slidesPerView={6}
                loop={true}
                grabCursor={true}
                spaceBetween={20}
                autoplay={{
                  delay: 1000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                breakpoints={{
                  600: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 2,
                  },

                  992: {
                    slidesPerView: 2,
                  },

                  1200: {
                    slidesPerView: 6,
                  },
                }}
                className="mySwiper p-4"
              >
                {comp_img.map((image, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <img src={image} className="client_sectionImg" alt="" />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
