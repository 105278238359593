import React from "react";
import { Link } from "react-router-dom";

const Lastpoint = () => {
  return (
    <div className="container">
      <div className="row">
        <div className=" col-md-12   mt-5  text-center ">
          <div className="top_title text-center">
            <h2 data-aos="fade-up" data-aos-duration="500">
              <span>Providing excellent IT solutions</span>
            </h2>
          </div>
          <h5
            className="After_title mb-4"
            data-aos="fade-up"
            data-aos-duration="500"
          >
            Our Mission
          </h5>
          <p
            className="lead mt-4 fw-300"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            Providing excellent IT solutions and utilizing cutting-edge
            technology to help you achieve your desired goal. We understand our
            customers and assure to deliver the perfect solutions according to
            your business objectives.
            <br />
          </p>
          {/* <div
            className="text-center mt-4 mb-4"
            // style={{ marginBottom: window.innerWidth < 768 ? "40px" : "0px" }}
          > */}
          <Link
            to="/Contact"
            className="all_btn"
            style={{ position: "relative", zIndex: 1 }}
          >
            <span>Get Started</span>
          </Link>

          {/* </div> */}
        </div>
      </div>
    </div>
  );
};
export default Lastpoint;
