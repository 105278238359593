import React from "react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import Lottie from "lottie-react";
import call from "../component/jsonfile/callus.json";
import chat from "../component/jsonfile/chatus.json";
import ticket from "../component/jsonfile/query.json";
import { Link } from "react-router-dom";
import {Card, CardHeader,CardBody,CardFooter} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const Customercare = () => {
  return (
    <section className="pt-5 customer_careSection  afterSlider_section dot-pattern">
      <div className="container-fluid ">
        <div className="row mt-5 mb-5 px-0 ">
          <div className="col-lg-1 col-md-1"></div>
          <div className="col-lg-6 col-md-12 mt-1-9  ">
            <div className="top_title text-left">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>Got Stuck? Visit Us Anytime</span>
              </h2>
            </div>
            <p
              className="lead mt-4 "
              data-aos="fade-up"
              data-aos-duration="600"
            >
              You can call us, chat or even raise a ticket for your queries, our
              technical team will contact you and will help you with the best
              solution possible.
            </p>
            <hr />
            <div className="serviceCard_here">
              <Swiper
                loop={true}
                grabCursor={true}
                spaceBetween={20}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper serviceCard_swiper"
                breakpoints={{
                  600: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },

                  992: {
                    slidesPerView: 2,
                  },

                  1200: {
                    slidesPerView: 3,
                  },
                }}
              >
                
                <SwiperSlide>
                  
                  <Card
                    className=" d-flex justify-content-center align-items-center"
                    borderTop="8px"
                    borderColor="#056894"
                    data-aos="fade-up"
                    data-aos-duration="200"
                  >
                    <CardHeader>
                      <div style={{ height: "150px", width: "150px" }}>
                        <Lottie animationData={call} />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <h5 className=" text-blue">Call Us</h5>
                      <p> For product related query call us now.</p>
                    </CardBody>
                    <CardFooter>
                      <Link to="/contact">
                        Click Here <ArrowForwardIcon />
                      </Link>
                    </CardFooter>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  
                  <Card
                    className=" d-flex justify-content-center align-items-center"
                    borderTop="8px"
                    borderColor="#056894"
                    data-aos="fade-up"
                    data-aos-duration="400"
                  >
                    <CardHeader>
                      <div style={{ height: "150px", width: "150px" }}>
                        <Lottie animationData={chat} />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <h5 className=" text-blue">Chat With Us</h5>
                      <p>Chat With Us Connect now with our engineers.</p>
                    </CardBody>
                    <CardFooter>
                      <Link to="/contact">
                        Click Here <ArrowForwardIcon />
                      </Link>
                    </CardFooter>
                  </Card>
                </SwiperSlide>
                <SwiperSlide>
                  
                  <Card
                    className=" d-flex justify-content-center align-items-center"
                    borderTop="8px"
                    borderColor="#056894"
                    data-aos="fade-up"
                    data-aos-duration="600"
                  >
                    <CardHeader>
                      <div style={{ height: "150px", width: "150px" }}>
                        <Lottie animationData={ticket} />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <h5 className=" text-blue">Ticket</h5>
                      <p> Need technical help? Raise a query now.</p>
                    </CardBody>
                    <CardFooter>
                      <Link to="/contact">
                        Click Here <ArrowForwardIcon />
                      </Link>
                    </CardFooter>
                  </Card>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className="col-lg-5 px-0 col-md-12 ">
            <img
              src="/assets/home/img-isometric-05.png"
              alt="..."
              className="img-fluid"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Customercare;
