import { Card, CardHeader, CardBody, Image } from "@chakra-ui/react";

const CommonCard = (props) => {
  return (
    <>
      <Card
        borderTop="8px"
        borderColor="#056894"
        data-aos="fade-up"
        data-aos-delay={props.delay}
      >
        <CardHeader className=" d-flex justify-content-center align-items-center">
          <Image
            src={`/assets/SDIcon/${props.img}`}
            alt="....."
            maxWidth="200px"
            maxHeight="400px"
          />
        </CardHeader>
        <CardBody>
          <h5 size="md" className="text-blue text-center">
            {props.title}
          </h5>
          <p>{props.txt}</p>
        </CardBody>
      </Card>
    </>
  );
};
export default CommonCard;
