import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { AddIcon, StarIcon } from "@chakra-ui/icons";
const Timeline = ({ timelinedata }) => {
  return (
    <>
      <VerticalTimeline>
        {timelinedata.map((data, index) => (
          <VerticalTimelineElement
            contentStyle={{
              borderBottom: "17px solid #03b5be",
              borderColor: "#03b5be",
              boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.9)",
            }}
            className="vertical-timeline-element--work"
            iconStyle={{ background: "#03b5be", color: "#fff" }}
            //    contentArrowStyle={{ borderRight: "7px solid  #03b5be" }}
            icon={<StarIcon />}
          >
            {data.image && (
              <img
                src={data.image}
                alt="..."
                className="vertical-timeline-element-image"
                style={{ display: "block", margin: "auto" }}
              />
            )}
            <h3 className="vertical-timeline-element-title text-blue">
              {data.title}
            </h3>
            <p>{data.desc}</p>
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </>
  );
};
export default Timeline;
