import React from "react";
import { Card, CardBody } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const Testimonials = ({ testimonialdata, tHead, tText }) => {
  return (
    <>
      <section className="Testimonials_section ">
        <div className="Testimonials_sectionLayer mb-5 rounded-lg mt-5 mb-5 ">
          <div className="container ">
            <div className=" col-md-12 ">
              <div className=" text-left ">
                <h2
                  data-aos="fade-up"
                  data-aos-duration="500"
                  className="text-white"
                >
                  <br />
                  <span>{tHead}</span>
                </h2>
              </div>
              <p
                className="lead mt-4 testimonial_txt text-white"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                {tText}
              </p>
              <hr />
              <br />
              <div className="swiper_section">
                <Swiper
                  loop={true}
                  grabCursor={true}
                  spaceBetween={10}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  className="mySwiper"
                  breakpoints={{
                    768: {
                      slidesPerView: 1,
                    },

                    992: {
                      slidesPerView: 2,
                    },

                    1200: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {testimonialdata.map((data, index) => (
                    <SwiperSlide key={index}>
                      <Card>
                        <CardBody>
                          {/* <div className="testimonial_user_img"> </div> */}
                          <div className="testimonial_qmark"> &#10077;</div>
                          {/* <div className="testimonial_pattern"></div> */}
                          <div className="testimonialreview_box bg_testicard ">
                            <blockquote>
                              <p className="text-center text-white">
                                {data.testimonial}
                              </p>
                            </blockquote>

                            <div className="rating">
                              <ul className="list-unstyled d-flex justify-content-center align-items-center">
                                {[...Array(data.rating)].map((_, index) => (
                                  <li key={index} className="text-warning ">
                                    <i className="fas fa-star text-center" />
                                  </li>
                                ))}
                              </ul>
                            </div>

                            <h5 className="USEr_Name text-dark text-center mt-2">
                              {data.author}
                            </h5>
                          </div>
                        </CardBody>
                      </Card>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
