import React from "react";
import PageTitle from "../../Common/PageTitle";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  List,
  ListItem,
  ListIcon,
  Stack,
  Box,
} from "@chakra-ui/react";
import { CheckIcon, MinusIcon, AddIcon } from "@chakra-ui/icons";
import feature from "./Feature/fantasyfeature.json";
import applic from "./Feature/fantasyapplication.json";
import Featuredata from "../../component/Featuredata";
import Testimonials from "../../component/CardContain/Testimonials";
import testimonialdata from "../../component/Testimonials/FantasyADTestimonials.json";
import Lastpoint from "../../component/Lastpoint";

const FantasyMAD = () => {
  return (
    <>
      <div className="slidersection_here ">
        <div className="pageheader bg-blue"></div>
        <PageTitle
          prevtitle="Products"
          title="Fantasy App Development"
          desc="Experience the best Fantasy apps development in India that you deserve. Show your command and capability by building your team in the match and playing small contests with the best fantasy app development in India."
          bimg="/img/banner/fad.png"
          page="Fantasy App Development"
        />
        <div className="container ">
          <div className=" col-md-12  text-center  mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Features That The</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Fantasy App Development Company Provide
            </h5>
            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              We have some offers for you by which you can experience the best
              fantasy cricket app in India to earn big and win points.
              <br />
              Some of the offers that the fantasy app development company in
              India gave are as follows
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid spacing={4} columns={{ base: 1, sm: 1, md: 1, lg: 1 }}>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/gif/word-class security.png"
                    alt="..."
                  />
                </div>
                <Stack>
                  <CardBody>
                    <h2 className=" text-darkblue   mb-2 font-itelic ">
                      World-Class Security
                    </h2>
                    <p className="lead mt-4 fw-300">
                      A fantasy app development company in India provides you
                      world-class security.
                    </p>
                    <h6 className=" text-darkblue   mb-2 font-itelic ">
                      Examples
                    </h6>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Guarantee With No Fraud
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" /> Completely
                        safe and secure
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Provide 100% identity & data Privacy
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <Stack>
                  <CardBody>
                    <h2 className=" text-darkblue   mb-2 font-itelic ">
                      Ground-Breaking Platform
                    </h2>
                    <p className="lead mt-4 fw-300">
                      A fantasy app development company in India gives you the
                      best platform to earn and play.
                    </p>
                    <h6 className=" text-darkblue   mb-2 font-itelic ">
                      Examples
                    </h6>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        0% commission- tables in Rummy
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" /> 2 dice Ludo,
                        Missions
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Play the Second inning and live Fantasy
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/gif/ground-breaking-platform.png"
                    alt="..."
                  />
                </div>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/gif/do-not-playing.png"
                    alt="..."
                  />
                </div>
                <Stack>
                  <CardBody>
                    <h2 className=" text-darkblue  mb-2 font-itelic ">
                      Do not Stop Playing
                    </h2>
                    <p className="lead mt-4 fw-300">
                      Do not stop playing games, let's start playing games
                      according to your interest in a fantasy app development
                      company in India.
                    </p>
                    <h6 className=" text-darkblue   mb-2 font-itelic ">
                      Examples
                    </h6>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        More than 15+ Games
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" /> Free cash
                        Tournament
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Pl Practice games, free games, 100% bonus games,
                        gadgets, and many more
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <Stack>
                  <CardBody>
                    <h2 className=" text-darkblue   mb-2 font-itelic ">
                      Profitable Gaming Platform
                    </h2>
                    <p className="lead mt-4 fw-300">
                      A fantasy app development company in India offers you to
                      earn more and create points according to your interest.
                    </p>
                    <h6 className=" text-darkblue   mb-2 font-itelic ">
                      Examples
                    </h6>
                    <List spacing={3}>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Unlimited Earning
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" /> Instantly
                        withdraw your Winnings
                      </ListItem>
                      <ListItem>
                        <ListIcon as={CheckIcon} color="#056894" />
                        Unlimited earning / friends via referrals and welcome
                        appropriate bonus
                      </ListItem>
                    </List>
                  </CardBody>
                </Stack>
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/gif/profitable-gaming.png"
                    alt="..."
                  />
                </div>
              </Card>
            </SimpleGrid>
          </div>
        </div>

        <div className="softwareDevpl_bgImg">
          <div
            className="onsoftwareDevpl_BGlayer mb-5 rounded-lg mt-5 mb-5"
            style={{
              padding: "20px",
            }}
          >
            <section className="mb-10">
              <div className="container ">
                <div className="col-md-12   mt-5 mb-5 ">
                  <div className=" text-color text-center">
                    <h2 data-aos="fade-up" data-aos-duration="500">
                      <span> Process Of Working</span>
                    </h2>
                  </div>
                  <h5
                    className="After_title mb-4 text-white"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    Fantasy App Development
                  </h5>

                  <p
                    className="lead mt-4 fw-300 text-white"
                    data-aos="fade-up"
                    data-aos-duration="600"
                  >
                    Join the match with the help of a fantasy app development
                    company in just 3 simple steps and these are the following
                    Steps
                  </p>
                  <hr />

                  <SimpleGrid
                    columns={{ base: 1, sm: 1, md: 1, lg: 3 }}
                    className="stepProcess_section"
                    spacing={6}
                  >
                    <Card data-aos="fade-right" data-aos-duration="200">
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <img src="/img/ican/select-match.png" alt="..." />
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-blue text-center">
                          Select Match
                        </h5>
                        <p>
                          Select the match according to your interest with the
                          help of a fantasy app development Company.
                        </p>
                      </CardBody>
                    </Card>
                    <Card data-aos="fade-right" data-aos-duration="400">
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <img src="/img/ican/join-contest.png" alt="...." />
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-blue text-center">
                          Join Contest
                        </h5>
                        <p>
                          The second step is to join the contest using a fantasy
                          app development Company.
                        </p>
                      </CardBody>
                    </Card>
                    <Card data-aos="fade-right" data-aos-duration="600">
                      <CardHeader className="d-flex justify-content-center align-items-center">
                        <img src="/img/ican/win-point.png" alt="..." />
                      </CardHeader>
                      <CardBody>
                        <h5 size="md" className="text-blue text-center">
                          Create Team, Win Points
                        </h5>
                        <p>
                          The third step is to create a team and play then win
                          points using a fantasy app development company and
                          earn unlimited.
                        </p>
                      </CardBody>
                    </Card>
                  </SimpleGrid>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="container ">
          <div className=" col-md-12  text-center  mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Fantasy App Development</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Company Also Offers
            </h5>

            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              The fantasy app development company also have some more features
              for you by which you will earn unlimited with full of joy Fantasy
              cricket app development in Bangalore has the following features
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid
              // className="stepProcess_section"
              spacing={4}
              //   templateColumns="repeat(auto-fill, minmax(255px, 1fr))"
              columns={{ base: 1, sm: 1, md: 2, lg: 4 }}
            >
              <Card
                data-aos="fade-up"
                data-aos-duration="500"
                className=" d-flex justify-content-center align-items-center"
                style={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.9)" }}
              >
                <CardHeader>
                  <Image src="/img/ican/instant-withdraw.png" alt="....." />
                </CardHeader>
                <CardBody>
                  <h5 size="md" className="text-blue text-center">
                    Instant withdraw
                  </h5>
                  <p>
                    A FAD company in India offers you multiple withdrawn options
                    in just a few seconds and fantasy cricket app development in
                    Bangalore is 100% safe and secure.
                  </p>
                </CardBody>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="500"
                className=" d-flex justify-content-center align-items-center"
                style={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.9)" }}
              >
                <CardHeader>
                  <Image src="/img/ican/gadget.png" alt="....." />
                </CardHeader>
                <CardBody>
                  <h5 size="md" className="text-blue text-center">
                    Gadget Leagues
                  </h5>
                  <p>
                    Fantasy cricket app development in Bangalore offers you to
                    win exciting gadgets by participating in gadget leagues.
                  </p>
                </CardBody>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="500"
                className=" d-flex justify-content-center align-items-center"
                style={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.9)" }}
              >
                <CardHeader>
                  <Image src="/img/ican/game.png" alt="....." />
                </CardHeader>
                <CardBody>
                  <h5 size="md" className="text-blue text-center">
                    Free Games
                  </h5>
                  <p>
                    Whatever your idea is, we will provide you with the solution
                    for it. We explore, then make the structure as per your
                    functional needs.
                  </p>
                </CardBody>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="500"
                className=" d-flex justify-content-center align-items-center"
                style={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.9)" }}
              >
                <CardHeader>
                  <Image src="/img/ican/win.png" alt="....." />
                </CardHeader>
                <CardBody>
                  <h5 size="md" className="text-blue text-center">
                    100% Winning
                  </h5>
                  <p>
                    Play games with a 0% platform fee on many high voltage
                    matches and earn the full Amount.
                  </p>
                </CardBody>
              </Card>
            </SimpleGrid>
          </div>
        </div>

        <div className="softwareDevpl_bgImg">
          <div
            className="mb-5 rounded-lg mt-5 mb-5 onsoftwareDevpl_BGlayer"
            style={{
              // backgroundImage: "url('/img/bgn-testimonials.png')",
              // backgroundSize: "cover",
              // backgroundPosition: "center",
              // backgroundRepeat: "no-repeat",
              padding: "20px",
              // backgroundColor: " #056894",
            }}
          >
            <section className="mb-10">
              <div className="container ">
                <div className="col-md-12 mt-5 mb-5 ">
                  <div className="col-md-12  mt-5 mb-5 ">
                    <div className="text-color text-center">
                      <h2 data-aos="fade-up" data-aos-duration="500">
                        <span> Fantasy App Development</span>
                      </h2>
                    </div>
                    <h5
                      className="After_title mb-4 text-white"
                      data-aos="fade-up"
                      data-aos-duration="500"
                    >
                      Company In India’s Top Games
                    </h5>

                    <p
                      className="lead mt-4  fw-300 text-white text-center"
                      data-aos="fade-up"
                      data-aos-duration="600"
                    >
                      A fantasy cricket app development in Bangalore have the
                      following top games and these are the following
                    </p>

                    <SimpleGrid
                      //  spacing={4}
                      // templateColumns="repeat(auto-fill, minmax(500px, 1fr))"
                      columns={{ base: 1, sm: 1, md: 1, lg: 3 }}
                    >
                      <card>
                        <List spacing={3}>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Fantasy
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Rummy
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Ludo
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Carrom
                          </ListItem>
                        </List>
                      </card>
                      <card>
                        <List spacing={3}>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />8 ball
                            Pool
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Run boy Run
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Sheep fight
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Cricket
                          </ListItem>
                        </List>
                      </card>
                      <card>
                        <List spacing={3}>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Fruit Slice
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Bricks
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            Baseball
                          </ListItem>
                          <ListItem>
                            <ListIcon as={CheckIcon} color="#056894" />
                            basketball
                          </ListItem>
                        </List>
                      </card>
                    </SimpleGrid>

                    <div className="text-center mt-4">
                      <Link to="/Contact" className="all_btn">
                        <span> Get Started</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        <div className="container m">
          <div className=" col-md-12  text-center  mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Fantasy App Development Company</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Have Some Advanced Features
            </h5>

            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Developers can handle all-determining features for developing
              modern and robust mobile apps for different platforms. <br />
              Some of the advanced features that a fantasy app development
              company in India have are
            </p>
          </div>
          <div className="col-md-12 mt-5">
            <SimpleGrid
              spacing={4}
              templateColumns="repeat(auto-fill, minmax(315px, 1fr))"
            >
              <Featuredata feature={feature} />
            </SimpleGrid>
          </div>
          <div className=" col-md-12 text-center  mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Fantasy App Development</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              Company’s Services
            </h5>

            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Some of the following services are there which Fantasy App
              Development Company provides you
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid spacing={4} columns={{ base: 1, sm: 1, md: 1, lg: 1 }}>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/ican/cricket.png"
                    alt="..."
                  />
                </div>
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-darkblue">
                      Fantasy Cricket App Development
                    </h5>
                    <p py="2">
                      We develop highly engaging fantasy cricket apps and white
                      label fantasy cricket software for popular leagues for
                      your fantasy cricket app development.
                    </p>
                  </CardBody>
                </Stack>
              </Card>

              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-darkblue">
                      Fantasy Football App Development
                    </h5>
                    <p py="2">
                      70 % of sports fans of other countries prefer fantasy
                      football over other games. Our fantasy football apps cater
                      to the increase in football fans for your fantasy app
                      development Company.
                    </p>
                  </CardBody>
                </Stack>
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/ican/football.png"
                    alt="..."
                  />
                </div>
              </Card>

              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/ican/kabaddi.png"
                    alt="..."
                  />
                </div>
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-darkblue">
                      Fantasy Kabaddi App Development
                    </h5>
                    <p py="2">
                      We have the knowledge in creating feature-rich fantasy
                      kabaddi apps for generating income with a wide customer
                      base.
                    </p>
                  </CardBody>
                </Stack>
              </Card>
              <Card
                data-aos="fade-up"
                data-aos-duration="300"
                className="android_app_develp"
                direction={{ base: "column", lg: "row" }}
                overflow="hidden"
                variant="outline"
                maxHeight={1500}
              >
                <Stack>
                  <CardBody>
                    <h5 size="md" className="text-darkblue">
                      Fantasy Multi-Sports App Development
                    </h5>
                    <p py="2">
                      Our expert developers generate scalable, robust fantasy
                      multi-sports app development seamlessly designed to offer
                      best-in-class user Experiences
                    </p>
                  </CardBody>
                </Stack>
                <div className=" d-flex justify-content-center align-items-center">
                  <Image
                    objectFit="contain"
                    maxW={{ base: "100%", sm: "350px" }}
                    src="/img/ican/sports.png"
                    alt="..."
                  />
                </div>
              </Card>
            </SimpleGrid>
          </div>

          <div className=" col-md-12  text-center  mt-5">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span> Why OneBigBit Technologies</span>
              </h2>
            </div>
            <h5
              className="After_title mb-4"
              data-aos="fade-up"
              data-aos-duration="500"
            >
              For Fantasy Sports Applications.
            </h5>

            <p
              className="lead mt-4 fw-300"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Here are the reasons for choosing us for your fantasy cricket app
              development price in India To get Immersive, engaging, and
              inviting digital experiences.
            </p>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <SimpleGrid spacing={4} columns={{ base: 1, sm: 1, md: 2, lg: 4 }}>
              {applic &&
                applic.map((data, index) => (
                  <Card
                    data-aos="fade-up"
                    data-aos-duration="500"
                    className=" d-flex justify-content-center align-items-center"
                    style={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.9)" }}
                  >
                    <CardHeader>
                      <Image src={data.image} alt="....." />
                    </CardHeader>
                    <CardBody>
                      <h5 size="md" className="text-blue text-center">
                        {data.title}
                      </h5>
                      <p>{data.desc}</p>
                    </CardBody>
                  </Card>
                ))}
            </SimpleGrid>
          </div>
        </div>
        <div className="softwareDevpl_bgImg mt-5">
          <div
            className=" bg-blue mb-5 rounded-lg mt-5 mb-5 onsoftwareDevpl_BGlayer"
            style={{
              padding: "20px",
            }}
          >
            <section className="mb-10">
              <div className="container ">
                <div className="col-md-12   mt-5 mb-5 ">
                  <div className="text-color text-center">
                    <h2 data-aos="fade-up" data-aos-duration="500">
                      <span>Advantages Of Choosing Us</span>
                    </h2>
                  </div>

                  <p
                    className="lead mt-4 fw-300 text-white"
                    data-aos="fade-up"
                    data-aos-duration="600"
                  >
                    Here we ensure that fantasy app development companies will
                    give you the best facilities with the best features for your
                    applications. <br />
                    Some of the advantages of choosing fantasy cricket app
                    development in Bangalore are.
                  </p>

                  <List spacing={3}>
                    <ListItem data-aos="fade-up" data-aos-duration="100">
                      <ListIcon as={CheckIcon} color="#056894" />A fantasy app
                      development company in India helps in building long-term
                      fruitful relationships with your users.
                    </ListItem>
                    <ListItem data-aos="fade-up" data-aos-duration="200">
                      <ListIcon as={CheckIcon} color="#056894" />A fantasy app
                      development company in India bring Transparency.
                    </ListItem>
                    <ListItem data-aos="fade-up" data-aos-duration="300">
                      <ListIcon as={CheckIcon} color="#056894" />A fantasy app
                      development company in India helps in improving the
                      participation of the Customers.
                    </ListItem>
                    <ListItem>
                      <ListIcon
                        as={CheckIcon}
                        color="#056894"
                        data-aos="fade-up"
                        data-aos-duration="400"
                      />
                      A fantasy app development company in India helps in easy
                      documentation regarding a Customer.
                    </ListItem>
                    <ListItem data-aos="fade-up" data-aos-duration="500">
                      <ListIcon as={CheckIcon} color="#056894" />A fantasy app
                      development company in India helps in connecting people
                      via an online Community.
                    </ListItem>

                    <ListItem data-aos="fade-up" data-aos-duration="600">
                      <ListIcon as={CheckIcon} color="#056894" />
                      Boost your revenue by driving traffic to the site with the
                      help of fantasy app development company in India.
                    </ListItem>

                    <ListItem data-aos="fade-up" data-aos-duration="700">
                      <ListIcon as={CheckIcon} color="#056894" />A fantasy app
                      development company in India will help ineffective
                      management of the customer Databases.
                    </ListItem>
                  </List>
                  {/* </CardBody>
                </Card> */}
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="container">
          <div className=" col-md-12   mt-5 mb-5 text-center ">
            <div className="top_title text-center">
              <h2 data-aos="fade-up" data-aos-duration="500">
                <span>FAQ's For</span>
              </h2>
            </div>
            <h5
              className="After_title mb-2"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Fantasy App Development
            </h5>
          </div>
          <div className="col-md-12   mt-5 mb-5 ">
            <Accordion allowToggle>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          How much does fantasy cricket app development Cost?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      The cost of fantasy cricket app development depends on the
                      following terms because it will totally depend on your
                      need and requirement on which we will work accordingly and
                      these are
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Number of platforms (Android, iOS, or Both)
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Features you plan to integrate
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Third-party integration
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Complexities involved
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Hourly rate of fantasy cricket app developers
                        </ListItem>

                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Development team size
                        </ListItem>
                      </List>
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Why should I choose onebigbit for fantasy sports app
                          development?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      The reasons for choosing us for your fantasy cricket app
                      development price in India are to get Immersive, engaging,
                      and inviting digital experiences and also have robust
                      security, available 24*7 For our customers to support
                      them, we keep ourselves motivated to give our customers
                      complete transparency for your fantasy cricket app
                      development cost.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          What are the different fantasy sports app development
                          services you offer ?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      We offer different types of fantasy sports app development
                      services like
                      <List spacing={3}>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Fantasy cricket app development
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Fantasy Kabaddi App Development
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Fantasy Football App Development
                        </ListItem>
                        <ListItem>
                          <ListIcon as={CheckIcon} color="#056894" />
                          Fantasy Multi-Sports App Development
                        </ListItem>
                      </List>
                      These are the services we offer for fantasy sports app
                      development.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Do you develop a fantasy sports app for kabaddi and
                          bricks?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Obviously Yes, we develop a fantasy sports app for all
                      kinds of games including cricket, kabaddi, chess, ludo,
                      bricks, run boy run, and many more for daily and
                      season-long fantasy sports Web
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
              <AccordionItem data-aos="fade-up" data-aos-duration="500">
                {({ isExpanded }) => (
                  <>
                    <h2
                      style={{
                        backgroundColor: "#f0f0f0",
                        border: "1px solid  #056894",
                        borderRadius: "5px",
                      }}
                    >
                      <AccordionButton
                        _expanded={{
                          bg: " #056894",
                          color: "white",
                          border: "1px solid  lightgray",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Box as="span" py={2} flex="1" textAlign="left">
                          Do you provide SEO and digital marketing services for
                          fantasy app development?
                        </Box>
                        {isExpanded ? (
                          <MinusIcon fontSize="12px" />
                        ) : (
                          <AddIcon fontSize="12px" />
                        )}
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      borderRadius="5px"
                      mb={3}
                      borderBottom="1px solid lightgray"
                      borderRight="1px solid lightgray"
                      borderLeft="1px solid lightgray"
                      pb={4}
                    >
                      Yes, we will provide you with the best SEO and digital
                      marketing services for fantasy app development just to
                      grow your fantasy apps revenue and downloads and also
                      worked on improving the engagement of the users on the
                      game apps.
                    </AccordionPanel>
                  </>
                )}
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <Testimonials
          testimonialdata={testimonialdata}
          tHead={
            <span>
              See Why <br /> Our Customers Loved Us
            </span>
          }
          tText={
            "Some of our customers have connected with us and enjoy our services for their fantasy sports applications and their feedback are the following"
          }
        />
        <div className="container">
          <Lastpoint />
        </div>
      </div>
    </>
  );
};
export default FantasyMAD;
