import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Lottie from "lottie-react";
import sd from "../jsonfile/sd.json";
import id from "../jsonfile/id.json";
import vr from "../jsonfile/vr.json";
import fc from "../jsonfile/fc.json";
import { Autoplay, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";

const Slider = () => {
  return (
    <>
      <section className="slidersection_here gradientbg_blue ">
        <div className="container justify-content-center p-center pt-5 ">
          <div className="row justify-content-center p-center mt-2 pb-3 mb-3">
            <div className="col-lg-12 col-md-12 ">
              <div className="position-relative">
                <Swiper
                  loop={true}
                  grabCursor={true}
                  spaceBetween={30}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Navigation]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="row text_container">
                      <div className="col-lg-6 col-md-12 mt-1-9 ">
                        <h1 className="sm-title animate__animated animate__fadeInUp">
                          Powered by Technology
                          <span className="text-light"> Driven</span> by
                          Intellects
                        </h1>
                        <p className="display-17 display-sm-11 display-md-9 display-lg-8 text-white display-xl-4 mb-10 title animate__animated animate__fadeInUp ">
                          We offer the best software development solutions and
                          you can get your custom build software that will help
                          you maximize your business goals.
                        </p>
                        <Link
                          to="/service/softwareDevelopment"
                          className="learnMore_btn   shadow-dark  me-2 my-1 my-sm-0 animate__animated animate__fadeInDown "
                        >
                          <span> Explore More</span>
                        </Link>

                        <Link
                          to="/about"
                          className=" butn  shadow-dark  ml-3 my-1 my-sm-0 animate__animated animate__fadeInDown "
                        >
                          Learn More
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-12  animate__animated animate__fadeInRight">
                        <Lottie animationData={vr} />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="row text_container">
                      <div className="col-lg-6 col-md-12 mt-1-9 ">
                        <h1 className="sm-title animate__animated animate__fadeInUp">
                          The Integration of Information
                          <span className="text-light"> Design </span> &
                          Technology
                        </h1>
                        <p className="display-17 display-sm-11 display-md-9 display-lg-8 text-white display-xl-4 mb-10 title animate__animated animate__fadeInDown">
                          Gift your business with the most optimized mobile
                          application that will attract more audience to you and
                          help you capture new milestones.
                        </p>
                        <Link
                          to="/service/appDevelopment"
                          className="learnMore_btn   shadow-dark  me-2 my-1 my-sm-0 animate__animated animate__fadeInDown"
                        >
                          <span> Explore More</span>
                        </Link>

                        <Link
                          to="/about"
                          className=" butn  shadow-dark  ml-3 my-1 my-sm-0 animate__animated animate__fadeInDown"
                        >
                          Learn More
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-12  animate__animated animate__fadeInRight">
                        <Lottie animationData={id} />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="row text_container">
                      <div className="col-lg-6 col-md-12 mt-1-9 ">
                        <h1 className="sm-title animate__animated animate__fadeInUp">
                          Empowering The Digital
                          <span className="text-light"> Generation </span>
                        </h1>
                        <p className="display-17 display-sm-11 display-md-9 display-lg-8 text-white display-xl-4 mb-10 title animate__animated animate__fadeInDown">
                          Mixed Reality, Augmented Reality, Virtual Reality &
                          many more get started with any of these futuristic
                          technologies and stand one step ahead of your
                          competitors.
                        </p>
                        <Link
                          to="/service/mixedReality"
                          className="learnMore_btn   shadow-dark  me-2 my-1 my-sm-0 animate__animated animate__fadeInDown"
                        >
                          <span> Explore More</span>
                        </Link>

                        <Link
                          to="/about"
                          className=" butn  shadow-dark  ml-3 my-1 my-sm-0 animate__animated animate__fadeInDown"
                        >
                          Learn More
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-12  animate__animated animate__fadeInRight">
                        <Lottie animationData={sd} />
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="row text_container">
                      <div className="col-lg-6 col-md-12 mt-1-9 ">
                        <h1 className="sm-title animate__animated animate__fadeInUp">
                          Get Free Consultation
                        </h1>
                        <p className="display-17 display-sm-11 display-md-9 display-lg-8 text-white display-xl-4 mb-5 title animate__animated animate__fadeInDown">
                          Get connected with our technicians and tell them about
                          the solution you need. Our dedicated team of experts
                          are active round the clock to assist you.
                        </p>
                        <Link
                          to="/contact"
                          className="butn shadow-dark animate__animated animate__fadeInDown"
                        >
                          <span> Connect Now</span>
                        </Link>
                      </div>
                      <div className="col-lg-6 col-md-12 animate__animated animate__fadeInRight">
                        <Lottie animationData={fc} />
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Slider;
