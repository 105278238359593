import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { Nav as ReactNav } from "react-bootstrap";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [serviceClicked, setServiceClicked] = useState(false);
  const [webLogo, setWebLogo] = useState("main_logo.png");
  const [navbarBackground, setNavbarBackground] = useState("transparent_bg");
  const toggleOffcanvas = () => {
    setShowOffcanvas((prev) => !prev);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const handleItemClick = () => {
    setIsOpen(false);
  };
  const toggleServiceClicked = () => {
    setServiceClicked(!serviceClicked);
  };
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    upper();
  }, [location.pathname]);

  const upper = () => {
    window.scrollTo(0, 0);
  }
  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    <a
      className="nav-link navLink text-white serviceAnchortxt"
      href="#!"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
        toggleServiceClicked();
      }}
    >
      <i className="fa fa-cog"></i> Services
      {serviceClicked ? (
        <ChevronUpIcon className="iconMob" />
      ) : (
        <ChevronDownIcon className="iconMob" />
      )}
    </a>
  ));
  const closeOffcanvas = () => {
    setShowOffcanvas(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 100;
      if (scrollPosition > threshold) {
        setNavbarBackground("redbg");
        setWebLogo("Logo.png");
      } else {
        setNavbarBackground("transparent_bg");
        setWebLogo("main_logo.png");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Navbar
        key="lg"
        expand="lg"
        className={`sticky-top  ${navbarBackground}`}
        // variant="dark"
        variant={navbarBackground === "redbg" ? "light" : "dark"}
      >
        <Container>
          <Navbar.Brand>
            <Link to="/">
              <img src={`/img/${webLogo}`} alt="Logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar-expand-lg"
            onClick={toggleOffcanvas}
          />
          <Navbar.Offcanvas
            show={showOffcanvas}
            onHide={closeOffcanvas}
            className="bg-nav m-0 text-bg-blue text-center"
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="start"
          >
            <Offcanvas.Header closeButton closeVariant="white">
              <Offcanvas.Title
                className="mx-auto"
                id="offcanvasNavbarLabel-expand-lg"
              >
                <Link to="/">
                  <img src="/img/main_logo.png" alt="Logo" />
                </Link>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <ReactNav className="justify-content-center flex-grow-1">
                <ReactNav.Link
                  className="navLink text-white"
                  onClick={closeOffcanvas}>
                  <Link to="/">
                    <i className="fa fa-home"></i> Home
                  </Link>
                </ReactNav.Link>
                <hr className="hrLine mx-auto" />
                <Dropdown className={isOpen === true ? `deskTop_Dropdown_menu` : 'deskTop_Dropdown_menu_removecss'} toggle={toggleDropdown} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                  <DropdownToggle className="drop_down_toggle">
                    <i className="fa fa-cog"></i> Services {isOpen ? <i className="fa fa-angle-up"></i> : <i className="fa fa-angle-down"></i>}
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link to="/service/softwareDevelopment" >
                      <DropdownItem onClick={handleItemClick}>
                        Software Development
                      </DropdownItem></Link>
                    <Link to="/service/appDevelopment" >
                      <DropdownItem onClick={handleItemClick}>
                        App Development
                      </DropdownItem>
                    </Link>
                    <Link to="/service/mixedReality">
                      <DropdownItem onClick={handleItemClick}>
                        Mixed Reality
                      </DropdownItem>
                    </Link>
                    <Link to="/service/digitalMarketing"  >
                      <DropdownItem onClick={handleItemClick}>
                        Digital Marketing
                      </DropdownItem></Link>
                  </DropdownMenu>
                </Dropdown>
                {/* <Dropdown className="mobil_Dropdown_menu" autoClose="outside">
                  <Dropdown.Toggle
                    as={CustomToggle}
                    className="navLink nav-link text-light"
                    id="dropdown-autoclose-outside"
                  ></Dropdown.Toggle>

                  <Dropdown.Menu className="bgDropDown   border-0 pt-0 pb-0" >
                    <hr className="hrLine mx-auto" />
                    <Dropdown.Item
                      className="  bText py-2" to="/Service/SoftwareDevelopment"
                      onClick={closeOffcanvas}
                    >
                      <span onClick={upper} >
                        Software Development
                      </span>
                    </Dropdown.Item>
                    <hr className="hrLine mx-auto" />
                    <Dropdown.Item
                      className="  bText py-2"
                      onClick={closeOffcanvas}
                    >
                      <Link to="/Service/AppDevelopment" onClick={upper} > App Development</Link>
                    </Dropdown.Item>
                    <hr className="hrLine mx-auto" />
                    <Dropdown.Item
                      className="  bText py-2"
                      onClick={closeOffcanvas}
                    >
                      <Link to="/Service/MixedReality" onClick={upper} > Mixed Reality</Link>
                    </Dropdown.Item>
                    <hr className="hrLine mx-auto" />
                    <Dropdown.Item
                      className="  bText py-2"
                      onClick={closeOffcanvas}
                    >
                      <Link to="/Service/DigitalMarketing" onClick={upper} >
                        Digital Marketing
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>  */}

                <hr className="mx-auto hrLine" />
                <ReactNav.Link
                  className="navLink text-white"
                  onClick={closeOffcanvas}
                >
                  <Link to="/about">
                    <i className="fa fa-info-circle"></i> About Us
                  </Link>
                </ReactNav.Link>
                <hr className="hrLine mx-auto" />
                <ReactNav.Link
                  className="navLink text-white"
                  onClick={closeOffcanvas}
                >
                  <Link to="/approach">
                    <i className="fa fa-refresh"></i> Approach
                  </Link>
                </ReactNav.Link>
                <hr className="hrLine mx-auto" />
                <ReactNav.Link
                  className="navLink text-white"
                  onClick={closeOffcanvas}
                >
                  <Link to="/products">
                    <i className="fa fa-th-large"></i> Products
                  </Link>
                </ReactNav.Link>
                <hr className="hrLine mx-auto" />
                <ReactNav.Link
                  className="navLink text-white"
                  onClick={closeOffcanvas}
                >
                  <Link to="/contact">
                    <i className="fa fa-user"></i> Contact Us
                  </Link>
                </ReactNav.Link>
              </ReactNav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};
export default Header;
