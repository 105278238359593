import React, { useState, useRef } from "react";
import { Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";

const RaiseaQuery = () => {
  const refcontainer = useRef(null);
  const [Data, setData] = useState({});
  const handleInput = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // console.log(Data, "data");
  const submitdata = async (e, v) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://api-obb.onebigbit.com/visitors/contact_obb",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(Data),
        }
      );
      const responseData = await response.json();
      console.log("Response Body:", responseData);
      if (response.status === 200) {
        console.log(response.status, response.ok);
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: "success",
          title: responseData.message,
        });
      } else {
        console.log(response.status);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
      refcontainer.current.reset();
    } catch (error) {
      console.log(error, "something went wrong");
    }
  };
  return (
    <section className="raiseQuery_section dot-pattern">
      <Container fluid={true} style={{ paddingLeft: "0px" }}>
        <Row className="  px-0 ">
          <Col lg={6} md={12} className="mt-5 ">
            <img src="/assets/home/img-newsletter.png" alt="..." />
          </Col>
          <Col lg={6} md={12} className="mt-5  px-0 ">
            <div className="mt-5 pt-4">
              <div className="container">
                <div className="top_title ml-2">
                  <h2 data-aos="fade-up" data-aos-duration="500">
                    <span> Raise A Query Here</span>
                  </h2>
                </div>
                <form
                  className="contact quform"
                  onSubmit={submitdata}
                  method="post"
                  ref={refcontainer}
                >
                  <div className="quform-elements">
                    <div className="row">
                      <div className="col-md-10">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="name">
                            Your Name
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="name"
                              type="text"
                              name="name"
                              required
                              onChange={handleInput}
                              placeholder="Your name here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="email">
                            Your Email
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="email"
                              type="email"
                              name="email"
                              required
                              onChange={handleInput}
                              placeholder="Your email here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="mobile">
                            Your mobile
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <input
                              className="form-control"
                              id="mobile"
                              type="number"
                              name="mobile"
                              required
                              onChange={handleInput}
                              placeholder="Your mobile here"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10">
                        <div className="quform-element form-group mb-3">
                          <label htmlFor="message">
                            Message
                            <span className="quform-required">*</span>
                          </label>
                          <div className="quform-input">
                            <textarea
                              className="form-control h-100"
                              id="message"
                              name="message"
                              required
                              onChange={handleInput}
                              rows={6}
                              placeholder="Tell us a few words"
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-10 mt-2">
                        <div className="quform-submit-inner">
                          <button type="submit" className="all_btn topindex">
                            <span>Send Now</span>
                          </button>
                        </div>
                        <div className="quform-loading-wrap text-start">
                          <span className="quform-loading" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default RaiseaQuery;
