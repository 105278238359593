import React, { useEffect, useRef, useState } from 'react';
import AllRoutes from "./Routes/AllRoutes";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import AOS from 'aos';
import 'aos/dist/aos.css';
import PreLoader from './Common/PreLoader';

gsap.registerPlugin(ScrollTrigger);

function App() {
  const elementRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);
  useEffect(() => {
    gsap.from(elementRef.current, {
      opacity: 0,
      y: 100,
      duration: 1,
      scrollTrigger: {
        trigger: elementRef.current,
        start: 'top 80%',
        end: 'bottom 20%',
        toggleActions: 'play none none reverse',
      },
    });
  }, []);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <div className="app">
        {loading ? <PreLoader /> : <AllRoutes />}
      </div>
      <div class="page_overlay"></div>
    </>
  );
}

export default App;
