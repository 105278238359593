import CountUp from "react-countup";
import { Card, CardHeader, CardBody } from "@chakra-ui/react";


const CountCard = (props) => {
  return (
    <>
      <div className="col-lg-6 col-md-6 col-sm-6 col-6 counter_card">
        <Card _hover={{boxShadow:'none'}} data-aos="fade-up" data-aos-duration={props.delay} style={{boxShadow:"none"}}>
          <CardHeader>
            <img src={props.icon} className={props.img_css} alt="" />
          </CardHeader>
          <CardBody>
            <h2 size="lg" className={props.color}>
              <span> <CountUp duration={5} start={0} end={props.num} /> </span>{props.unit}
            </h2>
            <p className="lead font-weight-400">{props.text}</p>
          </CardBody>
        </Card>
      </div>
    </>
  )
}

export default CountCard
