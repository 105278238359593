import React from 'react'
import { Card, CardHeader, CardBody, Image } from "@chakra-ui/react";

const AppCard = (props) => {
    return (
        <>
            <Card
                borderTop="8px"
                borderColor="#056894"
                data-aos="fade-up"
                data-aos-delay={props.delay}
            >
                <CardHeader className="d-flex justify-content-center align-items-center">
                    <Image
                        src={props.imgsrc}
                        alt="..."
                        borderRadius="lg"
                        maxWidth="300px"
                        maxHeight="500px"
                    />
                </CardHeader>
                <CardBody>
                    <h5 size="md" className="text-blue text-center">
                       {props.title}
                    </h5>
                    <p>
                       {props.txt}
                    </p>
                </CardBody>
            </Card>
        </>
    )
}

export default AppCard
