import React from "react";
import CircleCard from "../component/CardContain/CircleCard";
import circledata from "./industriesdata.json";
import { SimpleGrid } from "@chakra-ui/react";
const Industries = () => {
  return (
    <section className="industry_section bg_oneimg ">
      <div className="white_layer">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <div className="top_title text-center">
                <h2 data-aos="fade-up" data-aos-duration="500">
                  <span>Great Solutions For These Industries</span>
                </h2>
              </div>
              <h5
                className="After_title mb-4"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                We offer multiple IT solutions for enormous industries. We are
                #1 choice of industrialists because we use the most <br />
                robust technology to build a solution for your business.
              </h5>
            </div>
          </div>
          <div className="row   mb-5 circle_row_one">
            <SimpleGrid templateColumns="repeat(auto-fill, minmax(250px, 1fr))">
              <CircleCard data={circledata.categories1} />
            </SimpleGrid>
          </div>
          <div
            className={
              "row mb-5  " +
              (window.innerWidth > 1200 ? " circle_row_two" : "")
            }
          >
            <SimpleGrid templateColumns="repeat(auto-fill, minmax(250px, 2fr))">
              <CircleCard data={circledata.categories2} />
            </SimpleGrid>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Industries;
