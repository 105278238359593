import ServiceCard from "./ServiceCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const HomeService = () => {
  return (
    <>
      <section className="afterSlider_section">
        <div className="container ">
          <div className=" col-md-12 ">
            <div className="top_title text-center">
              <h2 data-aos="fade-down" data-aos-duration="1000">
                <span>We Give What You Expect</span>
              </h2>
            </div>
            <h5 className="After_title ">
              We Offer a Variety Of IT Solutions, Major Ones Are:
            </h5>
            <div className="serviceCard_here">
              <Swiper
                loop={true}
                grabCursor={true}
                spaceBetween={20}
                autoplay={{
                  delay: 6000,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
                className="mySwiper serviceCard_swiper"
                breakpoints={{
                  600: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },

                  992: {
                    slidesPerView: 3,
                  },

                  1200: {
                    slidesPerView: 5,
                  },
                }}
              >
                <SwiperSlide>
                  <ServiceCard
                    delay="500"
                    link="/service/softwareDevelopment"
                    txt="We offer custom software solutions with high end performance."
                    title="  Software Development"
                    img="img/service/software development.png"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ServiceCard
                    delay="600"
                    link="/service/appDevelopment"
                    txt="  Build your smart application with modern development technology."
                    title="  App Development"
                    img="img/service/app development.png"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ServiceCard
                    delay="800"
                    link="/service/mixedReality"
                    txt="  Get futuristic IT solutions with AR-VR based technology."
                    title="  Mixed Reality"
                    img="img/service/mixed reality.png"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ServiceCard
                    delay="1000"
                    link="/service/digitalMarketing"
                    txt="Get More leads with our digital marketing solutions."
                    title=" Digital Marketing"
                    img="img/service/digital marketing.png"
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <ServiceCard
                    delay="1500"
                    link="/"
                    txt=" Host your site on our hosting servers (Serverless available)."
                    title="  Web Hosting"
                    img="img/service/web hosting.png"
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeService;
