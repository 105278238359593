import {
    Image, List, ListItem, ListIcon
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { Col, Row } from "reactstrap";

const MixedApp = () => {
    return (
        <>
            <Row>
                <Col md={12} className=" mt-5  text-center  dot-pattern">
                    <div className="top_title text-center">
                        <h2 data-aos="fade-up" data-aos-duration="500">
                            <span> Types of Mixed Reality Application</span>
                        </h2>
                    </div>
                    <p className="lead mt-1 fw-300" data-aos="fade-up" data-aos-duration="600">
                        Types of Mixed Reality AppSome types of mixed reality apps provide
                        a robust set of tools which produce new environments <br /> and
                        visualization to experience..
                        <br />
                    </p>
                </Col>
            </Row>
            <Row className="mixed_rea_app mt-3">
                <Col md={8}>
                    <div className="heading_txt">
                        <h3>Enhanced Environment App: <span className="text-maincolor">HoloLens Only</span></h3>
                        <hr className="w-80" />
                    </div>
                    <div className="paratxt">
                        <p py="2">
                            Mixed reality appliaction is one of the most appropriate
                            ways that bring value in letting content in the user’s
                            current environment.</p><p>
                            This approach is popular for the app where the associated
                            placement of digital content in the real world is a key
                            and keeping the user’s real-world environment present
                            during their experience.
                        </p>
                        <span className="text-blue fw-bold mb-2">Examples</span>
                        <List pl={1} pt={4}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Mixed reality communication apps like Skype
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                Mixed reality shopping cart overlaid onto the
                                refrigerator door.
                            </ListItem>
                        </List>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="app_imghere">
                        <Image
                            objectFit="contain"
                            maxW={{ base: "100%", sm: "350px" }}
                            src="/img/hololens-only.png"
                            alt="..."
                        />
                    </div>
                </Col>
            </Row>
            <Row className="mixed_rea_app">
                <Col md={4} className="text-left d-flex justify-content-left">
                    <div className="app_imghere">
                        <Image
                            objectFit="contain"
                            maxW={{ base: "100%", sm: "350px" }}
                            src="/img/blend-real-world.png"
                            alt="..."
                        />
                    </div>
                </Col>
                <Col md={8}>
                    <div className="heading_txt">
                        <h3> Blended Environment: <span className="text-maincolor"> Blend Real World</span></h3>
                        <hr className="w-80" />
                    </div>
                    <div className="paratxt">
                        <p py="2">
                            A blended environment occupies the middle ground in the MR
                            experience.</p><p>This type of experience may transform an element
                                into entirely different objects but still, retain the
                                rough dimensions of the objects as its base. <br />
                            Create a digital layer that can be completely
                            overlaid at the user’s space, recognize and map the user’s
                            environment.
                        </p>
                        <span className="text-blue fw-bold mb-2">Examples</span>
                        <List pl={1} pt={4}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                mixed-reality interior design app that can paint walls
                                or floors with different colors and patterns
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />A An app that
                                allows users to blast holes in their real-world walls,
                                which reveal a magical world.
                            </ListItem>
                        </List>
                    </div>
                </Col>

            </Row>
            <Row className="mixed_rea_app">
                <Col md={8}>
                    <div className="heading_txt">
                        <h3>Immersive Environment App: <span className="text-maincolor">Similar to VR</span></h3>
                        <hr className="w-80" />
                    </div>
                    <div className="paratxt">
                        <p py="2">
                            Immersive is the third level of mixed reality.
                            <br /> Immersive environment apps are the apps that
                            completely change the world of the user and can be placed
                            in different times and zones.
                        </p><p>
                            These environments can feel saturated, real, and
                            exciting experiences that are only limited by the
                            imagination of the creator.
                        </p>
                        <span className="text-blue fw-bold mb-2">Examples</span>
                        <List pl={1} pt={4}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                An immerse app that lets users tour a space separate
                                from their own.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="#03b5be" />
                                An immerse app that stage-manages an event around the
                                user.
                            </ListItem>
                        </List>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="app_imghere">
                        <Image
                            objectFit="contain"
                            maxW={{ base: "100%", sm: "350px" }}
                            src="/img/similar-to-vr.png"
                            alt="..."
                        />
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default MixedApp
