import React from 'react'
import { Col } from 'reactstrap'

const TextTab = (props) => {
    return (
        <>
            <Col md={3} sm={3} lg={2} className='col-4'>
                <div className="text_tabHere">
                    <h6> {props.txt}</h6>
                </div>
            </Col>
        </>
    )
}

export default TextTab
